import { UPDATE } from "../action/cache";
import { DataCache } from '@audioplay/client';

const initialState: {
  cache: any,
} = {
  cache: undefined
};

export type state = typeof initialState;

const reducer = (state = initialState, action: any) => {
  if (action.type === UPDATE) {
    return Object.assign({}, state, { cache: action.cache });
  }
  return state;
};

export default reducer;
