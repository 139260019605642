import { createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
 
import { root } from "./reducer";
 
const persistConfig = {
  key: 'root',
  storage,
}

const create = () => {
  let store = createStore(persistReducer(persistConfig, root))
  let persistor = persistStore(store)
  return { store, persistor };
};

export default create;