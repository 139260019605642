import React, { Component } from 'react';
import { StandardContent } from '../../components/content/Standard';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

export interface Props extends HeaderProps {
};


export const Page404 = (props: Props) => {
  return <HeaderPage {...props}>
    <StandardContent verticalAlign={true}>
      <p>Oops! That page can’t be found.</p>
    </StandardContent>
  </HeaderPage>;
}