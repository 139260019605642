import React, { Component, useEffect, useState } from 'react';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Buffer } from 'buffer';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { makeRequest } from '@audioplay/client';
import { login } from '../../state/action/user';
import { state } from '../../state/reducer/user';
import { Spinner } from '../../components/elements/Spinner';


export interface Props extends HeaderProps {
  login: typeof login,
  user: state,
};

export const ConfirmDeleteBase = (props: Props) => {
  const [state, setState] = useState<'loading' | 'success' | 'error'>('loading');
  const params = useParams();
  const nav = useNavigate();
  let token = params['token'];
  if (token) {
    token = Buffer.from(token, "base64").toString();
  }

  useEffect(() => {
    makeRequest('delete.account.confirm', {
      deleteToken: token || '',
    })
      .then((res) => {
        if (res.result === 'success') {
          setState('success');
        } else {
          setState('error');
        }
      })
  }, [token]);

  if (state === 'loading') {
    return <div style={{ width: '100%', height: '100%', position: 'fixed'}}>
      <Spinner></Spinner>
    </div>
  }

  if (state === 'error') {
    return <HeaderPage>
      <Block.Block alternate={true}>
        <Standard.StandardContent verticalAlign={true} >
          <center><p><span style={{ fontSize: 30, fontWeight: 800, fontFamily: 'Knewave' }}>LINK NOT VALID</span><br/><br/>It looks like that link isn't valid, if you still want to delete your account please request a new one <a style={{fontWeight:600,color: 'rgba(255,0,87,1)'}} onClick={() => nav('/account/delete/')}>HERE</a></p></center>
        </Standard.StandardContent>
      </Block.Block>
    </HeaderPage>;
  } else {
    return <HeaderPage>
      <Block.Block alternate={true}>
        <Standard.StandardContent verticalAlign={true} >
          <center><p><span style={{ fontSize: 30, fontWeight: 800, fontFamily: 'Knewave' }}>WE MISS YOU ALREADY</span><br/><br/>You're account has been deleted</p></center>
        </Standard.StandardContent>
      </Block.Block>
    </HeaderPage>;
  }
}

export const ConfirmDelete = connect((state: any) => ({ user: state.user }), { login })(ConfirmDeleteBase);