export const REGISTER = "REGISTER";
export const UNREGISTER = "UNREGISTER";

export const register = (campaignId: string, accessToken: string) => ({
  type: REGISTER,
  campaignId,
  accessToken,
});

export const unregister = (campaignId: string) => ({
  type: UNREGISTER,
  campaignId,
});
