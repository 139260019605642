import { Form, Col, Spinner, Button as B, Dropdown } from 'react-bootstrap';
import { StandardControlProps, useControlState, wrapFormControl } from '../Base';

export function Button(props: StandardControlProps & { type: 'button' }) {
  const { value, outputProps } = useControlState(props);
  delete outputProps.isInvalid;
  delete outputProps.isValid;
  
  const { display = {} } = props;
  return <Form.Group ref={props.containerRef} key={props.id} className="control-group" style={{ flexDirection:'column', ...display.group?.style }} >
    <B ref={props.inputRef} {...outputProps} variant={display.props? display.props.variant: undefined} style={{
      fontFamily: 'Knewave',
      fontSize: 'x-large',
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 10,
      paddingBottom: 10,
      ...(props.display?props.display.style: {}),
    }} type={undefined}>
      {props.processing?<Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>:(props.title || (value !== undefined ? value.toString():''))}
    </B>
    {props.valid===false?<><br/><Form.Control.Feedback type="invalid" className="d-block" style={{width: 'auto'}}>
          {props.error !== undefined?props.error:'* required'}
        </Form.Control.Feedback></>: undefined}
  </Form.Group>;
}