import React, { createRef, ReactNode, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { util } from '@audioplay/client';

export interface Props {
  url?: string,
  mask?: (active: boolean) => JSX.Element,
  width?: number | string,
  height?: number | string,
  imageUpdated: (image: string | undefined) => void,
  className?: string,
}

export const CircularBorder = (active: boolean) => <svg viewBox='0 0 100 100'>
  <path d='M 0,0 L 0,50 1,50 A 49 49 0 0 1 99,50 49 49 0 0 1 1,50 L 0,50 0,100 100,100 100,0  Z' stroke='none' fill={active?'#9997':'#999D'}></path>
</svg>

export const ImageDisplay = (props: Omit<Props, 'imageUpdated'>) => {
  const content = props.url;
  return <div className={props.className} style={{ width: props.width !== undefined? props.width:'100%', height: props.height !== undefined?  props.height:'100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'end', background:  content? 'none':'#eee' }}>
    <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0}}>
      {content?<img style={{ width: '100%', height: '100%', aspectRatio: 'auto', objectFit: 'contain' }} alt="uploaded" src={content}></img>:undefined}
    </div>
    <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0}}>
      {props.mask?props.mask(false): undefined}
    </div>
  </div>;
}

export const ImageUpload = (props: Props) => {
  const [content, setContent] = useState<string | undefined>(props.url);
  const [state, setState] = useState<'initial' | 'uploading' | 'uploaded'>('initial');
  const [active, setActive] = useState(false);

  const fileInput = createRef<HTMLInputElement>();

  const loadFile = (file: File | undefined) => {
    if (file) {
      setState('uploading');
      file.arrayBuffer().then((c) => util.uploadFile(file.name, c))
        .then((r) => {
          if (r) {
            setState('uploaded');
            imageUpdated(r.url);
          } else {
            setState('initial');
          }
        });
    }
  }
  
  const imageUpdated = (image: string | undefined) => {
    props.imageUpdated(image);
    setContent(image);
  }

  if (state === 'uploading') {
    return <div style={{ width: props.width !== undefined? props.width:'100%', height: props.height !== undefined?  props.height:'100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', background: '#eee' }}>
      <Spinner animation='border'/>
    </div>
  }
  
  return <div className={props.className} style={{ width: props.width !== undefined? props.width:'100%', height: props.height !== undefined?  props.height:'100%', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'end', background:  content? 'none':'#eee' }}
    onDrop={e => {
      e.preventDefault();
      e.stopPropagation();
      loadFile(e.dataTransfer.files[0]);
    }}
    onMouseEnter={e => {
      setActive(true);
      e.preventDefault();
      e.stopPropagation();
    }}
    onMouseOut={e => {
      setActive(false);
      e.preventDefault();
      e.stopPropagation();
    }}
    onMouseOverCapture={e => {
      setActive(true);
      e.preventDefault();
      e.stopPropagation();
    }}
    onDragOver={e => {
      e.dataTransfer.dropEffect = 'copy';
      e.preventDefault();
      e.stopPropagation();
    }}
    onDragEnter={e => {
      e.dataTransfer.dropEffect = 'copy';
      e.preventDefault();
      e.stopPropagation();
    }}
    onDragExit={e => {
      e.preventDefault();
      e.stopPropagation();
    }}>
    <input id="myInput"
      type="file"
      ref={fileInput}
      style={{display: 'none'}}
      onChange={(event) => {
        const files = event.target.files || [];
        const file = files[0];
        loadFile(file);
      }}
    />
    
    
    <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0}}>
      {content?<img style={{ width: '100%', height: '100%', aspectRatio: 'auto', objectFit: 'contain' }} alt="uploaded" src={content}></img>:undefined}
    </div>
    <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0}}>
      {props.mask?props.mask(active): undefined}
    </div>
    {active? <div style={{margin: 5, zIndex: 1}}>
      <Button style={{flexGrow: 0, padding: '0rem .375rem'}} onClick={() => {
        if (fileInput.current) {
          fileInput.current.click();
        }
      }}>+</Button>
      {content?<Button style={{flexGrow: 0, padding: '0rem .375rem',marginLeft: 5}} variant="danger" onClick={() => {
        setState('initial');
        imageUpdated(undefined);
      }}>-</Button>:undefined}
    </div>: undefined}
  </div>;
}
