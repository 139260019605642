import React, { MouseEvent } from 'react';
import { CustomNode } from './Interface'
import { ElementActionInterface } from './Base';

export type Props = Omit<CustomNode, 'onClick'> & {
  children?: React.ReactNode,
  controller: ElementActionInterface,
};

export function Custom(props: Props) {
  const { display = {} } = props;
  if (display.hidden) {
    return null;
  }
  const click = props.controller.onClick;
  return props.render({ ...props, click });
}