import React, { Component, ReactNode } from 'react';
import { Header } from '../nav/Header';
import { Footer } from '../nav/Footer';

export interface Props {
  header?: JSX.Element,
  footer?: JSX.Element,
  pageProps?: React.ComponentProps<'div'>,
  contentProps?: React.ComponentProps<'div'>,
  children?: React.ReactNode,
  alignContent?: 'top' | 'bottom' | 'fill',
};

export interface State {

}

export class HeaderPageTemplate extends Component<Props, State> {

  public render() {
    const { header, footer, contentProps, pageProps } = this.props;
    
    return <div className="page-holder" { ...pageProps }>
      {header? header: <Header/>}
      <div className="content-holder" { ...contentProps }>
        {this.props.alignContent !== 'top' && this.props.alignContent !== 'fill' ? <div style={{flexGrow:1}}></div>:undefined}
        {this.props.children}
        {this.props.alignContent !== 'bottom' && this.props.alignContent !== 'fill'? <div style={{flexGrow:1}}></div>:undefined}
        {footer? footer: <Footer/>}
      </div>
    </div>
  }
}