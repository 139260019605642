import { Route } from 'react-router-dom';


import { Login } from '../../account/Login';
import { Signup } from '../../account/Signup';
import { ResetPassword } from '../../account/ResetPassword';
import { NewPassword } from '../../account/NewPassword';
import { FinishAccountCreation } from '../../account/FinishAccountCreation';
import { Delete } from '../../account/Delete';
import { ConfirmDelete } from '../../account/ConfirmDelete';


import { RouteBundleProps } from '../common';

export const resetPassword = (props: RouteBundleProps) => {
  return [
    <Route path="/resetPassword" element={<ResetPassword/>} />,
    <Route path="/newPassword/:token" element={<NewPassword/>} />,
    <Route path="/createPassword/:token" element={<FinishAccountCreation/>} />,
  ];
}

export const signup = (props: RouteBundleProps) => {
  return [
    <Route path="/createAccount" element={<Signup/>} />,
    <Route path="/new" element={<Signup/>} />,
    <Route path="/login" element={<Login/>} />,
  ];
}

export const deleteAccount = (props: RouteBundleProps) => {
  return [
    <Route path="/account/delete/:token" element={<ConfirmDelete/>} />,
    <Route path="/account/delete/" element={<Delete/>} />,
  ]
}