import React, { Component, useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { isAndroid, isIOS } from 'react-device-detect';

import { runDownloadRedirect, isMobileTarget } from '../../components/redirect/download';

import { Spinner } from '../../components/elements/Spinner';
export interface Props extends HeaderProps {

};

export const QRCode = (props: Props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const queryParams: {[key: string]: string } = {};
  const fired = useRef(false);
  const opened = useRef(false);
  const [loading, setLoading] = useState(true);

  searchParams.forEach((v,k) => {
    queryParams[k] = v;
  });
  useEffect(() => {
    if (fired.current === true) {
      return;
    }
    fired.current = true;

    if (isMobileTarget()) {
      runDownloadRedirect()
      setInterval(() => {
        setLoading(false);
      },500);
    } else {
      setLoading(false);
    }
  }, []);
  if (loading) {
    return <div style={{ width: '100%', height: '100%', position: 'fixed'}}>
      <Spinner></Spinner>
    </div>
  }

  return <HeaderPage>
    <Block.Block alternate={true}>
      <Standard.StandardContent>
        <div style={{ padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, maxWidth: 600 }} >
          <div style={{display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{display: 'flex', flexDirection: 'row' }}>
              <div className="title text" style={{ textAlign: 'center' }}>This code only works in the audioplay app. Please download the app and re scan the code.</div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div style={{display: 'flex', flexDirection: 'column',alignItems: 'center' }}>
                <a href={'https://apps.apple.com/app/apple-store/id1361477655'} style={{height:50}}><img src="/images/ios-download.png" alt="ios app store download" style={{height:'100%'}}></img></a>
                <div style={{height: 20}}/>
                <a href={'https://apps.apple.com/app/apple-store/id1361477655'} style={{height:150}}><img src="/images/ios-download-link.png" alt="ios app store download" style={{height:'100%'}}></img></a>
              </div>
              <div style={{width: 20}}/>
              <div style={{display: 'flex', flexDirection: 'column',alignItems: 'center' }}>
                <a href={'https://play.google.com/store/apps/details?id=au.com.audioplay'} style={{height:50}}><img src="/images/android-download.png" alt="google play store download" style={{height:'100%'}}></img></a>
                <div style={{height: 20}}/>
                <a href={'https://play.google.com/store/apps/details?id=au.com.audioplay'} style={{height:150}}><img src="/images/android-download-link.png" alt="google play store download" style={{height:'100%'}}></img></a>
              </div>
            </div>
          </div>
        </div>
      </Standard.StandardContent>
    </Block.Block>
  </HeaderPage>;
}