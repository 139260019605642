import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { redirect } from "react-router";

import { logout } from '../../state/action/user';
import { state } from '../../state/reducer/user';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DataCache } from '@audioplay/client'

export interface Props {
  logout: typeof logout,
  user: state,
};

export interface State {

}

export class HeaderBase extends Component<Props, State> {

  render() {
    if (this.props.user.accessToken) {
      return <Row className="page-header-nav">
      <Col className="logo block" md="4"  style={{ paddingLeft: 30 }}>
        <Link className="logo block" to='/' >
          <img className="image" src="/logo512.png" alt="audioplay logo" />
          <img className="tag-line image" src="/logo.png" alt="audioplay tagline" />
        </Link>
      </Col>
      <Col className="menu block" md="8" style={{ paddingLeft: 10, paddingRight: 20}}>
        {/* <Link className="text" to='/'>Dashboard</Link> */}
        <div style={{flexGrow: 1}}/>
        <div onClick={() => {
          this.props.logout();
        }}><p className="text">LOGOUT</p></div>
      </Col>
    </Row>
    }
    return <Row className="page-header-nav">
      <Col className="logo block" md="4" style={{ paddingLeft: 30 }}>
        <a className="logo block" href="https://audioplay.me">
          <img className="image" src="/logo512.png" alt="audioplay logo" />
          <img className="tag-line image" src="/logo.png" alt="audioplay tagline" />
        </a>
      </Col>
      <Col className="menu block" md="8">
        {/* <a className="text" href="https://audioplay.me">HOME</a>
        <a className="text" href="https://audioplay.me/stories">STORIES</a>
        <a className="text" href="https://audioplay.me/category/blog/">BLOG</a>
        <a className="text" href="https://audioplay.me/our-team/">ABOUT</a> */}
      </Col>
    </Row>
  }
}

export const Header = connect((state: any) => ({ user: state.user }), { logout })(HeaderBase); 