import { LOGIN, LOGOUT } from "../action/user";
import { DataCache } from '@audioplay/client';

const initialState: {
  accessToken?: string,
  refreshToken?: string,
  userConfiguration?: any[],
  updated?: number,
} = {
  accessToken: undefined,
  refreshToken: undefined
};

export type state = typeof initialState;

const reducer = (state = initialState, action: any) => {
  if (action.type === LOGIN) {
    DataCache.setAccess(action.accessToken);
    return Object.assign({}, state, { accessToken: action.accessToken, refreshToken: action.refreshToken, userConfiguration: action.userConfiguration, updated: Date.now()*0.001 });
  } else if (action.type === LOGOUT) {
    return Object.assign({}, state, { accessToken: undefined, refreshToken: undefined, userConfiguration: undefined, updated: undefined });
  }
  return state;
};

export default reducer;
