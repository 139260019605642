import { Route } from 'react-router-dom';

import * as Web from '../../web';

import { RouteBundleProps } from '../common';

export const login = (props: RouteBundleProps) => {
  return [
    <Route path="/login" element={<Web.Login/>} />,
  ];
}