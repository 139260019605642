import React, { Component } from 'react';
import { Col } from 'react-bootstrap';

export interface Props extends React.ComponentProps<typeof Col> {
  verticalAlign?: boolean,
  width?: number | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12',
}

export interface State {

}

export class StandardContent extends Component<Props, State> {

  render() {
    return <Col md={this.props.width || "8" } {...this.props} className={`${this.props.verticalAlign===true?"content-standard centre":"content-standard"} ${this.props.className || ''}`}>
      {this.props.children}
    </Col>
  }
}

export class StandardContentBlock extends Component<Props & { colour?: 'white' | 'contrast' }, State> {
  render() {
    return <div style={{backgroundColor: this.props.colour === 'contrast'? '#f2f2f2': this.props.colour === 'white'? '#fff': undefined, padding: 15, marginTop: 15, marginBottom: 15 }}>
      {this.props.children}
    </div>
  }
}