export type ControlValue = string | number | Date | boolean | undefined;
export type ControlOptions = { [key: string]: string | { disabled?: boolean, value: string, default?: boolean } };

export interface DisplayInformation {
  section?: 'header' | 'footer',
  align?: 'start' | 'centre' | 'end',
  hidden?: boolean,
  validate?: boolean,
  classNames?: string,
  style?: React.CSSProperties,
  title?: {
    classNames?: string,
    style?: React.CSSProperties,
  },
  size?: {
    width?: number | string,
    height?: number | string,
  }
  flex?: {
    size?: number,
    grow?: number,
  } | number,
}

export type ControlDisplayInformation<P extends {}> = DisplayInformation & {
  plainText?: boolean,
  inline?: boolean,
  disabled?: boolean,
  props?: P,
  group?: {
    classNames?: string,
    style?: React.CSSProperties,
  },
}

export interface Base {
  id?: string,
  title?: string,
  valid?: boolean,
  error?: string,
  onClick?: string | ((controller: FormController, information: NodeInformation) => Promise<void> | void),
  validationRequired?: boolean,
  display?: DisplayInformation,
}

export type BaseControl = {
  value?: ControlValue | ControlValue[],
  placeholder?: ControlValue | ControlValue[],
  optional?: boolean,
  processing?: boolean,
  onChange?: (value: ControlValue | ControlValue[], controller: FormController, information: NodeInformation) => void,
} & Base & { display?: ControlDisplayInformation<{}> }

export type OptionControl = {
  type: 'dropdown' | 'searchableDropdown',
  options?: ControlOptions,
  optionSet?: string,
} & BaseControl;

export type ImageControl = {
  type: 'img',
} & BaseControl & { display?: ControlDisplayInformation<{ simpleImage?: boolean, mask?: 'circle', imageWidth?: number | string, imageHeight?: number | string }> }

export type ButtonControl = {
  type: 'button',
} & BaseControl & { display?: ControlDisplayInformation<{ variant?: string }> }

export type StandardControl = {
  type: 'label' | 'text' | 'password' | 'textarea' | 'date' | 'time' | 'datetime' | 'timespan' | 'checkbox' | 'link' | 'itemSet',
} & BaseControl;

export type Control =  StandardControl | OptionControl | ImageControl | ButtonControl;

export type ControlType = Control['type'];


export type SectionDisplayInformation = Omit<DisplayInformation,'align'> & {
  align?: DisplayInformation['align'] | 'around' | 'between' | 'evenly',
}

export type Row = {
  type: 'row',
  containsHiddenInvalid?: boolean,
  items: (Control | CustomNode | Row | Section)[],
  wrapper?: (content: JSX.Element, state: Omit<Row, 'items' | 'onClick'>) => JSX.Element,
} & { display?: SectionDisplayInformation } & Omit<Base, 'display'>;

export type Section = {
  type: 'section',
  containsHiddenInvalid?: boolean,
  items: (Control | CustomNode | Row | Section)[],
  wrapper?: (content: JSX.Element, state: Omit<Section, 'items' | 'onClick'>) => JSX.Element,
} & { display?: SectionDisplayInformation } & Omit<Base, 'display'>;

export type CustomNode = {
  type: 'custom',
  render: (props: Omit<CustomNode, 'render' | 'onClick'> & { click?: () => void }) => JSX.Element,
} & Base;

export type ItemState = Control | Row | Section | CustomNode;

export interface FormController {
  getValue: (id: string) => ControlValue | ControlValue[] | undefined,
  getValid: (id: string) => boolean | undefined,
  getState: (id: string) => ItemState | undefined,

  setValue: (id: string, value: ControlValue) => Promise<void>,
  setValid: (id: string, valid: boolean, msg?: string | undefined) => Promise<void>,
  setHidden: (id: string, hidden: boolean) => Promise<void>,
  setState: (id: string, state: Partial<ItemState>) => Promise<void>,

  getStandardisedId: (id: string) => string,
  getChildren: (id: string) => string[],
  getParent: (id: string) => string | undefined,

  addNode: (node: ItemState, parentId: string, afterNode?: string) => void,
  removeNode: (nodeId: string) => void,
}

export interface NodeInformation {
  id: string,
  parentId: string,
}

export const isControl = (data: any): data is Control => data && typeof data.type === 'string' && data.type !== 'row' && data.type !== 'section';
export const isCustom = (data: any): data is CustomNode => data && (data.type === 'custom');
export const isRow = (data: any): data is Row => data && Array.isArray(data.items) && (data.type === 'row');
export const isSection = (data: any): data is Section => data && Array.isArray(data.items) && (data.type === 'section');

export const mapAlignment = (align: SectionDisplayInformation['align']) => align === 'centre'? 'center': align === 'around'? 'space-around': align === 'evenly'? 'space-evenly': align === 'between'? 'space-between': align;