import { Redirect } from '../nav/Redirect';
import { isAndroid, isIOS } from 'react-device-detect';

export const DownloadRedirector = () => {
  if (isIOS) {
    return <Redirect url={'https://apps.apple.com/app/apple-store/id1361477655'}/>
  }
  if (isAndroid) {
    return <Redirect url={'https://play.google.com/store/apps/details?id=au.com.audioplay'}/>
  }
  // return <></>;
  return <Redirect url={'https://audioplay.me'}/>;
}

export const isMobileTarget = () => {
  return isIOS || isAndroid;
}

export const runDownloadRedirect = (bounceToHomepage = false) => {
  if (isIOS) {
    window.location.href = 'https://apps.apple.com/app/apple-store/id1361477655';
  } else if (isAndroid) {
    window.location.href = 'https://play.google.com/store/apps/details?id=au.com.audioplay';
  } else if (bounceToHomepage) {
    window.location.href = 'https://audioplay.me';
  }
}