import { Form, Col, Spinner, Button, Dropdown } from 'react-bootstrap';
import { StandardControlProps, useControlState, wrapFormControl } from '../Base';

export function Text(props: StandardControlProps & { type: 'text' | 'password' | 'textarea' }) {
  const { value, click, outputProps } = useControlState(props);
  delete (outputProps as any).onClick;
  (outputProps as any).onKeyPress = (event: any) => {
    if(event.charCode === 13){
      click();  
    }
  };
  if (props.type === 'textarea') {
    const { display = {} } = props;
    return wrapFormControl(props, outputProps, props.title, undefined, <Form.Control ref={props.inputRef} {...outputProps} value={value !== undefined ? value.toString():''} as="textarea" rows={4} style={display.style}></Form.Control>);
  }
  return wrapFormControl(props, outputProps, props.title, value, undefined);
}