export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const formatNumber = (number: number): string => {
  let res = number.toString();
  for(let i = res.length-3; i > 0; i -= 3) {
    res = res.substring(0,i) + ',' +  res.substring(i);
  }
  return res;
}