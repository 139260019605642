import React, { Component } from 'react';


export interface Props {
  width?: number,
  height?: number,
}

export interface State {

}

export class Spinner extends Component<Props, State> {
  render() {
    if (this.props.width || this.props.height) {
      return <div style={{width: this.props.width !== undefined && this.props.width > 30? this.props.width: undefined, height: this.props.height !== undefined && this.props.height > 30? this.props.height: undefined }}>
        <div className="spinner" >
          <div className="loader bounce1"/>
          <div className="loader bounce2"/>
          <div className="loader bounce3"/>
        </div>
      </div>
    }
    
    return <div className="spinner" >
      <div className="loader bounce1"/>
      <div className="loader bounce2"/>
      <div className="loader bounce3"/>
    </div>
  }
}