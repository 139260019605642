import { useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import * as AudioplayClient from '@audioplay/client';

import { Redirect } from '../../components/nav/Redirect';
import { Page404 } from '../public/404';
import { Error } from '../public/Error';

import * as Web from '../web';

import { AppRouterProps, RouteBundleProps } from './common';
import { training, account, registration, web, analytics } from './public';
import { ContentEditor } from '../admin/Editor';
import * as RedirectPage from '../redirect';



export const AppRouter = (props: AppRouterProps) => {
  const [wasLoggedIn, setWasLoggedIn] = useState(props.loggedIn);
  const [cacheContext] = useState(new AudioplayClient.Context(new AudioplayClient.DataCache()));
  if (props.loggedIn && !wasLoggedIn) {
    setWasLoggedIn(true);
  }
  const bundleProps: RouteBundleProps = {
    loggedIn: props.loggedIn === true,
    context: cacheContext,
  };

  return <BrowserRouter>
    <Routes>
      {props.loggedIn?[
        training.training(bundleProps),
        registration.register(bundleProps),
        account.resetPassword(bundleProps),
        account.deleteAccount(bundleProps),
        analytics.download(bundleProps),
        <Route path="/app/*" element={<RedirectPage.QRCode/>} />,
        <Route path="/action/*" element={<RedirectPage.QRCode/>} />,
        <Route path="/login" element={<Navigate to={'/'}/>} />,
        <Route path="/" element={<Web.PresenterPack />} />,
        <Route path="*" element={<Page404/>} />,
      ]:[
        web.login(bundleProps),
        training.training(bundleProps),
        registration.register(bundleProps),
        account.resetPassword(bundleProps),
        account.deleteAccount(bundleProps),
        analytics.download(bundleProps),
        <Route path="/app/*" element={<RedirectPage.QRCode/>} />,
        <Route path="/action/*" element={<RedirectPage.QRCode/>} />,
        <Route path="/" element={<Navigate to={'/login'}/>} />,
        <Route path="*" element={<Page404/>} />,
      ]}
    </Routes>
  </BrowserRouter>;
}