export type contentType = 'the.turners' | 'sun.runners' | 'bamboo.bandits';

const title: { [key in contentType]: string } = {
  'the.turners': "/images/seasons/turners/title.png",
  'sun.runners': "/images/seasons/sunrunners/title.png",
  'bamboo.bandits': "/images/seasons/bamboobandits/title.png",
}

const titleText: { [key in contentType]: string } = {
  'the.turners': 'The Turners',
  'sun.runners': 'Sun Runners',
  'bamboo.bandits': 'Bamboo Bandits',
}

const background: { [key in contentType]: string } = {
  'the.turners': "/images/seasons/turners/background.png",
  'sun.runners': "/images/seasons/sunrunners/background.png",
  'bamboo.bandits': "/images/seasons/bamboobandits/background.png",
}

const character1: { [key in contentType]: string | undefined } = {
  'the.turners': undefined,
  'sun.runners': "/images/seasons/sunrunners/char.png",
  'bamboo.bandits': "/images/seasons/bamboobandits/chaos.png",
}

const docs: { [key in contentType]: { byo: string | undefined, remote: string, marketing: string, promo: string | undefined } } = {
  'the.turners': {
    // byo: '/docs/turners/THE TURNERS Tech Spec - Remote Delivery BYO Equipment - Draft.pdf',
    byo: undefined,
    remote: '/docs/turners/THE TURNERS Tech Spec - Remote Delivery Equipment Provided - Draft.pdf',
    marketing: '/docs/turners/THE TURNERS Marketing Kit.pdf',
    promo: 'https://vimeo.com/300236161',
  },
  'sun.runners': {
    // byo: '/docs/sunrunners/SUN RUNNERS Tech Spec - Remote Delivery BYO Equipment - Draft.pdf',
    byo: undefined,
    remote: '/docs/sunrunners/SUN RUNNERS Tech Spec - Remote Delivery Equipment Provided - Draft.pdf',
    marketing: '/docs/sunrunners/SUN RUNNERS Marketing Kit.pdf',
    promo: 'https://youtu.be/_mtRpU_ROzU',
  },
  'bamboo.bandits': {
    byo: undefined,
    remote: '/docs/bamboobandits/BAMBOO BANDITS Tech Spec - Remote Delivery Equipment Provided - Draft.pdf',
    marketing: '/docs/bamboobandits/BAMBOO BANDITS Marketing Kit.pdf',
    promo: 'https://youtu.be/8GpeVK2B5vs',
  }
}

const dotPoints: { [key in contentType]: string[] } = {
  'the.turners': [
    'Study guide/education kit',
    'Access to Play@Home experiences, which allow families to continue to engage with the story and characters at home (additional fees may be required)',
    'Artist Q&A delivered via video conference',
    'Colouring in/activity sheets',
  ],
  'sun.runners': [
    'Study guide/education kit',
    'Access to Play@Home experiences, which allow families to continue to engage with the story and characters at home (additional fees may be required)',
    'Artist Q&A delivered via video conference',
    'Colouring in/activity sheets',
  ],
  'bamboo.bandits': [
    'Access to Play@Home experiences, which allow families to continue to engage with the story and characters at home (additional fees may be required)',
    'Artist Q&A delivered via video conference',
    'Colouring in/activity sheets',
  ],
}

export const getTitle = (content: contentType) => {
  return title[content] || title['sun.runners'];
}
export const getTitleText = (content: contentType) => {
  return titleText[content] || 'Audioplay';
}

export const getBackground = (content: contentType) => {
  return background[content] || background['sun.runners'];
}

export const getCharacter = (content: contentType) => {
  return character1[content];
}

export const getDocs = (content: contentType) => {
  return docs[content] || docs['sun.runners'];
}

export const getEngagementDotPoints = (content: contentType) => {
  return dotPoints[content] || dotPoints['sun.runners'];
}