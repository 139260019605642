import { Form, Col, Spinner, Button, Dropdown } from 'react-bootstrap';
import { StandardControlProps, useControlState, wrapFormControl, labelHelper } from '../Base';

export function Label(props: StandardControlProps & { type: 'label' }) {
  const { value, outputProps } = useControlState(props);
  delete outputProps.isInvalid;
  delete outputProps.isValid;
  const { display = {} } = props;
  return <Form.Group ref={props.containerRef} key={props.id} className={`control-group${display.classNames === undefined? '': ` ${display.classNames}`}`} style={{flexDirection: 'column', ...display.group?.style}}>
    {(Array.isArray(value)?value: [value]).map(v => <label style={{ textAlign: 'center', ...display.style}} {...outputProps}>{labelHelper(`${v}`)}</label>)}
    {props.valid!== undefined?<Form.Control.Feedback type="invalid">
          {props.error?props.error:'* required'}
        </Form.Control.Feedback>: undefined}
  </Form.Group>;
}
