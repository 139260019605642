import React, { Component } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { makeRequest } from '../../helper/request';
import { login } from '../../state/action/user';
import { state } from '../../state/reducer/user';


export interface Props extends HeaderProps {
  login: typeof login,
  user: state,
};

export const LoginBase = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  if (props.user !== undefined && props.user.accessToken !== undefined) {
    return <Navigate to="/"/>;
  }
  return  <HeaderPage>
    <Block.Block alternate={true}>
      <Standard.StandardContent>
        <div style={{ padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, maxWidth: 600 }} >
          <Form
            submitHandler={(action, state) => {
              if (action === 'create.account') {
                navigate('/createAccount', { state: { email: (state['username'].value || '').toString() }})
              } else if (action === 'forgot.password') {
                navigate('/resetPassword', { state: { email: (state['username'].value || '').toString() }}) 
              } else if (action === 'login') {
                return makeRequest({
                  type: 'account.login',
                  email: state['username'].value,
                  password: state['password'].value,
                }).then((response) => {
                  if (response.result === 'success' && response.data && response.data.token) {
                    props.login(response.data.token, response.data.refreshToken);
                  } else {
                    const errors: { [key: string]: string } = {};
                    errors['username'] = '* the email entered was invalid';
                    errors['password'] = '* the password entered was invalid';
                    return errors;
                  }
                  return undefined;
                });
              }
              return Promise.resolve(undefined);
            }}
            template={[
              {
                type: 'section',
                title: 'Login',
                items: [],
              },
              {
                type: 'section',
                items: [
                  {
                    title: 'Email',
                    id: 'username',
                    type: 'text',
                    value: (location.state || {}).email || '',
                  },
                  {
                    title: 'Password',
                    id: 'password',
                    type: 'password',
                    value: '',
                    onClick: 'login',
                  }
                ],
              },
              {
                type: 'section',
                items: [
                  {
                    type: 'row',
                    display: {
                      align: 'centre',
                      style: { marginTop: 10 }
                    },
                    items: [      
                      {
                        id: 'access',
                        type: 'button',
                        title: 'Login',
                        value: 'login',
                        onClick: 'login',
                      }
                    ]
                  },
                  {
                    type: 'row',
                    display: {
                      align: 'evenly',
                    },
                    items: [
                      {
                        type: 'label',
                        value: 'reset password',
                        onClick: 'forgot.password',
                        validationRequired: false,
                      },
                      {
                        type: 'label',
                        value: 'create account',
                        onClick: 'create.account',
                        validationRequired: false,
                      },
                    ]
                  }
                ],
              }
            ]} />
        </div>
      </Standard.StandardContent>
    </Block.Block>
  </HeaderPage>;
}

export const Login = connect((state: any) => ({ user: state.user }), { login })(LoginBase);