import React, { Component, useRef, useState } from 'react';
import { redirect, Link, useLocation, Navigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ArrowLeftShort, InfoCircleFill } from 'react-bootstrap-icons';

import { getRequirementsTemplate, Input, Column, CalculationRow, convertValue } from './CalculationTemplate';


const inputSection = (section: {
  inputs: Input[],
} | {
  columns?: Column[],
  outputs: CalculationRow[],
}): section is {
  inputs: Input[],
} => {
  return typeof section === 'object' && section !== null && Array.isArray((section as any).inputs);
}

export interface Props {
  stateChange: (action: string) => void,
  organisation: string,
  content: string,
  deliveryMode: string,
  title: JSX.Element,
  includeBYO?: boolean,
};

export const Calculation = (props: Props) => {
  const [values, setValues] = useState<{ [key: string]: any }>({});
  const valueRef = useRef<{ [key: string]: any }>(values);
  valueRef.current = values;
  let activeColumns: Column[] = [];
  let first = true;
  const sections = getRequirementsTemplate(props.content as any, { includeBYO: props.includeBYO });
  return <div className='small-margin presenter-pack' style={{ padding: 15, flex: 1, flexGrow: 1, zIndex: 1 }} >
    <Col style={{height: '100%'}}>
      <div style={{ paddingBottom: 15}}>
        <Button 
          variant="audioplay-primary"
          style={{ width: 120, height: 50, borderRadius: 30, fontWeight: 700, fontSize: 14 }}
          onClick={() => {
            props.stateChange('');
          }}
        >
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div style={{padding: 0}}><ArrowLeftShort size={20}></ArrowLeftShort></div><div style={{flexGrow:1}}>BACK</div></div> 
        </Button>
      </div>
      <Row className='medium-no-wrap' style={{display: 'flex', flexDirection: 'row'}}>
        <Col style={{minWidth: 300, maxWidth: 350, display: 'flex', flexDirection: 'column'}}>
          {props.title}
          <div style={{height: 30}}></div>
          <div style={{color: 'white', fontWeight: 800, fontSize: 32, textShadow: '2px 2px #000000'}}>INDICATIVE STAFFING & EQUIPMENT</div>
          <div style={{height: 10}}></div>
          <div style={{color: 'white', fontWeight: 400, fontSize: 18, background: '#021625cc', padding: 10, borderRadius: 10}}>
          This calculator gives a rough idea of the the equipment you may need and the staff required to host the Creative Play Installation, based on your circumstances.
          <br/><br/>
          There are many ways skin a cat, so please call and talk to us if the estimates look challenging for you.
          </div>
          <div style={{flexGrow: 1}}></div>
        </Col>
        <Col style={{flexGrow: 0}}>
          <div style={{width: 30, height: 30 }}/>
        </Col>
        <div style={{ display: 'flex', flexDirection: 'column', zIndex:1, overflow: 'auto', alignItems: 'stretch', color: 'white'}}>
          {sections.map((section) => {
            let sectionBody: JSX.Element[] = [];
            if (inputSection(section)) {
              sectionBody = [
                <div className='medium-width' style={{ flexGrow: 0.5}}></div>,
                ...section.inputs.map((input, index) => {
                  const value = valueRef.current[input.name];
                  let c = undefined;
                  if (input.type === 'int' || input.type === 'number') {
                    c = <input className={`grid-input ${input.type === 'number' || input.type === 'int'? 'grid-input-number': ''}`} type={(input.type === 'number' || input.type === 'int')?'number':input.type === 'date'?'date':undefined} value={value} onChange={(event) => {
                      const value = event.target.value;
                      if (input.type === 'number' || input.type === 'int') {
                        let tmp = input.type === 'int'? Number.parseInt(value): Number.parseFloat(value);
                        if (Number.isFinite(tmp) && !Number.isNaN(tmp)) {
                          if (input.range) {
                            if (input.range.low !== undefined && tmp < input.range.low) {
                              tmp = input.range.low;
                            }
                            if (input.range.high !== undefined && tmp > input.range.high) {
                              tmp = input.range.high;
                            }
                          }
                          valueRef.current[input.name] = tmp;
                        } else {
                          delete valueRef.current[input.name];
                        }
                      } else {
                        valueRef.current[input.name] = value;
                      }
                      setValues({ ...valueRef.current });
                    }}></input>;
                  } else if (input.type === 'option') {
                    let v = value;
                    if (v === undefined) {
                      input.options.forEach(o => {
                        if (o.default) {
                          v = o.default;
                        }
                      })
                    }
                    c = <select className={`grid-input`} style={{}} value={v} onChange={(event) => {
                      const value = event.target.value;
                      let v = undefined;
                      input.options.forEach(o => {
                        if (`${o.value}` === value) {
                          v = o.value;
                        }
                      });
                      if (v !== undefined) {
                        valueRef.current[input.name] = v;
                      } else {
                        delete valueRef.current[input.name];
                      }
                      setValues({ ...valueRef.current });
                    }}>
                      {value === undefined? <option selected={true} value='' disabled={true}></option>:undefined}
                      {input.options.map(o => {
                        return <option value={o.value} disabled={o.disabled}>{o.title}</option>
                      })}
                    </select>
                  }
                  const control = <div style={{padding: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', fontWeight: 800}}>
                    <span style={{ paddingRight: 10}}>{input.title}</span>
                    {c}
                    {input.info?<div style={{ marginLeft: 10 }} onClick={() => {
                      props.stateChange(input.info || '');
                    }}>
                      <InfoCircleFill style={{ top: 0, left: 0, position: 'static', backgroundColor: 'white', color: '#021625', width: 20, height: 20, borderRadius: 20 }}></InfoCircleFill>
                    </div>:undefined}
                  </div>;
                  return index === 0? [control]: [<div style={{ flexGrow: 1}}></div>,control];
                }).reduce((a,b) => [...a,...b], []),
                <div className='medium-width' style={{ flexGrow: 0.5}}></div>
              ];
            } else {
              activeColumns = section.columns || activeColumns;
              let allEmpty = activeColumns.filter(i => i.title !== undefined && i.title !== '').length === 0;
              sectionBody = [
                ...(allEmpty?[]:[<div style={{ display: 'table-row', flexDirection: 'row', flexGrow: 0 }}>
                  {activeColumns.map((col,i) => {
                    return <div className='presenter-grid-cell' style={{ ...(section.title === undefined? {}: { borderTop: '1px solid #666c'}), ...(i === 0? {}: { borderLeft: '1px solid #666c' }),  display: 'table-cell', flexGrow: 1}}><div className='presenter-grid-cell-content'>{col.title}</div></div>;
                  })}
                </div>]),
                ...section.outputs.filter(row => {
                  if (row.display) {
                    const display = convertValue(row.display,'boolean',valueRef.current,() => undefined);
                    console.log('Display', display);
                    if (display === false) {
                      return false;
                    }
                  }
                  return true;
                }).map((output,index) => {
                  const values = output.values;
                  return <div style={{ display: 'table-row', flexDirection: 'row', flexGrow: 0 }}>
                    {activeColumns.map((col,i) => {
                      const v = values[col.name];
                      const value = v === undefined? undefined: convertValue(v,col.type,valueRef.current,() => undefined);
                      return <div className='presenter-grid-cell' style={{...(allEmpty && index === 0? {}: { borderTop: '1px solid #666c' }), ...(i === 0? {}: { borderLeft: '1px solid #666c' }), display: 'table-cell', flexGrow: 1 }}><div className='presenter-grid-cell-content'>{value === undefined? '': value}</div></div>;
                    })}
                  </div>;
                })
              ];
            }
            if (section.display) {
              const display = convertValue(section.display,'boolean',valueRef.current,() => undefined);
              if (display === false) {
                return [];
              }
            }
            first = false;
            return [
              ...(first?[]:[<div style={{ height: 10 }}></div>]),
              <div className={inputSection(section)?'grid-input-section':'grid-section'} style={{backgroundColor: inputSection(section)?'#0bd9fe':'#021625cc', flexGrow: 0, border: '2px solid black', borderRadius: 5, display: 'flex', flexDirection: 'column', padding: 10  }}>
                {section.title? <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><span style={{fontSize: 24, fontWeight: 500, padding: 10}}>{section.title}</span></div>: undefined}
                <div style={inputSection(section)? {  display: 'flex', flexDirection: 'row', flexGrow: 1, flexWrap: 'wrap' }: { display: 'table', flexGrow: 1  }}>
                  {sectionBody}
                </div>
              </div>
            ];
          })}
        </div>
      </Row>
    </Col>
  </div>;
}