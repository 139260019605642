import React, { Component, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { makeRequest } from '../../helper/request';

export interface Props extends HeaderProps {

};

export const ResetPassword = (props: Props) => {
  const [sent, setSent] = useState(false);
  const location = useLocation();
  return  <HeaderPage>
    <Block.Block alternate={true}>
      <Standard.StandardContent>
        <div style={{ padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, maxWidth: 600 }} >
          {sent?"A reset email has been sent to your email address if an account exists": <Form
        submitHandler={(action, state) => {
          if (action === 'reset') {
            return makeRequest({
              type: 'account.password.forgot',
              email: state['username'].value,
            }).then((response) => {
              if (response.result === 'success'){
                setSent(true);
                return undefined;
              }
              return { 'submit': 'Error: something went wrong'}
            });
          }
          return Promise.resolve(undefined);
        }}
        template={[
          {
            type: 'section',
            title: 'Reset Password',
            items: [],
          },
          {
            type: 'section',
            items: [
              {
                title: 'Email',
                id: 'username',
                type: 'text',
                onClick: 'reset',
                value: (location.state || {}).email || '',
              }
            ],
          },
          {
            type: 'section',
            items: [
              {
                type: 'row',
                display: {
                  style: {
                    marginTop: 10,
                  },
                  align: 'centre',
                },
                items: [      
                  {
                    id: 'submit',
                    type: 'button',
                    title: 'Reset',
                    value: 'reset',
                    onClick: 'reset',
                  }
                ]
              },
            ],
          }
        ]} />}
        </div>
      </Standard.StandardContent>
    </Block.Block>
  </HeaderPage>;
}