import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { Form as F, Col, Spinner, Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactDatePicker from 'react-datepicker';
import { Control as ControlProps, OptionControl, ControlValue, ControlType } from '../Interface';
import { ElementActionInterface } from '../Base';
import { TimeSpan } from './TimeSpan';
import { StandardControlProps } from './Base';
import { controlGenerators, Checkbox } from './Controls';

const generateId = () => Math.random().toString();

export const validate = (type: ControlProps['type'], value: ControlValue | ControlValue[], optional: boolean): boolean => {
  if (optional) {
    return true;
  }
  if (type === 'text' || type === 'password') {
    return value !== undefined && (value.toString()).length > 0;
  }
  if (!optional && value === undefined || value === null) {
    return false;
  }
  return true;
}

export type Props = Exclude<ControlProps, 'items' | 'onClick'> & {
  controller: ElementActionInterface,
  containerRef?: (node: HTMLElement | null) => void,
  inputRef?: (node: HTMLElement | null) => void,
}

export function Control(props: Props) {
  const [tmp] = useState(generateId());
  const { display = {}, } = props as ControlProps;

  
  const controlProps: StandardControlProps = {
    ...props,
    tmp,
  }
  if (controlGenerators[controlProps.type] === undefined) {
    return null;
  }
  const result = controlGenerators[controlProps.type](controlProps);
  if (display.hidden) {
    return null;
  }
  return result;
}