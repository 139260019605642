import { StandardControlProps, useControlState, wrapFormControl, labelHelper } from '../Base';
import ReactDatePicker from 'react-datepicker';

export function DateControl(props: StandardControlProps & { type: 'date' | 'datetime' | 'time' }) {
  const { value, change, outputProps } = useControlState(props);
  return wrapFormControl(props, outputProps, props.title, undefined, <ReactDatePicker
    ref={(picker: any) => {if(props.inputRef) {props.inputRef(picker)}}}
    {...outputProps}
    showTimeSelect={props.type === 'date'? undefined: true}
    showTimeSelectOnly={props.type === 'time'? true: undefined}
    dateFormat={props.type === 'datetime'? 'dd/MM/yyyy hh:mm a': props.type === 'date'? 'dd/MM/yyyy': 'hh:mm a'}
    timeFormat={props.type === 'date'? '': 'hh:mm a'}
    className={`form-control ${outputProps.isInvalid === true? 'is-invalid': ''}`}
    calendarClassName={props.type === 'time'?'audioplay-time-only':undefined}
    onChange={(date, event) => {
      const d = Array.isArray(date)? date[0]: date;
      if (d) {
        change(d.getTime()*0.001);
      } else if (d == null) {
        change(undefined);
      }
    }}
    selected={typeof value === 'number'? new Date(value*1000): typeof value === 'string'? new Date(value): null}
  />);
}