import { Form, Button, Row, Col } from 'react-bootstrap';
import { ChangeEvent, useCallback, useState } from 'react';
import { StandardControlProps, useControlState, wrapFormControl, labelHelper } from '../Base';

export interface Props {
  onChange: (value: string[]) => void,
  value: string[],
  isValid?: boolean,
  isInvalid?: boolean,
}

export function IS(props: Props) {
  const [value, setValue] = useState('');

  console.log(props.value)
  return <div className={`validation-control ${props.isValid === true? 'is-valid': ''} ${props.isInvalid === true? 'is-invalid': ''}`} style={{ padding: 0, maxWidth: '100%', flexGrow: 1 }}>
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
        <Form.Control value={value} type='text' onChange={(e) => setValue(e.target.value)}></Form.Control>
        <Button onClick={() => {
          if (value !== '') {
            props.onChange([...props.value, value]);
            setValue('');
          }
        }} style={{
          fontFamily: 'Knewave',
          fontSize: 'large',
        }} type={undefined}>ADD</Button>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%', flexWrap: 'wrap' }}>
        {props.value.map((v) => <div style={{ color: '#333', border: '#777', borderWidth: 1, margin: 2, borderStyle: 'solid', borderRadius: 5, display: 'flex', flexDirection: 'row' }} key={v}><span style={{ padding: 4}}>{v}</span><Button variant='clear' onClick={() => {
          if (value !== '') {
            props.onChange([...props.value, value]);
            setValue('');
          }
        }} type={undefined}>-</Button></div>)}
      </div>
    </div>
  </div>;
}


export function ItemSet(props: StandardControlProps & { type: 'itemSet' }) {
  const { value, change, outputProps } = useControlState(props);
  return wrapFormControl(props, outputProps, undefined, undefined, <IS
    {...props}
    onChange={(value) => {
      change(value);
    }}
    value={Array.isArray(value)? value.map(v => `${v}`): []}
  />);
}