
import React, { Component, useState } from 'react';
import { redirect, Link, useLocation, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { ChevronUp, ChevronDown, ChevronExpand, FileEarmarkArrowDownFill, ClipboardDataFill, PersonFill, VolumeUpFill, PlayBtnFill, ArrowLeftShort } from 'react-bootstrap-icons';
import { getEngagementDotPoints } from './PresenterAssets';

export interface Props {
  stateChange: (action: string) => void,
  organisation: string,
  content: string,
  deliveryMode: string,
  title: JSX.Element,
}

export const CommunityEngagement = (props: Props) => {
  const dotPoints = getEngagementDotPoints(props.content as any);
  return <div className='small-margin presenter-pack' style={{ padding: 15, flex: 1, flexGrow: 1, zIndex: 1 }} >
    <Col style={{height: '100%'}}>
      <div style={{ paddingBottom: 15}}>
        <Button 
          variant="audioplay-primary"
          style={{ width: 120, height: 50, borderRadius: 30, fontWeight: 700, fontSize: 14 }}
          onClick={() => {
            props.stateChange('');
          }}
        >
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div style={{padding: 0}}><ArrowLeftShort size={20}></ArrowLeftShort></div><div style={{flexGrow:1}}>BACK</div></div> 
        </Button>
      </div>
      
      <Row style={{justifyContent: 'space-between'}}>

        <Col style={{flexGrow: 1}}>
          <Row style={{ alignItems: 'end'}}>
            {props.title}
            <div style={{width: 30, height: 30}}></div>
            <div style={{color: 'white', fontWeight: 800, fontSize: 32, textShadow: '2px 2px #000000'}}>COMMUNITY ENGAGEMENT</div>
          </Row>
          <div style={{width: 30, height: 30}}></div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <div style={{backgroundColor: '#021625cc', flexGrow: 0, border: '2px solid black', borderRadius: 5, display: 'flex', flexDirection: 'column', maxWidth: 700, padding: 20  }}>
              <div style={{padding: 5, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 16, fontWeight: 300, color: 'white'}}>
              Audioplay can support your season with a range of community engagement tools including
              </div>
              <div style={{padding: 5, display: 'flex',  flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 16, fontWeight: 300, color: 'white'}}>
                <ul style={{margin: 0}}>
                  {dotPoints.map(i => <li>{i}</li>)}
                </ul>
              </div>
              <div style={{padding: 5, display: 'flex',  flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 16, fontWeight: 300, color: 'white'}}>
              We are also open to discussing new community engagement activities that may resonate with your community. Please chat with us if you have ideas you would like to explore.
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  </div>;
}