import { forwardRef, Children, useState } from 'react';
import { Form, Dropdown as ReactDropdown } from 'react-bootstrap';
import { Link as L } from 'react-router-dom';
import { StandardControlProps, useControlState, wrapFormControl, labelHelper } from '../Base';
import { southAustralianSchools } from '../../../../assets/data/schools';

const Toggle = forwardRef<any,any>(({ children, onClick }, ref) => (
  <div
    className='form-control'
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const Menu = forwardRef<any,any>(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');
    const startTest = value.toLowerCase();
    const innerTest = ' '+startTest;
    return (
      <div
        ref={ref}
        style={{...style, padding: 0}}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled" style={{maxHeight: 300, overflowY: 'scroll',margin: 0}}>
          {value === ''? children: Children.toArray(children).filter(
            (child) => {
              const v = (child as any).props.children.toLowerCase();
              return v.startsWith(startTest) || v.includes(innerTest);
            }
          )}
        </ul>
      </div>
    );
  },
);

export function SearchableDropdown(props: StandardControlProps & { type: 'searchableDropdown' }) {
  const { value, change, outputProps } = useControlState(props);
  const { display = {}, options = {}, optionSet } = props;
  const reverseOptions: { [key: string]: string } = {};
  let defaultValue: string | undefined = undefined;
  if (!optionSet){
    Object.entries(options).forEach(([k,v]) => {
      if (typeof v === 'object' && v.default === true) {
        defaultValue = k;
      }
      reverseOptions[typeof v === 'object'? v.value :v] = k;
    });
  }
  (outputProps as any).onSelect = (value: string) => {
    if (optionSet) {
      change(value);
    } else {
      change(reverseOptions[value]);
    }
  }
  let v = options[value !== undefined ? value.toString():''];
  if (optionSet) {
    v = value !== undefined ? value.toString():'';
  }
  if (v === undefined && defaultValue !== undefined) {
    v = defaultValue;
    change(defaultValue);
  }

  const selectValue = typeof v === 'object'? v.value :v;
  return wrapFormControl(props, outputProps, props.title, undefined, <ReactDropdown ref={props.inputRef} {...outputProps}>
    <ReactDropdown.Toggle id="dropdown-autoclose-true" as={Toggle}>
      {selectValue || <span style={{ color: '#aaa'}}>{props.placeholder?props.placeholder.toString():'select'}</span>}
    </ReactDropdown.Toggle>

    <ReactDropdown.Menu as={Menu}>
      {optionSet==='sa-schools'?southAustralianSchools.map((v) => {
        const label = v;
        return <ReactDropdown.Item eventKey={label}>{label}</ReactDropdown.Item>
      }):Object.entries(options).map(([k,v]) => {
        const label = typeof v === 'object'? v.value :v;
        const disabled = typeof v === 'object'? v.disabled :false;
        return <ReactDropdown.Item disabled={disabled} eventKey={label}>{label}</ReactDropdown.Item>
        // return <option disabled={disabled} value={label}>{label}</option>
      })}
    </ReactDropdown.Menu>
  </ReactDropdown>);
}