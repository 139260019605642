import { contentType } from './PresenterAssets';
export type Input = {
  name: string,
  title: string,
} & ({
  type: 'number' | 'int',
  info?: string,
  range?: { low?: number, high?: number }
} | {
  type: 'date',
  info?: string,
  range?: { low?: number, high?: number }
} | {
  type: 'option',
  info?: string,
  options: {
    default?: boolean,
    title: string,
    value: string | number,
    disabled?: boolean,
  }[],
})

export interface Column {
  name: string,
  title: string,
  type: 'number' | 'date' | 'string',
}

export type CalcOperation = (
  { type: 'number', value: number } |
  { type: 'string', value: string } | 
  { type: 'empty' } |
  { type: 'boolean', value: boolean } |
  { type: 'operator', operator: '+' | '-' | '*' | '/' | '==' | '!=' | '<' | '<=' | '>' | '>=' } |
  { type: 'reference', value: string, row?: string } |
  { type: 'function', function: string, arguments: Calc[] } |
  { type: 'group', value: Calc }
);

export type Calc = string | number | CalcOperation[];

export interface CalculationRow {
  name?: string,
  display?: Calc,
  values: {
    [key: string]: Calc,
  }
}

export type Calculation = (({
  inputs: Input[],
} | {
  columns?: Column[],
  outputs: CalculationRow[],
}) & {
  display?: Calc,
  title?: string,
})[];

export const toInnerNode = (value: string | number | undefined | boolean): CalcOperation => {
  if (value === undefined) {
    return { type: 'empty' };
  } else if (typeof value === 'string') {
    return { type: 'string', value: value };
  } else if (typeof value === 'number') {
    return { type: 'number', value: value };
  } else if (typeof value === 'boolean') {
    return { type: 'boolean', value: value };
  }
  return { type: 'empty' };
}

export const valueType = (value: any): value is { value: boolean | string | number } => {
  return typeof value === 'object' && ['boolean', 'string', 'number'].includes(typeof value.value);
}

export const convertValue = (value: Calc, target: 'number' | 'date' | 'string' | 'boolean' | 'any', values: { [key: string]: any }, getRowValues:(row: string) => { [key: string]: any } | undefined): string | number | undefined | boolean => {
  if (Array.isArray(value)) {
    const input: (CalcOperation)[] = [...value];
    for(let i = 0; i < input.length; i ++) {
      const active = input[i];
      if (active.type === 'group') {
        const v = convertValue(active.value, 'number', values, getRowValues);
        input[i] = toInnerNode(v);
      } else if (active.type === 'reference') {
        const v = (active.row? getRowValues(active.row) || {}: values)[active.value]
        input[i] = toInnerNode(v);
      } else if (active.type === 'function') {
        const args = active.arguments.map(a => convertValue(a, 'any', values, getRowValues));
        let value: (CalcOperation) = { type: 'empty' };
        if (active.function === 'contains') {
          value = { type: 'boolean', value: args.slice(1).includes(args[0]) };
        } else if (active.function === 'map') {
          const v = args[0];
          for(let i = 1; i < args.length; i += 2) {
            if (args[i] === v) {
              value = toInnerNode(args[i+1]);
            }
          }
        } else if (active.function === 'all') {
          let all = true;
          args.forEach((v) => {
            if (v !== false && v !== undefined) {

            } else {
              all = false;
            }
          })
          value = { type: 'boolean', value: all };
        } else if (active.function === 'any') {
          let any = false;
          args.forEach((v) => {
            if (v !== false && v !== undefined) {
              any = true;
            }
          })
          value = { type: 'boolean', value: any };
        }
        input[i] = value;
      }
    }

    for(let i = 0; i < input.length; i ++) {
      const active = input[i];
      if (active.type === 'operator' && (active.operator === '*' || active.operator === '/')) {
        if (i === 0 || i === input.length-1) {
          input[i] = { type: 'empty' };
        } else {
          const prev = input[i-1];
          const next = input[i+1];
          let value: (CalcOperation) = { type: 'empty' };
          if (prev.type === 'number' && next.type === 'number') {
            if (active.operator === '*') {
              value = { type: 'number', value: prev.value*next.value };
            } else if (active.operator === '/') {
              const tmp = prev.value/next.value;
              if (Number.isFinite(tmp) && !Number.isNaN(tmp)) {
                value = { type: 'number', value: tmp};
              }
            }
          }
          input.splice(i-1,3,value);
          i -= 1;
        }
      }
    }

    for(let i = 0; i < input.length; i ++) {
      const active = input[i];
      if (active.type === 'operator' && (active.operator === '+' || active.operator === '-')) {
        if (i === 0 || i === input.length-1) {
          input[i] = { type: 'empty' };
        } else {
          const prev = input[i-1];
          const next = input[i+1];
          let value: (CalcOperation) = { type: 'empty' };
          if (prev.type === 'number' && next.type === 'number') {
            if (active.operator === '+') {
              value = { type: 'number', value: prev.value+next.value};
            } else if (active.operator === '-') {
              value = { type: 'number', value: prev.value-next.value};
            }
          }
          input.splice(i-1,3,value);
          i -= 1;
        }
      }
    }
    for(let i = 0; i < input.length; i ++) {
      const active = input[i];
      if (active.type === 'operator' && ['==', '!=', '<', '<=', '>', '>='].includes(active.operator)) {
        if (i === 0 || i === input.length-1) {
          input[i] = { type: 'empty' };
        } else {
          const prev = input[i-1];
          const next = input[i+1];
          let value: (CalcOperation) = { type: 'empty' };
          if (valueType(prev) && valueType(next)) {
            try {
              if (active.operator === '==') {
                value = { type: 'boolean', value: prev.value === next.value };
              } else if (active.operator === '!=') {
                value = { type: 'boolean', value: prev.value !== next.value };
              } else if (active.operator === '<') {
                value = { type: 'boolean', value: prev.value < next.value };
              } else if (active.operator === '<=') {
                value = { type: 'boolean', value: prev.value <= next.value };
              } else if (active.operator === '>') {
                value = { type: 'boolean', value: prev.value > next.value };
              } else if (active.operator === '>=') {
                value = { type: 'boolean', value: prev.value >= next.value };
              }
            } catch {

            }
          }
          if (prev.type === 'empty' || next.type === 'empty') {
            if (prev.type === 'empty' && next.type ==='empty') {
              if (active.operator === '==') {
                value = { type: 'boolean', value: true };
              } else if (active.operator === '!=') {
                value = { type: 'boolean', value: false };
              } else if (active.operator === '<') {
                value = { type: 'boolean', value: false };
              } else if (active.operator === '<=') {
                value = { type: 'boolean', value: true };
              } else if (active.operator === '>') {
                value = { type: 'boolean', value: false };
              } else if (active.operator === '>=') {
                value = { type: 'boolean', value: true };
              }
            } else {
              if (active.operator === '==') {
                value = { type: 'boolean', value: false };
              } else if (active.operator === '!=') {
                value = { type: 'boolean', value: true };
              } else if (active.operator === '<') {
                value = { type: 'boolean', value: prev.type === 'empty' };
              } else if (active.operator === '<=') {
                value = { type: 'boolean', value: prev.type === 'empty' };
              } else if (active.operator === '>') {
                value = { type: 'boolean', value: prev.type !== 'empty' };
              } else if (active.operator === '>=') {
                value = { type: 'boolean', value: prev.type !== 'empty' };
              }
            }
          }
          if (prev.type === 'number' && next.type === 'number') {
            if (active.operator === '+') {
              value = { type: 'number', value: prev.value+next.value};
            } else if (active.operator === '-') {
              value = { type: 'number', value: prev.value-next.value};
            }
          }
          input.splice(i-1,3,value);
          i -= 1;
        }
      }
    }

    const out = input.length > 0? input[0]: undefined;
    if (out !== undefined && (out.type === 'number' || out.type === 'string' || out.type === 'boolean')) {
      return out.value;
    }
    return undefined;
  }
  return value;
}

interface TemplateOptions {
  includeBYO?: boolean
}

export const sunRunnersRequirements = (options: TemplateOptions): Calculation => [
  {
    inputs: [
      {
        name: 'spaces',
        title: 'Play Spaces',
        type: 'option',
        options: [
          {
            title: '1',
            value: 1,
          },
          {
            title: '2',
            value: 2,
          },
          {
            title: '3',
            value: 3,
          },
          {
            title: '4',
            value: 4,
          },
        ],
      },
      {
        name: 'mode',
        title: 'Delivery Mode',
        type: 'option',
        options: [
          {
            title: 'Fully Delivered',
            value: 'full',
          },
          {
            title: 'Remote Delivery - Equipment Provided',
            value: 'remote',
          },
          ...(options.includeBYO === true?[{
            title: 'Remote Delivery - BYO Equipment',
            value: 'byo',
          }]:[]),
        ],
        info: 'tech-select',
      }
    ]
  },
  {
    display: [{ type: 'function', function: 'all', arguments: [[{ type: 'reference', value: 'mode'}, { type: 'operator', operator: '!='}, { type: 'empty' }], [{ type: 'reference', value: 'spaces'}, { type: 'operator', operator: '!='}, { type: 'empty' }]]}],
    columns: [
      {
        name: 'title',
        title: '',
        type: 'string'
      },
      {
        name: 'total',
        title: '',
        type: 'number'
      }
    ],
    outputs: [
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Recommended Staff',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'string', value: '1 Facilitator + 1 extra for the for first half day'}], [{ type: 'number', value: 2}], [{ type: 'string', value: '1 Facilitator + 1 extra for the for first half day'}], [{ type: 'number', value: 3}], [{ type: 'string', value: '2 Facilitators + 1 extra for the for first half day'}], [{ type: 'number', value: 4}], [{ type: 'string', value: '2 Facilitators + 1 extra for the for first half day'}]]}]
        },
      },
      {
        values: {
          title: 'Minimum Space Required',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'string', value: '4m x 4m'}], [{ type: 'number', value: 2}], [{ type: 'string', value: '8m x 4m'}], [{ type: 'number', value: 3}], [{ type: 'string', value: '12m x 4m'}], [{ type: 'number', value: 4}], [{ type: 'string', value: '16m x 4m or 8m x 8m'}]]}]
        },
      },
    ]
  },
  {
    title: 'Presenter to Provide',
    display: [{ type: 'function', function: 'all', arguments: [[{ type: 'reference', value: 'mode'}, { type: 'operator', operator: '!='}, { type: 'empty' }], [{ type: 'reference', value: 'spaces'}, { type: 'operator', operator: '!='}, { type: 'empty' }]]}],
    columns: [
      {
        name: 'title',
        title: '',
        type: 'string'
      },
      {
        name: 'total',
        title: 'Quantity',
        type: 'number'
      }
    ],
    outputs: [
      {
        values: {
          title: 'Chairs',
          description: 'Chairs with legs that kids can crawl under. Ideally not too heavy',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Paper (Packet)',
          description: 'A4 printing paper',
          single: '1',
          total: 1,
        },
      },
      {
        values: {
          title: 'Trestle Table',
          description: 'Storage and management of headphones and devices',
          single: '1 to 2',
          total: '1 to 2',
        },
      },
      {
        values: {
          title: 'Soft floor covering (if required)',
          description: 'Artificial turf, puzzle mats or other soft floor covering to make the floor comfortable to play on',
          single: '1',
          total: 1,
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Phones or Tablets',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}, { operator: '+', type: 'operator'}, { type: 'number', value: 1}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Headphones',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Pouches',
          description: '',
          single: '4',
          total: [{ type: 'number', value: 4}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Facilitator Mobile Phone',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 3}], [{ type: 'number', value: 3}], [{ type: 'number', value: 4}], [{ type: 'number', value:3}]]}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Headphones (Staff)',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 3}], [{ type: 'number', value: 3}], [{ type: 'number', value: 4}], [{ type: 'number', value:3}]]}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Cleaning Cloths and Alcohol Cleaner',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Charging Equipment for all Devices',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Wifi Router',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}]]}],
        values: {
          title: 'Stage Weights',
          description: '',
          single: '10-15kg',
          total: '10-15kg'
        },
      },
      

    ],
  },
  {
    title: 'Audioplay To Provide',
    display: [{ type: 'function', function: 'all', arguments: [[{ type: 'reference', value: 'mode'}, { type: 'operator', operator: '!='}, { type: 'empty' }], [{ type: 'reference', value: 'spaces'}, { type: 'operator', operator: '!='}, { type: 'empty' }]]}],
    outputs: [
      {
        values: {
          title: 'Large Blanket',
          description: 'Queen size or larger, light weight',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        values: {
          title: 'Rubber Bands (Packet)',
          description: 'Large enough to comfortably wear around your wrist',
          single: '1',
          total: 1,
        },
      },
      {
        values: {
          title: 'Towels',
          description: '',
          single: '2',
          total: [{ type: 'number', value: 2}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Laundry Basket',
          description: '',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Bucket',
          description: '',
          single: '2',
          total: [{ type: 'number', value: 2}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Textas (Packet)',
          description: 'Waterbased, washable. These may be used on skin',
          single: '1',
          total: 1,
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Phones or Tablets',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}, { operator: '+', type: 'operator'}, { type: 'number', value: 1}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Headphones',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Pouches',
          description: '',
          single: '4',
          total: [{ type: 'number', value: 4}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Charging Equipment for all Devices',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Cleaning Cloths and Alcohol Cleaner',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Wifi Router',
          description: '',
          single: '1',
          total: '1'
        },
      },
    ]
  },
];

export const turnersRequirements = (options: TemplateOptions): Calculation => [
  {
    inputs: [
      {
        name: 'spaces',
        title: 'Play Spaces',
        type: 'option',
        options: [
          {
            title: '1',
            value: 1,
          },
          {
            title: '2',
            value: 2,
          },
          {
            title: '3',
            value: 3,
          },
          {
            title: '4',
            value: 4,
          },
        ],
      },
      {
        name: 'mode',
        title: 'Delivery Mode',
        type: 'option',
        options: [
          {
            title: 'Fully Delivered',
            value: 'full',
          },
          {
            title: 'Remote Delivery - Equipment Provided',
            value: 'remote',
          },
          ...(options.includeBYO === true?[{
            title: 'Remote Delivery - BYO Equipment',
            value: 'byo',
          }]:[]),
        ],
        info: 'tech-select',
      }
    ]
  },
  {
    display: [{ type: 'function', function: 'all', arguments: [[{ type: 'reference', value: 'mode'}, { type: 'operator', operator: '!='}, { type: 'empty' }], [{ type: 'reference', value: 'spaces'}, { type: 'operator', operator: '!='}, { type: 'empty' }]]}],
    columns: [
      {
        name: 'title',
        title: '',
        type: 'string'
      },
      // {
      //   name: 'description',
      //   title: 'Description',
      //   type: 'string'
      // },
      // {
      //   name: 'single',
      //   title: 'Qty Per Space',
      //   type: 'number'
      // },
      {
        name: 'total',
        title: '',
        type: 'number'
      }
    ],
    outputs: [
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Recommended Staff',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'string', value: '1 Facilitator + 1 extra for the for first half day'}], [{ type: 'number', value: 2}], [{ type: 'string', value: '1 Facilitator + 1 extra for the for first half day'}], [{ type: 'number', value: 3}], [{ type: 'string', value: '2 Facilitators + 1 extra for the for first half day'}], [{ type: 'number', value: 4}], [{ type: 'string', value: '2 Facilitators + 1 extra for the for first half day'}]]}]
        },
      },
      {
        values: {
          title: 'Minimum Space Required',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'string', value: '4m x 4m'}], [{ type: 'number', value: 2}], [{ type: 'string', value: '8m x 4m'}], [{ type: 'number', value: 3}], [{ type: 'string', value: '12m x 4m'}], [{ type: 'number', value: 4}], [{ type: 'string', value: '16m x 4m or 8m x 8m'}]]}]
        },
      },
    ]
  },
  {
    title: 'Presenter to Provide',
    display: [{ type: 'function', function: 'all', arguments: [[{ type: 'reference', value: 'mode'}, { type: 'operator', operator: '!='}, { type: 'empty' }], [{ type: 'reference', value: 'spaces'}, { type: 'operator', operator: '!='}, { type: 'empty' }]]}],
    columns: [
      {
        name: 'title',
        title: '',
        type: 'string'
      },
      // {
      //   name: 'description',
      //   title: 'Description',
      //   type: 'string'
      // },
      // {
      //   name: 'single',
      //   title: 'Qty Per Space',
      //   type: 'number'
      // },
      {
        name: 'total',
        title: 'Quantity',
        type: 'number'
      }
    ],
    outputs: [
      {
        values: {
          title: 'Chairs',
          description: 'Chairs with legs that kids can crawl under. Ideally not too heavy',
          single: '2',
          total: [{ type: 'number', value: 2}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Trestle Table',
          description: 'Storage and management of headphones and devices',
          single: '1 to 2',
          total: '1 to 2',
        },
      },
      {
        values: {
          title: 'Soft floor covering (if required)',
          description: 'Artificial turf, puzzle mats or other soft floor covering to make the floor comfortable to play on',
          single: '1',
          total: 1,
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Landline Phone (Doesn\'t need to be functional)',
          description: '',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Phones or Tablets',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}, { operator: '+', type: 'operator'}, { type: 'number', value: 1}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Headphones',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Pouches',
          description: '',
          single: '4',
          total: [{ type: 'number', value: 4}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Facilitator Mobile Phone',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 3}], [{ type: 'number', value: 3}], [{ type: 'number', value: 4}], [{ type: 'number', value:3}]]}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Headphones (Staff)',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 3}], [{ type: 'number', value: 3}], [{ type: 'number', value: 4}], [{ type: 'number', value:3}]]}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Cleaning Cloths and Alcohol Cleaner',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Charging Equipment for all Devices',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Wifi Router',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}]]}],
        values: {
          title: 'Stage Weights',
          description: '',
          single: '10-15kg',
          total: '10-15kg'
        },
      },
    ],
  },
  {
    title: 'Audioplay To Provide',
    display: [{ type: 'function', function: 'all', arguments: [[{ type: 'reference', value: 'mode'}, { type: 'operator', operator: '!='}, { type: 'empty' }], [{ type: 'reference', value: 'spaces'}, { type: 'operator', operator: '!='}, { type: 'empty' }]]}],
    outputs: [
      {
        values: {
          title: 'Large Blanket',
          description: 'Queen size or larger, light weight',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        values: {
          title: 'Large Sheets',
          description: '',
          single: '2',
          total: [{ type: 'number', value: 2}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Small Ziplock Bags',
          description: '',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Salt',
          description: '',
          single: '1',
          total: '1',
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Landline Phone (Doesn\'t need to be functional)',
          description: '',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Phones or Tablets',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}, { operator: '+', type: 'operator'}, { type: 'number', value: 1}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Headphones',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Pouches',
          description: '',
          single: '4',
          total: [{ type: 'number', value: 4}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Charging Equipment for all Devices',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Cleaning Cloths and Alcohol Cleaner',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Wifi Router',
          description: '',
          single: '1',
          total: '1'
        },
      },
    ]
  },
];

export const bambooBanditsRequirements = (options: TemplateOptions): Calculation => [
  {
    inputs: [
      {
        name: 'spaces',
        title: 'Play Spaces',
        type: 'option',
        options: [
          {
            title: '1',
            value: 1,
          },
          {
            title: '2',
            value: 2,
          },
          {
            title: '3',
            value: 3,
          },
          {
            title: '4',
            value: 4,
          },
        ],
      },
      {
        name: 'mode',
        title: 'Delivery Mode',
        type: 'option',
        options: [
          {
            title: 'Fully Delivered',
            value: 'full',
          },
          {
            title: 'Remote Delivery - Equipment Provided',
            value: 'remote',
          },
          ...(options.includeBYO === true?[{
            title: 'Remote Delivery - BYO Equipment',
            value: 'byo',
          }]:[]),
        ],
        info: 'tech-select',
      }
    ]
  },
  {
    display: [{ type: 'function', function: 'all', arguments: [[{ type: 'reference', value: 'mode'}, { type: 'operator', operator: '!='}, { type: 'empty' }], [{ type: 'reference', value: 'spaces'}, { type: 'operator', operator: '!='}, { type: 'empty' }]]}],
    columns: [
      {
        name: 'title',
        title: '',
        type: 'string'
      },
      // {
      //   name: 'description',
      //   title: 'Description',
      //   type: 'string'
      // },
      // {
      //   name: 'single',
      //   title: 'Qty Per Space',
      //   type: 'number'
      // },
      {
        name: 'total',
        title: '',
        type: 'number'
      }
    ],
    outputs: [
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Recommended Staff',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'string', value: '1 Facilitator + 1 extra for the for first half day'}], [{ type: 'number', value: 2}], [{ type: 'string', value: '1 Facilitator + 1 extra for the for first half day'}], [{ type: 'number', value: 3}], [{ type: 'string', value: '2 Facilitators + 1 extra for the for first half day'}], [{ type: 'number', value: 4}], [{ type: 'string', value: '2 Facilitators + 1 extra for the for first half day'}]]}]
        },
      },
      {
        values: {
          title: 'Minimum Space Required',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'string', value: '4m x 4m'}], [{ type: 'number', value: 2}], [{ type: 'string', value: '8m x 4m'}], [{ type: 'number', value: 3}], [{ type: 'string', value: '12m x 4m'}], [{ type: 'number', value: 4}], [{ type: 'string', value: '16m x 4m or 8m x 8m'}]]}]
        },
      },
    ]
  },
  {
    title: 'Presenter to Provide',
    display: [{ type: 'function', function: 'all', arguments: [[{ type: 'reference', value: 'mode'}, { type: 'operator', operator: '!='}, { type: 'empty' }], [{ type: 'reference', value: 'spaces'}, { type: 'operator', operator: '!='}, { type: 'empty' }]]}],
    columns: [
      {
        name: 'title',
        title: '',
        type: 'string'
      },
      // {
      //   name: 'description',
      //   title: 'Description',
      //   type: 'string'
      // },
      // {
      //   name: 'single',
      //   title: 'Qty Per Space',
      //   type: 'number'
      // },
      {
        name: 'total',
        title: 'Quantity',
        type: 'number'
      }
    ],
    outputs: [
      {
        values: {
          title: 'Chairs',
          description: 'Chairs with legs that kids can crawl under. Ideally not too heavy',
          single: '2',
          total: [{ type: 'number', value: 2}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Trestle Table',
          description: 'Storage and management of headphones and devices',
          single: '1 to 2',
          total: '1 to 2',
        },
      },
      {
        values: {
          title: 'Soft floor covering (if required)',
          description: 'Artificial turf, puzzle mats or other soft floor covering to make the floor comfortable to play on',
          single: '1',
          total: 1,
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Phones or Tablets',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}, { operator: '+', type: 'operator'}, { type: 'number', value: 1}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Headphones',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Pouches',
          description: '',
          single: '4',
          total: [{ type: 'number', value: 4}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Facilitator Mobile Phone',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 3}], [{ type: 'number', value: 3}], [{ type: 'number', value: 4}], [{ type: 'number', value:3}]]}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Headphones (Staff)',
          description: '',
          single: '1',
          total: [{ type: 'function', function: 'map', arguments: [[{ type: 'reference', value: 'spaces'}], [{ type: 'number', value: 1}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 2}], [{ type: 'number', value: 3}], [{ type: 'number', value: 3}], [{ type: 'number', value: 4}], [{ type: 'number', value:3}]]}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Cleaning Cloths and Alcohol Cleaner',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Charging Equipment for all Devices',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'byo'}]]}],
        values: {
          title: 'Wifi Router',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}]]}],
        values: {
          title: 'Stage Weights',
          description: '',
          single: '10-15kg',
          total: '10-15kg'
        },
      },
    ],
  },
  {
    title: 'Audioplay To Provide',
    display: [{ type: 'function', function: 'all', arguments: [[{ type: 'reference', value: 'mode'}, { type: 'operator', operator: '!='}, { type: 'empty' }], [{ type: 'reference', value: 'spaces'}, { type: 'operator', operator: '!='}, { type: 'empty' }]]}],
    outputs: [
      {
        values: {
          title: 'Large Blanket',
          description: 'Queen size or larger, light weight',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        values: {
          title: 'Large Sheets',
          description: 'Double bed size sheets',
          single: '2',
          total: [{ type: 'number', value: 2}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Bucket',
          description: '',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Textas (Packet)',
          description: 'Waterbased, washable. These may be used on skin',
          single: '1',
          total: '1',
        },
      },
      {
        values: {
          title: 'Heliotrope Prop',
          description: '',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Umbrella',
          description: 'Large, golf umbrella with easy push button open',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Stick',
          description: 'Like a broom stick - not bamboo as it can confuse the story',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Shoe Box',
          description: '',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Plastic Jewels',
          description: '',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        values: {
          title: 'Mark Up Tape',
          description: '',
          single: '1',
          total: '1',
        },
      },
      {
        values: {
          title: 'Leaves',
          description: 'Real or artificial - in the story they are bamboo leaves, but work with what you have',
          single: '1',
          total: [{ type: 'number', value: 1}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}],
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Phones or Tablets',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}, { operator: '+', type: 'operator'}, { type: 'number', value: 1}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Headphones',
          description: '',
          single: '6',
          total: [{ type: 'number', value: 6}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Pouches',
          description: '',
          single: '4',
          total: [{ type: 'number', value: 4}, { operator: '*', type: 'operator' }, { type: 'reference', value: 'spaces'}]
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Charging Equipment for all Devices',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Cleaning Cloths and Alcohol Cleaner',
          description: '',
          single: '1',
          total: '1'
        },
      },
      {
        display: [{ type: 'function', function: 'contains', arguments: [[{ type: 'reference', value: 'mode'}], [{ type: 'string', value: 'full'}], [{ type: 'string', value: 'remote'}]]}],
        values: {
          title: 'Wifi Router',
          description: '',
          single: '1',
          total: '1'
        },
      },
    ]
  },
];

export const getRequirementsTemplate = (content: contentType, options: TemplateOptions) => {
  const generators: { [key in contentType]: (options: TemplateOptions) => Calculation } = {
    'bamboo.bandits': bambooBanditsRequirements,
    'sun.runners': sunRunnersRequirements,
    'the.turners': turnersRequirements,
  }
  return (generators[content] || generators['sun.runners'])(options);
}