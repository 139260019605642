import { REGISTER, UNREGISTER } from "../action/register";

const initialState: {
  registrations: { [key: string]: string },
} = {
  registrations: {},
};

export type state = typeof initialState;

export default (state = initialState, action: any) => {
  if (action.type === REGISTER) {
    return Object.assign({}, state, { registrations: Object.assign(state.registrations, { [action.campaignId]: action.accessToken }) });
  }
  if (action.type === UNREGISTER) {
    return Object.assign({}, state, { registrations: Object.assign(state.registrations, { [action.campaignId]: undefined }) });
  }
  return state;
}
