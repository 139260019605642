import React, { Component, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeRequest } from '@audioplay/client';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { login } from '../../state/action/user';
import { state } from '../../state/reducer/user';


export interface Props extends HeaderProps {
  user: state,
};

export const DeleteBase = (props: Props) => {
  const [submitted, setSubmitted] = useState(false);
  if (submitted) {
    return <HeaderPage>
      <Block.Block alternate={true}>
        <Standard.StandardContent verticalAlign={true} >
          <center><p><span style={{ fontSize: 30, fontWeight: 800, fontFamily: 'Knewave' }}>WE’RE SORRY TO SEE YOU GO</span><br/><br/>Please confirm you want to delete your account via the email we sent you</p></center>
        </Standard.StandardContent>
      </Block.Block>
    </HeaderPage>;
  }
  return <HeaderPage>
    <Block.Block alternate={true}>
      <Standard.StandardContent verticalAlign={true}>
        <div style={{ padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, maxWidth: 600 }} >
          <Form
            submitHandler={(action, state) => {
              if (action === 'delete') {
                return makeRequest('delete.account', {
                  email: typeof state['username'].value === 'string'? state['username'].value: undefined
                })
                  .then((res) => {
                    if (res.result === 'success') {
                      setSubmitted(true);
                      return undefined;
                    }
                    const errors: { [key: string]: string } = {};
                    errors['delete'] = 'something went wrong please try again';
                    return errors;
                  });
              }
              return Promise.resolve(undefined);
            }}
            template={[
              {
                type: 'section',
                title: 'DELETE ACCOUNT',
                display: { title: { style: { fontSize: 30, fontWeight: 800, fontFamily: 'Knewave' }}},
                items: [],
              },
              {
                type: 'section',
                items: [
                  {
                    type: 'custom',
                    render: () => <p>To delete your account<ul><li>Enter your email address below</li><li>We’ll email to confirm you want us to delete your account</li></ul>Once your account is deleted you’ll lose access to any content you’ve previously purchased.<br/><br/> </p>
                  }
                ],
              },
              {
                type: 'section',
                items: [
                  {
                    title: 'Email',
                    id: 'username',
                    type: 'text',
                    value: '',
                  }
                ],
              },
              {
                type: 'section',
                items: [
                  {
                    type: 'row',
                    display: {
                      align: 'centre',
                      style: { marginTop: 10 }
                    },
                    items: [      
                      {
                        id: 'delete',
                        type: 'button',
                        title: 'DELETE ACCOUNT',
                        value: 'delete',
                        onClick: 'delete',
                      }
                    ]
                  }
                ],
              }
            ]} />
        </div>
      </Standard.StandardContent>
    </Block.Block>
  </HeaderPage>;
}

export const Delete = connect((state: any) => ({ user: state.user }), {})(DeleteBase);