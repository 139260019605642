import React, { MouseEvent } from 'react';
import { Row as R, Form as F } from 'react-bootstrap';
import { Row as RowProps, mapAlignment } from './Interface';
import { ElementActionInterface } from './Base';

export type Props = Omit<RowProps, 'items' | 'onClick'> & {
  children?: React.ReactNode,
  controller: ElementActionInterface,
  containerRef?: (node: HTMLElement | null) => void,
};

export function Row(props: Props) {
  const { display = {}, valid, error, containsHiddenInvalid } = props;
  if (display.hidden === true) {
    return null;
  }
  const click = props.controller.onClick;
  return (props.wrapper || ((e: JSX.Element) => e))(<R
    key={props.id}
    ref={props.containerRef}
    className={`form-row${display.classNames? ' '+display.classNames:''} ${(valid === false || containsHiddenInvalid === true) && display.validate === true ?'is-invalid': ''}`}
    style={{ justifyContent: mapAlignment(display.align), ...display.style }}
    onClick={click?(e: MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      click();
    }:undefined}>
    {props.title === undefined? undefined: <div className="title text" style={display.title?.style}>{props.title}</div>}
    {props.children}
    {valid === false && display.validate === true ?<F.Control.Feedback type="invalid" style={{ display: 'initial', textAlign: 'center'}}>
        {error !== undefined?error:'* error'}
      </F.Control.Feedback>: undefined}
  </R>, props);
}