import { Form, Col, Spinner, Button, Dropdown } from 'react-bootstrap';
import { Link as L } from 'react-router-dom';
import { StandardControlProps, useControlState, wrapFormControl, labelHelper } from '../Base';
import { useState } from 'react';

export function Checkbox(props: StandardControlProps & { type: 'checkbox' }) {
  const { value, change, outputProps } = useControlState(props);
  outputProps.className += ' checkbox';
  (outputProps as any).onChange = (event: React.ChangeEvent<any>) => {
    event.stopPropagation();
    if (event.target === undefined){
      return;
    }
    change(event.target.checked);
  }
  delete (outputProps as any).onClick;
  const { display = {} } = props;
  // const { props: displayProps = {} } = display;
  // return <input type='checkbox' style={{ width: 20, height: 20, minWidth: 20, minHeight: 20, marginRight: 20, ...display.style }} checked={checked} onChange={e => {
  //   console.log('PERSIST');
  //   (outputProps as any).onChange(e);
  //   setChecked(!checked)
  // }} />;
  if (props.title) {
    return <Form.Group ref={props.containerRef} key={props.id} style={{justifyContent: 'left', ...display.group?.style}}>
      <Col sm="auto" style={{ paddingRight: 20, display: 'flex', flexDirection: 'row', alignItems: display.align === 'start'? 'baseline': display.align === 'end'? 'end': 'center'}}>
        <Form.Control ref={props.inputRef} {...outputProps} value={undefined} style={{ width: 20, height: 20, minWidth: 20, minHeight: 20, marginRight: 20, ...display.style }} checked={value === true}></Form.Control>
        <Form.Label column sm="auto">{labelHelper(props.title || '')}</Form.Label>
      </Col>
      {props.valid === false?<Form.Control.Feedback type="invalid" style={{display: 'block'}}>
            {props.error?props.error:'* required'}
          </Form.Control.Feedback>: undefined}
    </Form.Group>;
  } else {
    return <Form.Group ref={props.containerRef} key={props.id} className='control-group' style={{ display: 'flex', alignItems: 'center', ...display.group?.style}}>
      <Form.Control ref={props.inputRef} {...outputProps} value={undefined} style={{ width: 20, height: 20, minWidth: 20, minHeight: 20, ...display.style }} checked={value === true}></Form.Control>
      {props.valid === false?<Form.Control.Feedback type="invalid" style={{display: 'block'}}>
            {props.error?props.error:'* required'}
          </Form.Control.Feedback>: undefined}
    </Form.Group>;
  }
}