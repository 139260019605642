import React, { Component, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeRequest } from '@audioplay/client';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';


import { login } from '../../state/action/user';
import { state } from '../../state/reducer/user';


export interface Props extends HeaderProps {
  login: typeof login,
  user: state,
};

export const LoginBase = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [state, setState] = useState<'scope.invalid' | 'new' | 'password.setup' | 'login' | 'initial'>('initial');
  
  if (props.user !== undefined && props.user.accessToken !== undefined) {
    return <Navigate to="/" />;
  };

  let form = undefined;
  if (state === 'initial') {
    form = <Form
      key={'form.start'}
      submitHandler={(action, state) => {
        if (action === 'login') {
          const e = state['email'].value as string;
          return makeRequest('account.scoped.login.start', {
            email: e,
            scope: ['web','public'],
          }).then((response) => {
            setEmail(e);
            if (response.result === 'success') {
              setState(response.status);
            } else {
              setState('scope.invalid');
            }
            return undefined;
          });
        }
        return Promise.resolve(undefined);
      }}
      template={[
        {
          type: 'section',
          title: 'Sign in',
          items: [],
        },
        {
          type: 'section',
          items: [
            {
              title: 'Email',
              id: 'email',
              type: 'text',
              value: email || (location.state || {}).email || '',
              onClick: 'login',
            }
          ],
        },
        {
          type: 'section',
          items: [
            {
              type: 'row',
              display: {
                align: 'centre',
                style: { marginTop: 10 }
              },
              items: [      
                {
                  id: 'access',
                  type: 'button',
                  title: 'CONTINUE',
                  value: 'login',
                  onClick: 'login',
                }
              ]
            }
          ],
        }
      ]} />;
  } else if (state === 'login') {
    form = <Form
      key={'form.login'}
      submitHandler={(action, state) => {
        if (action === 'login') {
          return makeRequest('account.scoped.login', {
            email: email || '',
            authorisation: {
              password: state['password'].value as string,
            },
            scope: ['web','public'],
          }).then((response) => {
            if (response.result === 'success' && response.token) {
              props.login(response.token, response.refreshToken, response.configuration);
              navigate('/');
            } else {
              const errors: { [key: string]: string } = {};
              errors['password'] = '* the password entered was invalid';
              return errors;
            }
            return undefined;
          });
        } else if (action === 'forgot.password') {
          navigate('/resetPassword', { state: { email: email || '' }}) 
        }
        return Promise.resolve(undefined);
      }}
      template={[
        {
          type: 'section',
          title: 'Presenter Sign in',
          items: [],
        },
        {
          type: 'section',
          items: [
            {
              title: 'Email',
              id: 'email',
              type: 'text',
              display: {
                disabled: true,
              },
              value: email || '',
            },
            {
              title: 'Password',
              id: 'password',
              type: 'password',
              value: '',
              onClick: 'login',
            }
          ],
        },
        {
          type: 'section',
          items: [
            {
              type: 'row',
              display: {
                align: 'centre',
                style: { marginTop: 10 }
              },
              items: [      
                {
                  id: 'access',
                  type: 'button',
                  title: 'Login',
                  value: 'login',
                  onClick: 'login',
                }
              ]
            },
            {
              type: 'row',
              display: {
                align: 'centre',
                style: { marginTop: 10 }
              },
              items: [
                {
                  type: 'label',
                  value: 'reset password',
                  onClick: 'forgot.password',
                  validationRequired: false,
                },
              ]
            }
          ],
        }
      ]} />;
    } else if (state === 'password.setup') {
      form = <Form
        key={'form.create.password'}
        submitHandler={(action, state) => {
          if (action === 'login') {
            const errors: { [key: string]: string } = {};
            const password = state['password'].value===undefined?'':state['password'].value.toString();
            if (password.length < 8 || !/[0-9]/.test(password) || !/[A-Z]/.test(password) ) {
              errors['password'] = '* passwords must be at least 8 characters and contain an uppercase letter and a number';
            }
            if (state['password'].value !== state['password.confirm'].value) {
              errors['password.confirm'] = '* passwords must match';
            }
            if (Object.keys(errors).length > 0) {
              return Promise.resolve(errors)
            }
            return makeRequest('account.scoped.create.password', {
              email: email || '',
              password: password,
              scope: ['web','public'],
            }).then((response) => {
              if (response.result === 'success' && response.token) {
                props.login(response.token, response.refreshToken, response.configuration);
                navigate('/');
              } else {
                const errors: { [key: string]: string } = {};
                errors['password'] = '* the password entered was invalid';
                return errors;
              }
              return undefined;
            });
          } else if (action === 'forgot.password') {
            navigate('/resetPassword', { state: { email: email || '' }}) 
          }
          return Promise.resolve(undefined);
        }}
        template={[
          {
            type: 'section',
            title: 'Create Password',
            items: [
              {
                type: 'label',
                value: 'Please create a password to complete setting up your account'
              }
            ],
          },
          {
            type: 'section',
            items: [
              {
                title: 'Email',
                id: 'email',
                type: 'text',
                display: {
                  disabled: true,
                },
                value: email || '',
              },
              {
                title: 'Password',
                id: 'password',
                type: 'password',
                value: '',
              },
              {
                title: 'Confirm Password',
                id: 'password.confirm',
                type: 'password',
                value: '',
                onClick: 'login',
              }
            ],
          },
          {
            type: 'section',
            items: [
              {
                type: 'row',
                display: {
                  align: 'centre',
                  style: { marginTop: 10 }
                },
                items: [      
                  {
                    id: 'access',
                    type: 'button',
                    title: 'CREATE PASSWORD',
                    value: 'login',
                    onClick: 'login',
                  }
                ]
              }
            ],
          }
        ]} />;
  } else if (state === 'new' || state === 'scope.invalid') {
    form = <div>Sorry your account isn't able to login through the website at this time<br/><br/>Please contact <a href='mailto:support@audioplay.me'>support@audioplay.me</a> to request access</div>
  }
  return <HeaderPage>
    <Block.Block alternate={true}>
      <Standard.StandardContent>
        <div style={{ padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, maxWidth: 600 }} >
          {form}
        </div>
      </Standard.StandardContent>
    </Block.Block>
  </HeaderPage>;
}

export const Login = connect((state: any) => ({ user: state.user }), { login })(LoginBase);