
import { Form, Col, Spinner, Button, Dropdown } from 'react-bootstrap';
import { ImageUpload, CircularBorder, ImageDisplay } from '../../../elements/ImageUpload';

import { StandardControlProps, useControlState, wrapFormControl, labelHelper } from '../Base';

export function Image(props: StandardControlProps & { type: 'img' }) {
  const { value, change, outputProps } = useControlState(props);
  const { display = {} } = props;
  const { props: displayProps = {} } = display;
  if (displayProps.simpleImage) {
    return wrapFormControl(props, outputProps, undefined, undefined, <img src={value !== undefined ? value.toString():''} alt={props.title} style={display.style}/>);
  }
  if (display.disabled) {
    return wrapFormControl(props, outputProps, props.title, undefined, <ImageDisplay
      className={`validation-control`}
      mask={displayProps.mask === 'circle'? CircularBorder : undefined}
      url={value !== undefined ? value.toString():''}
      height={displayProps.imageHeight !== undefined? displayProps.imageHeight: 100}
      width={displayProps.imageWidth !== undefined? displayProps.imageWidth: undefined}
    />);
  } else {
    return wrapFormControl(props, outputProps, props.title, undefined, <ImageUpload
      className={`validation-control ${outputProps.isInvalid === true? 'is-invalid': ''}`}
      mask={displayProps.mask === 'circle'? CircularBorder : undefined}
      url={value !== undefined ? value.toString():''}
      height={displayProps.imageHeight !== undefined? displayProps.imageHeight: 100}
      width={displayProps.imageWidth !== undefined? displayProps.imageWidth: undefined}
      imageUpdated={(image) => {
        change(image);
      }}
    />);
  }
}