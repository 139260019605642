import gtm from 'react-gtm-module';

export const getTagManager = () => {
  if (getTagManager._INIT) {
    return;
  }
  getTagManager._INIT = true;
  const gtmId = process.env.GOOGLE_CONVERSION_TRACKING_ID || 'GTM-5939MFQ7';
  if (gtmId === undefined) {
    return;
  }
  gtm.initialize({
    gtmId,
    dataLayer: {
      'js': new Date(),
      'event': 'Initialize'
    },
  });
}
getTagManager._INIT = false;

export const trackEvent = (event: string, eventData?: { [key: string]: any }) => {
  const gtmId = process.env.GOOGLE_CONVERSION_TRACKING_ID || 'GTM-5939MFQ7';
  if (gtmId === undefined) {
    if (eventData && eventData['eventCallback']) {
      eventData['eventCallback']();
    }
    return;
  }
  getTagManager();
  gtm.dataLayer({
    dataLayer: {
      'js': new Date(), // current date time,
      event: event,
      ...eventData,
    }
  })
}