import { Route } from 'react-router-dom';

import * as Register from '../../registration';
import { Redirect } from '../../../components/nav/Redirect';
import { RouteBundleProps } from '../common';

export const register = (props: RouteBundleProps) => {
  return [
    <Route path="/register/:id" element={<Register.RegisterBase/>} />,
    <Route path="/page/:id" element={<Register.RegisterBase/>} />,
  ];
}