import React, { Component, useState } from 'react';
import { redirect, Link, useLocation, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { ChevronUp, ChevronDown, ChevronExpand, FileEarmarkArrowDownFill, ClipboardDataFill, PersonFill, VolumeUpFill, PlayBtnFill, ArrowLeftShort, Envelope, Instagram, Twitter, Facebook, Telephone  } from 'react-bootstrap-icons';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage,  Props as HeaderProps } from '../../components/page/HeaderPage';

import { makeRequest } from '../../helper/request';
import { state } from '../../state/reducer/user';
import { Calculation } from './Calculation';
import { DeliveryMode } from './DeliveryMode';
import { Prices } from './Prices';
import { CommunityEngagement } from './CommunityEngagement';
import { openInNewTab } from './helper';
import * as PresenterAssets from './PresenterAssets';

export interface Props extends HeaderProps {
  user: state,
};

export const PresenterPackBase = (props: Props) => {
  const [state, setState] = useState<string | undefined>();
  if (props.user.accessToken === undefined) {
    return <Navigate to="/"/>;
  }
  const presenterInfo = (props.user.userConfiguration || []).find(i => i.type === 'data' && i.name === 'web.presenter');
  let {
    content = 'unknown',
    deliveryMode  = 'unknown',
    organisation = 'unknown',
  } = ((presenterInfo || {}).data || {});

  const seriesLogo = PresenterAssets.getTitle(content);
  const titleText = PresenterAssets.getTitleText(content);
  const background = PresenterAssets.getBackground(content);
  const character = PresenterAssets.getCharacter(content);
  const docs = PresenterAssets.getDocs(content);

  const title = <img src={seriesLogo} alt={titleText} style={{ width: '80%', maxWidth: 300 }}/>
  const backgroundElement = <div style={{ position: 'absolute', minWidth: '100%', minHeight: '100%', backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center' }}/>


  if (state === 'calc') {
    return <HeaderPage alignContent='fill'>
      <Block.Block alternate={true}>
        {backgroundElement}
        <Calculation stateChange={(state) => setState(state)} content={content} includeBYO={docs.byo !== undefined} deliveryMode={deliveryMode} organisation={organisation} title={title}/>
      </Block.Block>
    </HeaderPage>;
  }
  if (state === 'engagement') {
    return <HeaderPage alignContent='fill'>
      <Block.Block alternate={true}>
        {backgroundElement}
        <CommunityEngagement stateChange={(state) => setState(state)} content={content} deliveryMode={deliveryMode} organisation={organisation} title={title}/>
      </Block.Block>
    </HeaderPage>;
  }
  if (state === 'prices') {
    return <HeaderPage alignContent='fill'>
      <Block.Block alternate={true}>
        {backgroundElement}
        <Prices stateChange={(state) => setState(state)} content={content} includeBYO={docs.byo !== undefined} deliveryMode={deliveryMode} organisation={organisation}  title={title}/>
      </Block.Block>
    </HeaderPage>;
  }
  if (state === 'tech-select') {
    return <HeaderPage alignContent='fill'>
      <Block.Block alternate={true}>
        {backgroundElement}
          <DeliveryMode
            buttonLabel={{
              byo: 'Technical Specification',
              remote: 'Technical Specification',
              full: 'Contact',
            }}
            icon={{
              byo: <FileEarmarkArrowDownFill size={20}></FileEarmarkArrowDownFill>,
              remote: <FileEarmarkArrowDownFill size={20}></FileEarmarkArrowDownFill>,
              full: <PersonFill size={20}></PersonFill>,
            }}
            title={title}
            stateChange={(action) => setState(action)}
            includeBYO={docs.byo !== undefined}
            optionSelected={(selection) => {
              if (selection === 'byo') {
                if (docs.byo) {
                  openInNewTab(docs.byo);
                }
              } else if (selection === 'remote') {
                openInNewTab(docs.remote)
              } else if (selection === 'full') {
                setState('contact');
                return;
              }
              setState('');
            }}
          />
      </Block.Block>
    </HeaderPage>;
  }

  if (state === 'contact') {
    return <HeaderPage alignContent='fill'>
      <Block.Block alternate={true}>
        {backgroundElement}
        <div className='small-margin presenter-pack' style={{ padding: 15, flex: 1, flexGrow: 1, zIndex: 1 }} >
          <Col style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{ paddingBottom: 15}}>
              <Button 
                variant="audioplay-primary"
                style={{ width: 120, height: 50, borderRadius: 30, fontWeight: 700, fontSize: 14 }}
                onClick={() => {
                  setState('');
                }}
              >
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div style={{padding: 0}}><ArrowLeftShort size={20}></ArrowLeftShort></div><div style={{flexGrow:1}}>BACK</div></div> 
              </Button>
            </div>
            
            <Row style={{justifyContent: 'space-between', flexGrow: 1}}>
              <Col style={{minWidth: 300, maxWidth: 350, display: 'flex', flexDirection: 'column'}}>
                {title}
                <div style={{height: 30}}></div>
                <div style={{color: 'white', fontWeight: 800, fontSize: 32, textShadow: '2px 2px #000000'}}>LET'S TALK</div>
                <div style={{height: 20}}></div>
                <div style={{color: 'white', fontWeight: 500, fontSize: 18, background: '#021625cc', padding: 20, borderRadius: 10}}>
                  Gemma Pepper - Producer<br/><br/>
                  <a href='mailto:gemma@audioplay.me' style={{color: 'white'}}><Envelope size={24}></Envelope><span style={{ paddingLeft: 20 }}>gemma@audioplay.me</span></a><br/>
                  <a href='tel:(+618) 8121 4404' style={{color: 'white'}}><Telephone size={24}></Telephone><span style={{ paddingLeft: 20 }}>+61 8 8121 4404</span></a><br/>
                  <br></br>
                  <a href='https://www.instagram.com/audioplaykids/' style={{color: 'white', paddingRight: 20 }}><Instagram size={24}></Instagram></a>
                  <a href='https://www.facebook.com/audioplaykids' style={{color: 'white'}}><Facebook size={24}></Facebook></a>

                </div>
                <div style={{flexGrow: 1}}></div>
              </Col>
              <div style={{width: 30, height: 30}}></div>
              <Col style={{flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end', alignItems: 'end', paddingTop: 30}}>
                {character === undefined? undefined: <img src={character} style={{maxWidth: 350}}></img>}
              </Col>
            </Row>
        </Col>
        </div>
      </Block.Block>
    </HeaderPage>;
  }

  return <HeaderPage alignContent='fill'>
    <Block.Block alternate={true}>
      {/* <Standard.StandardContent> */}
        {/* <div style={{ position: 'absolute', minWidth: '100%', minHeight: '100%', background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${background})`, backgroundSize: 'cover', backgroundPosition: 'left bottom' }}/> */}
        {backgroundElement}
        <div className='presenter-pack' style={{ padding: 15, margin: 15, marginLeft: 30, marginRight: 30, flex: 1, flexGrow: 1 }} >
          <Row style={{height: '100%'}}>
            <Col style={{minWidth: 300, maxWidth: 350, display: 'flex', flexDirection: 'column'}}>
              {title}
              <div style={{height: 30}}></div>
              <div style={{color: 'white', fontWeight: 800, fontSize: 32, textShadow: '2px 2px #000000'}}>INSTALLATION PRESENTER PACK</div>
              <div style={{height: 20}}></div>
              <div style={{flexGrow: 1}}></div>
              <div style={{color: 'white', fontWeight: 800, fontSize: 20, background: '#021625bb', padding: 20, borderRadius: 10}}>
              <i>"Hosting Audioplay was a genuine pleasure... Some families came in specifically for the ‘Audioplay’ and were just as delighted as the unsuspecting families"</i>
  <br/>- State Library of South Australia
              </div>
              <div style={{flexGrow: 1}}></div>
            </Col>
            <Col style={{flexGrow: 0}}>
              <div style={{width: 50 }}/>
            </Col>
            <Col style={{flexGrow: 1}}>
              <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginLeft: -20 }}>
                {([
                  {
                    title: 'Delivery Mode & Tech Spec',
                    icon: <FileEarmarkArrowDownFill size={20}></FileEarmarkArrowDownFill>,
                    state: 'tech-select'
                  },
                  {
                    title: 'Marketing Kit',
                    icon: <FileEarmarkArrowDownFill size={20}></FileEarmarkArrowDownFill>,
                    download: docs.marketing,
                  },
                  {
                    title: 'Staffing & Equipment Calculator',
                    icon: <ClipboardDataFill size={20}></ClipboardDataFill>,
                    state: 'calc'
                  },
                  {
                    title: 'Community Engagement',
                    icon: <PersonFill size={20}></PersonFill>,
                    state: 'engagement'
                  },
                  {
                    title: 'Indicative Pricing',
                    icon: <ClipboardDataFill size={20}></ClipboardDataFill>,
                    state: 'prices'
                  },
                  // {
                  //   title: 'Audio Sample',
                  //   icon: <VolumeUpFill size={20}></VolumeUpFill>,
                  // },
                  // {
                  //   title: 'Price Guide',
                  //   icon: <ClipboardDataFill size={20}></ClipboardDataFill>,
                  // },
                  docs.promo?{
                    title: 'Promo Video',
                    icon: <PlayBtnFill size={20}></PlayBtnFill>,
                    url: docs.promo,
                  }:undefined,
                  {
                    title: 'Contact',
                    icon: <PersonFill size={20}></PersonFill>,
                    state: 'contact',
                  },
                ] as { download?: string, url?: string, state?: string, icon?: JSX.Element, title: string }[]).map((item) => {
                  
                  if ((item.download || item.url) !== undefined) {
                    return <div style={{ padding: 20, flexGrow: 0, flexBasis: '27%' }}>
                    <a href={item.download || item.url} target='_black' style={{color:'black'}}>
                    <Button 
                      variant="audioplay-primary"
                      style={{ width: 220, height: 70, borderRadius: 30, fontWeight: 700, fontSize: 14 }}
                    >
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div style={{padding: 10}}>{item.icon}</div><div style={{flexGrow:1}}>{item.title}</div></div>
                    </Button>
                    </a>
                  </div>;
                  }
                  return <div style={{ padding: 20, flexGrow: 0, flexBasis: '27%' }}>
                    <Button 
                      variant="audioplay-primary"
                      style={{ width: 220, height: 70, borderRadius: 30, fontWeight: 700, fontSize: 14 }}
                      onClick={() => {
                        if (item.state) {
                          setState(item.state);
                        }
                      }}
                    >
                      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div style={{padding: 10}}>{item.icon}</div><div style={{flexGrow:1}}>{item.title}</div></div> 
                    </Button>
                </div>;
                  
                })}
              </div>
            </Col>
          </Row>
          
        </div>
      {/* </Standard.StandardContent> */}
    </Block.Block>
  </HeaderPage>;
}

export const PresenterPack = connect((state: any) => ({ user: state.user }), { })(PresenterPackBase);