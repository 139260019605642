export const makeRequest = (data?: any) => {
  return fetch(
    process.env.API_URL || 'https://ugaa5f4r18.execute-api.ap-southeast-2.amazonaws.com/live/api',
    {
      body: data===undefined? undefined: JSON.stringify(data),
      method: data===undefined? "GET": "POST",
      headers: {
        'Content-Type': 'application/json'
      }
    },
  )
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      return Promise.reject(err);
    })

}