import React, { Component, useState } from 'react';
import { Buffer } from 'buffer';
import { useLocation, useParams } from 'react-router-dom';
import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { makeRequest } from '@audioplay/client';

export interface Props extends HeaderProps {

};


export const FinishAccountCreation = (props: Props) => {
  const [sent, setSent] = useState(false);
  const params = useParams();
  let token = params['token'];
  if (token) {
    token = Buffer.from(token, "base64").toString();
  }
  let apple = 'https://apps.apple.com/app/apple-store/id1361477655';
  let android = 'https://play.google.com/store/apps/details?id=au.com.audioplay';
  
  return <HeaderPage>
    <Block.Block alternate={true}>
      <Standard.StandardContent>
        <div style={{ padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, maxWidth: 600 }} >
          {sent?<div style={{backgroundColor: '#f2f2f2', padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, width: '100%', display: 'flex', flexDirection: 'column', alignItems : 'center  ' }}>
          <div style={{ fontSize: 24, textAlign: 'center', fontWeight: 600, paddingBottom: 20 }}>Great you're account is ready to go!</div>
          <br></br>
          <div style={{ paddingBottom: 20 }}>Download the Audioplay App and sign in with the account you've just finished setting up</div>

          <div style={{height:50, display: 'flex', flexDirection: 'row' }}>
            <a href={apple} style={{height:'100%'}}><img src="/images/ios-download.png" alt="ios app store download" style={{height:'100%'}}></img></a>
            <div style={{width: 20}}/>
            <a href={android} style={{height:'100%'}}><img src="/images/android-download.png" alt="google play store download" style={{height:'100%'}}></img></a>
          </div>
          <div style={{ paddingBottom: 20, paddingTop: 20 }}>We can't wait to have you play!</div>
          <img className="image" src="/logo512-pink.png" alt="audioplay logo" style={{width: 100 }}/>
          <img className="tag-line image" src="/logo-black.png" alt="audioplay tagline" style={{width: 200 }} />
        </div>:<Form
        submitHandler={(action, state) => {
          if (action === 'reset') {
            const errors: { [key: string]: string } = {};
            const password = state['password'].value===undefined?'':state['password'].value.toString();
            if (password.length < 8 || !/[0-9]/.test(password) || !/[A-Z]/.test(password) ) {
              errors['password'] = '* passwords must be at least 8 characters and contain an uppercase letter and a number';
            }
            if (Object.keys(errors).length > 0) {
              return Promise.resolve(errors)
            }
            return makeRequest('account.password.reset',
            {
              resetToken: token || '',
              newPassword: state['password'].value?state['password'].value.toString(): '',
            }).then((response) => {
              if (response.result === 'success'){ 
                setSent(true);
                return undefined;
              }
              return { 'submit': 'Your password could not be created'}
            });
          }
          return Promise.resolve(undefined);
        }}
        template={[
          {
            type: 'section',
            title: 'Create New Password',
            items: [],
          },
          {
            type: 'section',
            items: [
              {
                title: 'Password',
                id: 'password',
                type: 'password',
              }
            ],
          },
          {
            type: 'section',
            items: [
              {
                type: 'row',
                display: {
                  style: {
                    marginTop: 10,
                  },
                  align: 'centre',
                },
                items: [
                  {
                    id: 'submit',
                    type: 'button',
                    title: 'Create Password',
                    value: 'reset',
                    onClick: 'reset',
                  }
                ]
              },
            ],
          }
        ]} />}
        </div>
      </Standard.StandardContent>
    </Block.Block>
  </HeaderPage>;
}