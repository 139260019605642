import { Control as ControlProps, OptionControl, ControlValue, ControlType } from '../../Interface';
import { StandardControlProps } from '../Base';

import { Text } from './Text';
import { Button } from './Button';
import { Label } from './Label';
import { Link } from './Link';

import { Image } from './Image';
import { DateControl } from './Date';
import { Checkbox } from './Checkbox';
import { Dropdown } from './Dropdown';
import { SearchableDropdown } from './SearchableDropdown';
import { TimeSpan } from './TimeSpan';
import { ItemSet } from './ItemSet';

const typedControlGenerators: {
  [key in ControlProps['type']]: (props: StandardControlProps & { type: key }) => JSX.Element
} = {
  text: Text,
  password: Text,
  textarea: Text,
  button: Button,
  checkbox: Checkbox,
  img: Image,
  label: Label,
  link: Link,
  dropdown: Dropdown,
  searchableDropdown: SearchableDropdown,
  timespan: TimeSpan,
  date: DateControl,
  datetime: DateControl,
  time: DateControl,
  itemSet: ItemSet,
}

export const controlGenerators: {
  [key in ControlProps['type']]: (props: StandardControlProps) => JSX.Element
} = typedControlGenerators as any;

export { Checkbox };