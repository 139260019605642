import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

export interface Props {
  content?: JSX.Element[],
  fullWidth?: boolean,
  colour?: string,
};

export interface State {

}

export class Footer extends Component<Props, State> {

  render() {
    const count = (this.props.content || []).length;
    const width = `${100/(count === 0?1: count)}%`;
    return <Row className="page-footer-nav" style={{backgroundColor: this.props.colour}}>
      <Col md={this.props.fullWidth? '12':'8'} style={{ display: 'flex', flexDirection: 'row' }}>
        {(this.props.content || []).map((item, i) => <Col className="menu block" style={{width, paddingLeft: i===0?0:10, paddingRight: i===count-1?0:10, alignItems: 'center', flexDirection: 'column', display: 'flex',  }} key={`col-${i}`}>
          {item}
        </Col>)}
      </Col>
    </Row>
  }
}