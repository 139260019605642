import React, { Component } from 'react';
import { HeaderPageTemplate, Props as HeaderPageTemplateProps } from './HeaderPageTemplate';
import { BaseComponent, BaseState, BaseProps } from '../Component';

export interface Props extends BaseProps {
  children?: React.ReactNode,
  header?: HeaderPageTemplateProps['header'],
  footer?: HeaderPageTemplateProps['footer'],
  pageProps?: HeaderPageTemplateProps['pageProps'],
  contentProps?: HeaderPageTemplateProps['contentProps'],
  alignContent?: HeaderPageTemplateProps['alignContent'],
};

export function HeaderPage (props: Props) {
  return <HeaderPageTemplate contentProps={props.contentProps} header={props.header} footer={props.footer} pageProps={props.pageProps} alignContent={props.alignContent}>
    {props.children}
  </HeaderPageTemplate>
}