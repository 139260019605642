import React, { Component, useState } from 'react';
import { redirect, Link, useLocation, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { ChevronUp, ChevronDown, ChevronExpand, FileEarmarkArrowDownFill, ClipboardDataFill, PersonFill, VolumeUpFill, PlayBtnFill, ArrowLeftShort } from 'react-bootstrap-icons';

import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage,  Props as HeaderProps } from '../../components/page/HeaderPage';

const isIndividualIcon = (data: any): data is {
  full: JSX.Element,
  remote: JSX.Element,
  byo: JSX.Element,
} => typeof data === 'object' && data.full !== undefined && data.remote !== undefined && data.byo !== undefined;

export interface Props {
  optionSelected: (option: 'full' | 'remote' | 'byo') => void,
  stateChange: (action: string) => void,
  title: JSX.Element,
  buttonLabel: string | {
    full: string,
    remote: string,
    byo: string,
  } | undefined,
  icon: JSX.Element | {
    full: JSX.Element,
    remote: JSX.Element,
    byo: JSX.Element,
  } | undefined,
  includeBYO?: boolean,
}

export const DeliveryMode = (props: Props) => {

  const [mode, setMode] = useState<'full' | 'remote' | 'byo' | 'none'>('none');

  const options = {
    'full': <Col className='medium-fixed-width' style={{flexGrow: 1}}>
      <div style={{backgroundColor: '#021625cc', flexGrow: 0, border: '2px solid black', borderRadius: 5, display: 'flex', flexDirection: 'column', padding: 10  }}>
      <select className={`grid-input medium-font-size`} style={{color: 'white' , padding: 10, fontWeight: 800, textShadow: '2px 2px #000000', border: 'unset', height: 'unset'}} value={'full'} onChange={(event) => {
        const value = event.target.value;
        setMode(value as any);
      }}>
        <option value='full'>FULLY DELIVERED</option>
        <option value='remote'>REMOTE DELIVERY – EQUIPMENT PROVIDED</option>
        {props.includeBYO === true?<option value='byo'>REMOTE DELIVERY – BYO EQUIPMENT</option>: undefined}
      </select>
        <div style={{height: 10}}></div>
        <div style={{color: 'white', fontWeight: 400, fontSize: 16, padding: 10 }}>
        Kick back and relax, we've got this. Our team travel to your venue with all the gear needed to deliver the installation. Within the fee we provide all necessary staffing and technical equipment required.
        </div>
        <div style={{ display: 'table', flexGrow: 1, color: 'white'  }}>
          <div style={{ display: 'table-row', flexDirection: 'row' }}>
            <div className='presenter-grid-cell' style={{  display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5 }}><div className='presenter-grid-cell-content'>Generally included in the Fee</div></div>
            <div className='presenter-grid-cell' style={{  display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5 }}><div className='presenter-grid-cell-content'>General costs in addition to the Fee</div></div>
          </div>
          <div style={{ display: 'table-row', flexDirection: 'row' }}>
            <div style={{  display: 'table-cell' }}>
              <ul>
                <li>All technical equipment required to stage the installation</li>
                <li>Software & creative content licenses</li>
                <li>Wages and on costs for the Installation Facilitators</li>
                <li>Per diems for our team (if required)</li>
                <li>A comprehensive marketing kit</li>
              </ul>
            </div>
            <div style={{  display: 'table-cell' }}>
              <ul>
                <li>Flights for our team (if required)</li>
                <li>Accommodation for our team (if required)</li>
                <li>Freight and/or shipping costs</li>
                <li>Provision of physical and technical elements as outlined in the Technical Specification</li>
                <li>FOH services for the duration of the season</li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{padding: 5, display: 'flex',  flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Button 
            variant="audioplay-primary"
            style={{ minWidth: 120, minHeight: 60, borderRadius: 30, fontWeight: 700, fontSize: 14 }}
            onClick={() => {
              props.optionSelected('full');
            }}
          >
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{props.icon === undefined? undefined: <div style={{padding: 0}}>{isIndividualIcon(props.icon)? props.icon.full: props.icon} </div>}<div style={{flexGrow:1}}>{props.buttonLabel === undefined? 'SELECT': typeof props.buttonLabel === 'string'? props.buttonLabel: props.buttonLabel.full}</div></div> 
          </Button>
        </div>
      </div>
    </Col>,
    remote: <Col className='medium-fixed-width' style={{flexGrow: 1}}>
      <div style={{backgroundColor: '#021625cc', flexGrow: 0, border: '2px solid black', borderRadius: 5, display: 'flex', flexDirection: 'column', padding: 10  }}>
      <select className={`grid-input medium-font-size`} style={{color: 'white' , padding: 10, fontWeight: 800, textShadow: '2px 2px #000000', border: 'unset', height: 'unset'}} value={'remote'} onChange={(event) => {
        const value = event.target.value;
        setMode(value as any);
      }}>
        <option value='full'>FULLY DELIVERED</option>
        <option value='remote'>REMOTE DELIVERY – EQUIPMENT PROVIDED</option>
        {props.includeBYO === true?<option value='byo'>REMOTE DELIVERY – BYO EQUIPMENT</option>: undefined}
      </select>
        <div style={{height: 10}}></div>
        <div style={{color: 'white', fontWeight: 400, fontSize: 16, padding: 10 }}>
        Our Remote Delivery option aims to reduce the cost for presenters by utilising the skills you already have within your team. We'll train your team to be able to facilitate the installation. The software we've built is super simple so with a small amount of training your team will be able to comfortably deliver the experience. <br/><br/>
We also provide a full kit of all the technical equipment you'll need. We will ship it to you ahead of time, allowing your team to familiarise themselves with the setup.<br/><br/>
Our remote delivery model aims to reduce the carbon footprint of the installation and reduce the cost associated with staff travel.
        </div>
        <div style={{ display: 'table', flexGrow: 1, color: 'white'  }}>
          <div style={{ display: 'table-row', flexDirection: 'row' }}>
            <div className='presenter-grid-cell' style={{  display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5 }}><div className='presenter-grid-cell-content'>Generally included in the Fee</div></div>
            <div className='presenter-grid-cell' style={{  display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5 }}><div className='presenter-grid-cell-content'>General costs in addition to the Fee</div></div>
          </div>
          <div style={{ display: 'table-row', flexDirection: 'row' }}>
            <div style={{  display: 'table-cell' }}>
              <ul>
                <li>All technical equipment required to stage the installation</li>
                <li>Software & creative content licenses</li>
                <li>A comprehensive marketing kit</li>
                <li>A complete training program for your team</li>
                <li>On-call access to technical support throughout the season</li>
              </ul>
            </div>
            <div style={{  display: 'table-cell' }}>
              <ul>
                <li>Freight and/or shipping costs</li>
                <li>Provision of physical and technical elements as outlined in the Technical Specification</li>
                <li>FOH services for the duration of the season</li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{padding: 5, display: 'flex',  flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Button 
            variant="audioplay-primary"
            style={{ minWidth: 120, minHeight: 60, borderRadius: 30, fontWeight: 700, fontSize: 14 }}
            onClick={() => {
              props.optionSelected('remote');
            }}
          >
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{props.icon === undefined? undefined: <div style={{padding: 0}}>{isIndividualIcon(props.icon)? props.icon.remote: props.icon} </div>}<div style={{flexGrow:1}}>{props.buttonLabel === undefined? 'SELECT': typeof props.buttonLabel === 'string'? props.buttonLabel: props.buttonLabel.remote}</div></div> 
          </Button>
        </div>
      </div>
    </Col>,
    byo: <Col className='medium-fixed-width' style={{flexGrow: 1}}>
      <div style={{backgroundColor: '#021625cc', flexGrow: 0, border: '2px solid black', borderRadius: 5, display: 'flex', flexDirection: 'column', padding: 10  }}>
      <select className={`grid-input medium-font-size`} style={{color: 'white' , padding: 10, fontWeight: 800, textShadow: '2px 2px #000000', border: 'unset', height: 'unset'}} value={'byo'} onChange={(event) => {
        const value = event.target.value;
        setMode(value as any);
      }}>
        <option value='full'>FULLY DELIVERED</option>
        <option value='remote'>REMOTE DELIVERY – EQUIPMENT PROVIDED</option>
        {props.includeBYO === true?<option value='byo'>REMOTE DELIVERY – BYO EQUIPMENT</option>: undefined}
      </select>
        <div style={{height: 10}}></div>
        <div style={{color: 'white', fontWeight: 400, fontSize: 16, padding: 10 }}>
        Our Remote Delivery - BYO option further reduces the costs for presenters by utilising the skills of your team AND the resources/equipment already available in your community.<br/><br/>
We'll train your team to be able to facilitate the installation. The software we've built is super simple so with a small amount of training your team will be able to comfortably deliver the experience. <br/><br/>
You’ll utilise equipment that you already have in your community to deliver the experience. We’ll step you through how to download our software and setup the installation. You can find a simple guide to the equipment that might be required <a style={{ color: '#0bd9fe', textDecorationLine: 'underline'  }} onClick={() => { props.stateChange('calc')}}>here</a><br/><br/>
This presentation model further reduces the carbon footprint of the installation and is the most cost effective.
        </div>
        <div style={{ display: 'table', flexGrow: 1, color: 'white'  }}>
          <div style={{ display: 'table-row', flexDirection: 'row' }}>
            <div className='presenter-grid-cell' style={{  display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5 }}><div className='presenter-grid-cell-content'>Generally included in the Fee</div></div>
            <div className='presenter-grid-cell' style={{  display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5 }}><div className='presenter-grid-cell-content'>General costs in addition to the Fee</div></div>
          </div>
          <div style={{ display: 'table-row', flexDirection: 'row' }}>
            <div style={{  display: 'table-cell' }}>
              <ul>
                <li>Software & creative content licenses</li>
                <li>A comprehensive marketing kit</li>
                <li>A complete training program for your team</li>
                <li>On-call access to technical support throughout the season</li>
              </ul>
            </div>
            <div style={{  display: 'table-cell' }}>
              <ul>
                <li>All technical & physical equipment required to stage the installation</li>
                <li>Provision of physical and technical elements as outlined in the Technical Specification</li>
                <li>FOH services for the duration of the season</li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{padding: 5, display: 'flex',  flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <Button 
            variant="audioplay-primary"
            style={{ minWidth: 120, minHeight: 60, borderRadius: 30, fontWeight: 700, fontSize: 14 }}
            onClick={() => {
              props.optionSelected('byo');
            }}
          >
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{props.icon === undefined? undefined: <div style={{padding: 0}}>{isIndividualIcon(props.icon)? props.icon.byo: props.icon} </div>}<div style={{flexGrow:1}}>{props.buttonLabel === undefined? 'SELECT': typeof props.buttonLabel === 'string'? props.buttonLabel: props.buttonLabel.byo}</div></div> 
          </Button>
        </div>
      </div>
    </Col>,
    none: <Col className='medium-fixed-width' style={{flexGrow: 1}}>
      <div style={{backgroundColor: '#021625cc', flexGrow: 0, border: '2px solid black', borderRadius: 5, display: 'flex', flexDirection: 'column', padding: 10  }}>
      <select className={`grid-input medium-font-size`} style={{color: 'white' , padding: 10, fontWeight: 800, textShadow: '2px 2px #000000', border: 'unset', height: 'unset'}} value={'none'} onChange={(event) => {
        const value = event.target.value;
        setMode(value as any);
      }}>
        <option selected={true} value='none' disabled={true}>Delivery Mode</option>
        <option value='full'>FULLY DELIVERED</option>
        <option value='remote'>REMOTE DELIVERY – EQUIPMENT PROVIDED</option>
        {props.includeBYO === true?<option value='byo'>REMOTE DELIVERY – BYO EQUIPMENT</option>: undefined}
      </select>
        <div style={{height: 10}}></div>
      </div>
    </Col>
  }
  return <div style={{ flex: 1, flexGrow: 1, zIndex: 1 }} >
    <Col style={{height: '100%'}}>
      <div className='small-padding presenter-pack'>
        <Button 
          variant="audioplay-primary"
          style={{ width: 120, height: 50, borderRadius: 30, fontWeight: 700, fontSize: 14 }}
          onClick={() => {
            props.stateChange('');
          }}
        >
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div style={{padding: 0}}><ArrowLeftShort size={20}></ArrowLeftShort></div><div style={{flexGrow:1}}>BACK</div></div> 
        </Button>
      </div>
      <Row className='medium-horizontal-scroll small-padding presenter-pack'>
        <Col style={{minWidth: 300, maxWidth: 350, display: 'flex', flexDirection: 'column'}}>
          {props.title}
          <div style={{height: 30}}></div>
          <div style={{color: 'white', fontWeight: 800, fontSize: 32, textShadow: '2px 2px #000000'}}>DELIVERY MODES</div>
          <div style={{height: 10}}></div>
          <div style={{color: 'white', fontWeight: 400, fontSize: 18, background: '#021625cc', padding: 20, borderRadius: 10}}>
          Audioplays Event Installations are immersive audio experiences which invite audiences to step into the story and physically play out the action as one of the characters.
   <br/><br/>
  Our installations are perfect for festivals and school holiday programs, offering short, high rotation experiences for groups of 4-16 players. Sessions can run to a structured schedule throughout the day or on a drop-in basis.
          </div>
          <div style={{flexGrow: 1}}></div>
        </Col>
        <Col style={{flexGrow: 0}}>
          <div style={{width: 30, height: 30 }}/>
        </Col>
        {options[mode]}
      </Row>
  </Col>
</div>;
}