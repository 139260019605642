import React, { Component, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataCache } from '@audioplay/client';
import { connect } from 'react-redux';

import { state } from '../state/reducer/user';

import { update } from '../state/action/cache';
import { AppRouterProps } from './router/common';

export interface Props {
  user: state,
  update: typeof update,
  cache: any,
  router: (props: AppRouterProps) => JSX.Element,
};

export const BaseApp = (props: Props) => {
  const [setup, setSetup] = useState(false);
  if (setup === false) {
    setSetup(true);
    DataCache.setEndpoint(process.env.API_URL || 'https://ugaa5f4r18.execute-api.ap-southeast-2.amazonaws.com/dev/api');
    DataCache.setAccess(props.user.accessToken);
    DataCache.setCachePersistance({
      save: (value) => {
        props.update(value);
        return Promise.resolve();
      },
      clear: () => {
        props.update(undefined);
        return Promise.resolve();
      },
      load: () => {
        return Promise.resolve((typeof props.cache === 'object'? props.cache: undefined) || {});
      }
    }, props.cache);
  }
  return props.router({
    user: props.user,
    loggedIn: props.user.accessToken !== undefined,
  });
}

export const App = connect((state: any) => ({ user: state.user, cache: state.cache.cache }), { update })(BaseApp);