import { StandardControlProps, useControlState, wrapFormControl, labelHelper } from '../Base';
import { TimeSpan as TS } from '../TimeSpan';

export function TimeSpan(props: StandardControlProps & { type: 'timespan' }) {
  const { value, change, outputProps } = useControlState(props);
  return wrapFormControl(props, outputProps, undefined, undefined, <TS
    {...props}
    onChange={(value) => {
      change(value);
    }}
    selected={typeof value === 'number'? value: null}
  />);
}