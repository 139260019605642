import React, { useState } from 'react';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';
import { StandardContent, StandardContentBlock } from '../../components/content/Standard';
import { Col, Row, Container, Table } from 'react-bootstrap';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/pagination'
import 'swiper/scss/navigation'

import { IoDocumentTextOutline, IoLogoYoutube, IoAlbumsOutline, IoChevronDownOutline, IoChevronUpOutline, IoPlay } from "react-icons/io5";

export interface Props extends HeaderProps {

};

const imageDisplay = (images: string[]) => {
  return <Swiper
    spaceBetween={10}
    slidesPerView={'auto'}
    navigation={true}
    pagination={{
      clickable: true,
      bulletActiveClass:'audioplay-pagination-bullet-active-primary'
    }}
    centeredSlides={true}
    modules={[Pagination,Navigation]}
    style={{ minHeight:200}}
  >
    {images.map(img => <SwiperSlide style={{ height: '300px', maxWidth: '400px', maxHeight: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><img src={img} alt="Training" style={{ objectFit: 'contain', maxHeight: '100%', maxWidth: '100%' }}/></SwiperSlide>)}
  </Swiper>
}

const screenDisplay = (screens: { image: string, body: string | JSX.Element }[]) => {
  return <Swiper
    spaceBetween={10}
    slidesPerView={'auto'}
    navigation={true}
    pagination={{
      clickable: true,
      bulletActiveClass:'audioplay-pagination-bullet-active-primary'
    }}
    centeredSlides={true}
    modules={[Pagination,Navigation]}
    style={{ minHeight:200}}
  >
    {screens.map(({ image, body }) => <SwiperSlide style={{ maxWidth: '500px', width: '100%', padding: 10 }}><div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.15) 1.5px 1.5px 6.0px' }}><img src={image} alt="Training" style={{ height: '100%', maxHeight: '300px', paddingRight: 5 }}/><div style={{margin: 10}}>{body}</div></div></SwiperSlide>)}
  </Swiper>
}

const doubleScreenDisplay = (screens: { image: string, body: string | JSX.Element, body2: string | JSX.Element, image2: string | JSX.Element }[]) => {
  return <Swiper
    spaceBetween={10}
    slidesPerView={'auto'}
    navigation={true}
    pagination={{
      clickable: true,
      bulletActiveClass:'audioplay-pagination-bullet-active-primary'
    }}
    centeredSlides={true}
    modules={[Pagination,Navigation]}
    style={{ minHeight:200}}
  >
    {screens.map(({ image, body, body2, image2 }) => <SwiperSlide style={{ width: '90%', maxWidth: '400px', padding: 10 }}><div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.15) 1.5px 1.5px 6.0px' }}><img src={image} alt="Training" style={{  height: '300px', paddingRight: 5 }}/><div style={{margin: 10}}>{body}</div></div></SwiperSlide>)}
  </Swiper>
}

const videoHolder = (items: JSX.Element[]) => {
  return <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>{items}</Row>
}

const itemDisplay = (items: JSX.Element[]) => <Swiper
  spaceBetween={10}
  slidesPerView={'auto'}
  navigation={true}
  pagination={{
    clickable: true,
    bulletActiveClass:'audioplay-pagination-bullet-active-primary'
  }}
  centeredSlides={true}
  modules={[Pagination,Navigation]}
  style={{ minHeight:200}}
>
  {items.map((item) => <SwiperSlide>{item}</SwiperSlide>)}
</Swiper>



export const RemoteDelivery = (props: Props) => {
  const [video, setVideo] = useState<string | undefined>(undefined);
  const [active, setActive] = useState<string | undefined>(undefined);

  const videoThumbnail = (thumbnail: string, title: string, video: string, style?: React.ComponentProps<'div'>) => {
    return <div style={{ margin: 5, width: 150, height: 150, position: 'relative', ...style}} onClick={() => { setVideo(video)}}>
      <img src={thumbnail} alt={title} style={{ width: '100%', height: '100%', objectFit: 'cover' }}></img>
      <div style={{ margin: 'auto', position: 'absolute', top: 0, left: 0, backgroundColor: '#0004', display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <IoPlay style={{width: '30px', height: '30px', color: '#fff'}}/>
      </div>
    </div>
  }

  const docs = {
    'Remote Delivery Manual': 'https://drive.google.com/file/d/1r4XmiPyzy84-7lgTvZ-O7Ded462d4WP1/view?usp=sharing',
    'Remote Delivery Setup Checklist': 'https://drive.google.com/file/d/1r7W8uPHnQAVa7iE7kec0oFafnM-k9OAu/view?usp=sharing',
    'Installation Tech Spec': 'https://drive.google.com/file/d/1hHGkVSNFpxD3QXrkYEoGnR4V1h9_bhYR/view?usp=sharing',
    'Remote Delivery Sample Risk Assessment': 'https://drive.google.com/file/d/1hHr26RF62W8_PeYpZC9AYqSz3g6i7ZJu/view?usp=sharing',
  };
  let videoNode = undefined;
  if (video) {
    videoNode = <div style={{backgroundColor: '#1116', position: 'absolute', top: 0, left: 0, height: '100vh', width: '100vw', zIndex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} onClick={() => {setVideo(undefined)}}>
      <div style={{ backgroundColor: 'white', padding: 10, borderRadius: 5}}>
      <iframe
              style={{ width: '60vw', height: '40vw', minWidth: '300px', maxWidth: '100vw' }}
              src={video}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="video"
            />
      </div>
    </div>
  }

  return <HeaderPage contentProps={{'style':{backgroundColor: '#f2f2f2'}}}>
    {videoNode}
    <StandardContent style={{backgroundColor: '#f2f2f2'}}>
      <StandardContentBlock colour="white">
        <h1>AUDIOPLAY: CREATIVE PLAY INSTALLATION TRAINING</h1>
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'preEventPlanning'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('preEventPlanning'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100)}}>PRE-EVENT PLANNING<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>PRE-EVENT PLANNING<IoChevronUpOutline/></h2>
        The Turners can be delivered with up to 4x Play Spaces running simultaneously, with 4 players in each play space. The physical layout will be tailored to suit your specific space, however factors to keep in mind:
        <br/><br/>
        <ul>
<li>Players need space to move, so the play space should be a dedicated space (not otherwise used as a
thoroughfare) and free of obstacles.</li>
<li>Regardless of the configuration of the Play Spaces it’s important that the facilitator has a clear line of sight to all
spaces so as to monitor the experience and respond quickly to any issues.</li>
<li>All Play Spaces should be in the same room as the WiFi Modem to ensure the strength of the signal to all devices.</li>
</ul> 
You can download the Audioplay App at any time and try one of the play at home experiences. This will help to give you a sense of how Audioplay works and what to expect when facilitating the installation.

<Container>
<Row><Col md={12}><div style={{ textAlign: 'center', fontWeight: 'bold' }}>Download Links</div></Col></Row>
<Row>
  <Col md={6} sm={12}><a href='https://play.google.com/store/apps/details?id=au.com.audioplay&hl=en_US'><div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}><div><img src={'/images/android-download-link.png'} alt="Training" style={{ height: '200px' }}/></div><div>https://tinyurl.com/AudioplayAndroid</div></div></a></Col>
  <Col md={6} sm={12}><a href='https://apps.apple.com/au/app/audioplay/id1361477655'><div style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}><div><img src={'/images/ios-download-link.png'} alt="Training" style={{ height: '200px' }}/></div><div>https://tinyurl.com/AudioplayiOS</div></div></a></Col>
</Row>
</Container>
</>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
      { active !== 'playSpaces'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('playSpaces'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>PLAY SPACES<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>PLAY SPACES<IoChevronUpOutline/></h2>
        {videoHolder([
          videoThumbnail('/images/training/video-1.png', 'Unpacking Your Kit', "https://www.youtube.com/embed/U1hRw7WZ7wY"),
          videoThumbnail('/images/training/video-2.png', 'Preparing the Installation Space', "https://www.youtube.com/embed/vvpG3HAFYSI"),
          videoThumbnail('/images/training/video-3.png', 'Dressing the Installation Space', "https://www.youtube.com/embed/LhadZJm7ztQ"),
          videoThumbnail('/images/training/video-4.png', 'Preparing Your Tech Area', "https://www.youtube.com/embed/SVmFrmXhhsE")
        ])}
        <br/>
        Kids often loose themselves in the story so it’s useful to use visual markers to clearly delineate the perimeter of the space they are to play in. This can be achieved using specific flooring such as artificial turf, rugs or puzzle mats or even simply marking the space with adhesive tape, to visually differentiate the play space from the surrounding flooring. This is particularly important in a shared event space.
        <br/><br/>
If you are presenting more than one play space it can be useful to create a visual barrier between the Play Spaces. This could be done with drapes or even putting pot plants between the spaces. This visual barrier helps kids overcome the sense that they are being watched by the other participants (which sometimes stifles their play) and also helps them know which set of props are to be used for their specific Play Space.
</>}
      </StandardContentBlock>
      
      <StandardContentBlock colour="white">
      { active !== 'stagingAndProps'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('stagingAndProps'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>STAGING & PROPS<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>STAGING & PROPS<IoChevronUpOutline/></h2>
        {videoHolder([
          videoThumbnail('/images/training/video-5.png', 'Setting Up The Props', "https://www.youtube.com/embed/pWFkUOITwqU"),
          videoThumbnail('/images/training/video-6.png', 'Physical Space Recap', "https://www.youtube.com/embed/CGANhC8d0Lc"),
        ])}
        All the props required for the play space should be pre-set in the space, making sure they are all visible to the players.
        <br/><br/>
Lay out the following items on the floor in the centre of each play space. Make sure they are separated so that each item is clearly visible:
<br/><br/>
        <ul>
<li>1x Large blanket (double bed size or larger) 2x Flat sheets (double or queen size)</li>
<li>2x Chairs</li>
<li>1x Prop Phone (place in the corner of the play space)</li>
</ul>
<div style={{float: 'right', display: 'flex', flexDirection: 'column', alignItems: 'center' }}><img src={'/images/training/props-phone.png'} alt="Training" style={{ height: '200px' }}/>PROP PHONE, 'ANTIDOTE' BAG AND SALT</div>
<span style={{ fontWeight: 'bold' }}>Prop Phone</span> - In the base of the prop phone there is a secret compartment. An “Antidote” zip lock bag with a small amount of salt (1/4 teaspoon) should be placed inside the secret compartment. The phone should then be placed in the corner of the play space.<br/><br/>


      
      Players are invited to sprinkle the ‘antidote’ during the experience, when players take up this invitation you will have a small amount of salt left on the floor which can be vacuumed up at the end of the day.
      </>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
      { active !== 'wifiNetwork'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('wifiNetwork'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>WIFI NETWORK<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>WIFI NETWORK<IoChevronUpOutline/></h2>
        {videoHolder([
          videoThumbnail('/images/training/video-7.png', 'Turning On The WIFI', "https://www.youtube.com/embed/NLV4GDHvQsY"),
        ])}
        To start, unpack your Wi-Fi Router and plug it into a GPO power outlet. The router does not need to be connected to the internet. To confirm it’s setup correctly make sure the Power light and the WiFi network lights are on.
        {imageDisplay(['/images/training/router-1.png','/images/training/router-2.png','/images/training/router-3.png'])}
        </>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'deviceSetup'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('deviceSetup'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>MOBILE DEVICE SETUP<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>MOBILE DEVICE SETUP<IoChevronUpOutline/></h2>
        {videoHolder([
          videoThumbnail('/images/training/video-8.png', 'Charging The Devices', "https://www.youtube.com/embed/_O1CDBG_LzQ"),
          videoThumbnail('/images/training/video-9.png', 'Turning On The Devices', "https://www.youtube.com/embed/CR57pGP4haQ"),
        ])}

        Before you start ensure all mobile devices are fully charged, ready to last the duration of the event. To setup the devices:
1. Turn on all devices by holding down the middle button on the side of the device.
2. Select the Audioplay App icon on the home screen.

3. All devices are pre-programmed to open to one of the following three states. Check
  your equipment list to confirm how many of each device you should have. Player devices come in coloured cases and the Control Device have a sparkly case.

        <Container>
          <Row>
            <Col md={4} sm={12} xs={12}>
              <h2>CONTROL DEVICE</h2>
              <h3>Sparkly Case</h3>
              <img src='/images/training/software-control-1.png' alt='Control Device' style={{ width: '100px'}}/>
              <div>
            There should only ever be one Control Device per event.
Your Control Device triggers the experience to start on all the player devices. Once the devices are all switched on you will see the number of Player Devices and Spare Devices for each colour group that are connected to the network.
Ensure that there are 4 Player
Devices for each colour group before commencing the session.
</div>
            </Col>
            <Col md={4} sm={12} xs={12}>
            <h2>PLAYER DEVICES</h2>
              <h3>Coloured Case</h3>
              <img src='/images/training/software-player-1.png' alt='Control Device' style={{width: '100px'}}/>
              <div>
              Each Player Device will be pre-set to one of the four characters
1. Abbie, 2. Leo,
3. Rattle or 4. Boa.
Each group of four players will be allocated a colour group. The colour is indicated by the background colour behind the character and by the colour of the phone case.
</div>
            </Col>
            <Col md={4} sm={12} xs={12}>

              <h2>SPARE DEVICES</h2>
              <h3>Clear Case</h3>
              <img src='/images/training/software-spare-1.png' alt='Control Device' style={{width: '100px'}}/>
              <div>
            Whilst you only need 4 devices for each play space you are running, we have included some Spare Devices, in case there are any issues during the session.
It is best to have all phones on and running the Audioplay App throughout the event, so they are ready to go if you do need to use one of the spares.
</div>
            </Col>
          </Row>
        </Container>
        </>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'appPinning'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('appPinning'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>PIN THE APP<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>PIN THE APP<IoChevronUpOutline/></h2>
        {videoHolder([
          videoThumbnail('/images/training/video-10.png', 'Pinning The App', "https://www.youtube.com/embed/GBgbj3Nulck"),
          videoThumbnail('/images/training/video-9.png', 'Turning On The Devices', "https://www.youtube.com/embed/CR57pGP4haQ"),
        ])}
        To avoid members of the public closing the app during play it is important that the App is pinned to the main screen.
        {screenDisplay([
          {image: '/images/training/app-pinning-1.gif', body: 'To pin the app, first open the Audioplay App then return to the home screen and click the bottom left button (three vertical lines).'},
          {image: '/images/training/app-pinning-2.gif', body: 'Scroll across to the Audioplay App and hold down the Audioplay icon at the top.'},
          {image: '/images/training/app-pinning-3.gif', body: 'A menu will appear, from which you should select “Pin this app”.'},
          {image: '/images/training/app-pinning-4.gif', body: 'You will see the following message when the App is successfully pinned'},
        ])}
        <span style={{ fontWeight: 'bold' }}>The Audioplay App will need to be pinned at the beginning of each day.</span><br/>
To unpin the app press and hold the bottom left and bottom right buttons as shown in the image to the left.<br/><br/>

        Once all the devices have been switched on and pinned, lay them out on a table, place each one inside a character pouch and connect a pair of headphones to each ready for your audience to arrive. The pouches allow kids to carry the device whilst keeping their hands free. Put the spare devices to one side or carry one in your pocket so it’s at hand if needed.
        </>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'wifiConnection'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('wifiConnection'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>WIFI CONNECTION<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>WIFI CONNECTION<IoChevronUpOutline/></h2>
        All the devices will be pre-programed to automatically connect to the Audioplay Wi-Fi network. If you do have connectivity issues, here is how you check and reset the Wi-Fi.
        {screenDisplay([
          {image: '/images/training/wifi-1.gif', body: 'Swipe down from the top and then press and hold the Wi-Fi symbol in the top left corner.'},
          {image: '/images/training/wifi-2.gif', body: 'You will then see a list of all available Wi-Fi networks. If the Audioplay2 network isn’t already selected go ahead and select it from the list.'},
          {image: '/images/training/wifi-3.gif', body: 'To exit this screen click on the circle in the centre at the bottom of the screen.'},
        ])}
        If you are unable to connect to the Audioplay network for some reason, the login details are noted here.<br/><br/>
        <div style={{ backgroundColor: 'white', boxShadow: 'rgba(0, 0, 0, 0.15) 1.5px 1.5px 6.0px', alignItems: 'center', display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', width: 'fit-content', padding: '20px' }}>
          <span style={{fontWeight: 'bold'}}>WIFI NETWORK</span><br/>
          Network: Audioplay2<br/>
          Password: theturners2017
        </div>
        </>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'troubleshooting'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('troubleshooting'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>TECHNICAL TROUBLESHOOTING<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>TECHNICAL TROUBLESHOOTING<IoChevronUpOutline/></h2>
        <div style={{backgroundColor: 'white'}}>
        <Table striped bordered hover responsive style={{ marginBottom: 0}}>
        <thead>
          <tr>
            <th>Issue</th>
            <th>Solution</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Four player devices are switched on but the Control Device isn’t reading 4 for that colour group?</td>
            <td>Check the WI-FI connection is the same for all devices in that colour group. Adjust the Wi-Fi settings if required (See instructions above).</td>
          </tr>
          <tr>
            <td>I have two control devices, which one do I use?</td>
            <td>Switch off one of the Control Devices, the system is designed to run with only one Control Device and the system will become unstable if you are operating two Control Devices simultaneously.</td>
          </tr>
          </tbody>
        </Table>
        </div>
        </>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'preShowTest'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('preShowTest'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>PRE-SHOW TESTS<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>PRE-SHOW TESTS<IoChevronUpOutline/></h2>
        Prior to families arriving it’s good to test the devices to check they are working fine and the volume is set to an appropriate level. We advise that you <span style={{fontWeight: 'bold'}}>run these test 30-45mins before your scheduled start time</span>, so you have time to resolve issues if they do arise.<br/><br/>
Throughout the pre-show tests and whilst operating the installation the Facilitator should carry with them their own pair of ear bud headphones. Plug your personal ear-bud headphones into the device for testing the volume levels and whilst listening/overserving the experience so as to reduce the cleaning requirements.<br/><br/>
<p style={{ fontSize: '20px'}}>VOLUME TESTS</p>
        {videoHolder([
          videoThumbnail('/images/training/video-11.png', 'Setting The Device Volume', "https://www.youtube.com/embed/S4sOiIG_8Ps"),
        ])}
          Its best to have the speaker volume set to silent and the headphone volume set to the maximum recommended level. All devices will be pre-set to these levels however details on how to check these levels are noted below. The volume can be adjusted using the button on the top right side of the device. Make sure your headphones are plugged in when adjusting the volume, in order to adjust the headphone volume specifically.<br/><br/>
To test the volume use the Control Device to trigger all devices to start the introductory audio sequence. See instructions for operating the app on page 10. Whilst playing the introductory audio, use your headphones to check to the audio levels on each device as follows.<br/><br/>
<span style={{fontWeight: 'bold', color: 'red', textAlign: 'center'}}>WARNING: Kids hearing is more sensitive than adults hearing and may be damaged by excessive volume. Never increase the volume over the maximum levels recommended by the device.</span>
{screenDisplay([
          {image: '/images/training/volume-1.gif', body: 'Push the volume button on the top right of the device and the following menu will appear. Select the three dots at the top of the control bar to expand the menu.'},
          {image: '/images/training/volume-2.gif', body: 'In this main menu you can adjust the volume, making sure your headphones are plugged in whilst adjusting the levels.'},
          {image: '/images/training/volume-3.gif', body: <>Make sure that you keep the volume levels within the recommended range. <span style={{fontWeight: 'bold', color: 'red', textAlign: 'center'}}>The volume icon will turn orange if you exceed the recommended levels.</span></>},
        ])}
        </>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'audience'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('audience'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>AUDIENCE MANAGEMENT<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>AUDIENCE MANAGEMENT<IoChevronUpOutline/></h2>
        As families arrive in the space its useful to keep in mind the following:<br/><br/>
        <span style={{ fontSize: '20px'}}>KIDS & BUTTONS –</span> Kids are generally familiar with phones and love to push ALL the buttons. If you refer to the phones as ‘Devices’ then kids will be less confident with them. We have found that kids are a lot less likely to push all the buttons when we refer to them as ‘Devices’.
        <br/><br/>
        <span style={{ fontSize: '20px'}}>LITTLE KIDS –</span> This experience is not suitable for children under 6 years as they often struggle to keep up with the pace of the audio instructions and they quite often get scared. However, if a 5yr old is playing with a parent/older sibling then it may be OK. Talk it through with the parent and let them decide if their child will be OK with it.
<br/><br/>
<span style={{ fontSize: '20px'}}>ENGLISH LANGUAGE –</span> The experience is very fast paced and does rely on the players having reasonable English language comprehension. If you have concerns about any players English language comprehension talk it through with their parent. You can partner kids up, so that two kids play as the same character using one of the spare devices, so a child with stronger English can help guide a child that may otherwise struggle.
<br/><br/>
<span style={{ fontSize: '20px'}}>MIXED ABILITIES –</span> We welcome kids with diverse abilities and neuro-diversity to play. In instances where physical movement may be challenging or they may struggle to keep up, an older child or carer can be given one of the spare devices to play as the same character so they can support a child through the experience.
<br/><br/>
<span style={{ fontSize: '20px'}}>GETTING KIDS IN THE ZONE –</span> If kids seem hyped up on entering the space it can be useful to get them to take their shoes off, this action provides a momentary breather as they enter the space. It also saves them tramping their shoes all over the blankets/sheets. This strategy should be considered in the context of your COVID-19 response plan.
<br/><br/>
<span style={{ fontSize: '20px'}}>PARENTS PLAYING & OBSERVING –</span> If parents are keen to play they are most welcome and they generally really enjoy it. If parents are keen to watch their kids play, ask them sit back from the edge of the play space to reduce the feeling of the kids being watched.
<br/><br/>
<span style={{ fontSize: '20px'}}>GETTING PLAYERS SETUP</span>
<br/><br/>
As families arrive ask them to get into groups of 4 (2x pairs) – you will need 4 players for the experience to start. If you don’t have enough players invite parents to play to make up the numbers.
<br/><br/>
In assembling your groups consider the following
<br/>
<ul>
<li>Kids have a better time playing with friends or people they know</li>
<li>Where possible have little kids paired with older kids/adults</li>
</ul>
<br/>
If you need a parent or carer to support a player, then offer them one of the spare devices locked to the same character as the player they are supporting. Always keep at least one spare device on hand in case of issues.
</>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'software'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('software'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>OPERATING THE SOFTWARE<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>OPERATING THE SOFTWARE<IoChevronUpOutline/></h2>
        The following outlines the screen sequence you will see on each device, the audio that will be heard and the actions the facilitator will need to take, throughout the experience.
        <br/><br/>
        <div style={{backgroundColor: 'white'}} className="audioplay-table">
        <Table striped bordered hover responsive style={{ marginBottom: 0, width: '750px', margin: 'auto'}}>
        <thead>
          <tr>
            <th>PLAYER DEVICES</th>
            <th>AUDIO</th>
            <th>FACILITATOR ACTION</th>
            <th>CONTROL DEVICE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{padding: '5px'}}><img src={'/images/training/software-player-1.png'} alt="Training" style={{  height: '300px' }}/></td>
            <td style={{fontStyle: 'italic', padding: '10px'}}></td>
            <td style={{color: 'red', padding: '10px'}}>Greet the audience and get them setup with a device and headphones.
As you provide them with a device + headphones tell them the name of their character.
Ensure there are 4 players for each colour group before pressing “Start Introduction”</td>
            <td style={{padding: '5px'}}><img src={'/images/training/software-control-1.png'} alt="Training" style={{  height: '300px' }}/></td>
          </tr>
          <tr>
            <td style={{padding: '5px'}}><img src={'/images/training/software-player-2.png'} alt="Training" style={{  height: '300px' }}/></td>
            <td style={{fontStyle: 'italic', padding: '10px'}}>Have you got your headphones on? Sweet, OK let’s get started.
When you’re playing you need to stay in the play space we’ve setup, sometimes we call it a room. The facilitator is pointing it out now, can you see where the corners are? Great, so stay inside that space.</td>
            <td style={{color: 'red', padding: '10px'}}>Point out permitter of the play space noting the corners which are referred to in the audio instructions</td>
            <td style={{padding: '5px'}}><img src={'/images/training/software-control-2.png'} alt="Training" style={{  height: '300px' }}/></td>
          </tr>
          <tr>
            <td style={{padding: '5px'}}><img src={'/images/training/software-player-3.png'} alt="Training" style={{  height: '300px' }}/></td>
            <td style={{fontStyle: 'italic', padding: '10px'}}>While we’re playing we are going to use some props
There’s a
Blanket
Two Sheets
A couple of chairs and An old style phone
Can you see everything? If there is anything missing, ask your facilitator to point it out.</td>
            <td style={{color: 'red', padding: '10px'}}>Answer any questions players may have about the props.</td>
            <td style={{padding: '5px'}}></td>
          </tr>
          <tr>
            <td style={{padding: '5px'}}><img src={'/images/training/software-player-4.png'} alt="Training" style={{  height: '300px' }}/></td>
            <td style={{fontStyle: 'italic', padding: '10px'}}>Right, we’re almost ready.
Now remember, everyone will hear something different, so if you’re asked to do something different from your partner, it’s totally fine, you’re not doing it wrong.
You can’t break it, so just follow the instructions as best you can... Remember, we’re here to have FUN!</td>
            <td style={{color: 'red', padding: '10px'}}></td>
            <td></td>
          </tr>
          <tr>
            <td style={{padding: '5px'}}><img src={'/images/training/software-player-5.png'} alt="Training" style={{  height: '300px' }}/></td>
            <td style={{fontStyle: 'italic', padding: '10px'}}>Once you start, you need to be able to use your hands, you don’t need to look at the screen. When you hit start, put your device in your pocket.
Lastly, if you have any issues, just put your hand up and the facilitator will come and help you out.
Ok, give your facilitator a thumbs up to let them know you’re ready to start.</td>
            <td style={{color: 'red', padding: '10px'}}>When all players are giving a thumbs up the facilitator presses “Start” to initiate the experience from the Control Device</td>
            <td style={{padding: '5px'}}><img src={'/images/training/software-control-3.png'} alt="Training" style={{  height: '300px' }}/></td>
          </tr>
          <tr>
            <td style={{padding: '5px'}}><img src={'/images/training/software-player-6.png'} alt="Training" style={{  height: '300px' }}/></td>
            <td style={{fontStyle: 'italic', padding: '10px'}}>Experience audio plays through – 17.5 mins.</td>
            <td style={{color: 'red', padding: '10px'}}>Facilitator should observe the play.
You can listen to the audio of any of the four characters by selecting the character image.
Critical Moment:
35sec - ensure the BLANKET is fully laid out by Boa. (Not the sheet!)
Note, pressing ‘Pause’ will pause the audio on all devices.</td>
            <td style={{padding: '5px'}}><img src={'/images/training/software-control-4.png'} alt="Training" style={{  height: '300px' }}/></td>
          </tr>
          <tr>
            <td style={{padding: '5px'}}><img src={'/images/training/software-player-7.png'} alt="Training" style={{  height: '300px' }}/></td>
            <td style={{fontStyle: 'italic', padding: '10px'}}>Nice one! That was great!
OK let’s just grab all the bits and pieces and put them back where they were when you started.
If you enjoyed playing we have more Audioplay stories that you can play at home, anytime you like! Check out the App on your phone to see all the other great episodes we’ve got available.
Thanks for playing! Catch you soon!</td>
            <td style={{color: 'red', padding: '10px'}}>Check all props have been reset to their original position.
Check the antidote in the zip lock bag – top up as required and reposition the zip lock bag at the base of the phone.
The devices will reset back to the beginning ready for the next session.</td>
            <td style={{padding: '5px'}}><img src={'/images/training/software-control-1.png'} alt="Training" style={{  height: '300px' }}/></td>
          </tr>
          </tbody>
        </Table></div>
        </>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'play'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('play'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>PLAY<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>PLAY<IoChevronUpOutline/></h2>
        From the point you hit “Start” on the Control Device, the experience will run for 17.5 minutes. If you are delivering the experience to a fixed schedule the facilitator will need to keep patrons moving through to ensure you stay to time.
        <br/><br/>
Whilst patrons are playing its best to be relatively hands off and let the kids figure out how they are navigating the space however if kids are struggling to find props or understand what they are doing please step in to help. We want them to enjoy the experience.
<br/><br/>
There is one critical point to ensure runs smoothly. 35 seconds in it is important that Boa lays the BLANKET out on the floor (not a sheet). Many subsequent instructions hang off this action being completed correctly, so if the players don’t do this, please step in and lay the blanket out for them.
<br/><br/>
If kids get scared at any point it’s fine for them to stop playing. Kids will often respond well to a short break, taking the headphones off and sitting on the sidelines whilst they overcome the sense of fear before being ready to re-join the experience. If a child leaves the experience in the first half (before the point where the Vipermen and the Turners are working together) it is advisable that the facilitator join the game and play as the absent character. Simply select that character on the Control Device and join in.
</>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'spareDevices'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('spareDevices'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>SPARE DEVICES<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>SPARE DEVICES<IoChevronUpOutline/></h2>
        {imageDisplay(['/images/training/software-spare-1.png', '/images/training/software-spare-2.png'])}
        We have included some spare devices, in case there is an issue with any of the Player Devices. These devices will be connected to the same colour group as the player devices – as indicated by the frame and number colour. However these devices are not set to be a specific character.
        <br/><br/>
In the event there is an issue with a Player Device, grab a Spare Device and simply
<ul>
<li>Select the relevant character</li>
<li>Press the ‘lock button’</li>
<li>Switch the spare device with the problematic Player Device.</li>
</ul>
This will minimise disruption for the player.
<br/><br/>
Spare Devices can also be used to enable two people to play as the same character. This is useful for little kids who may need parental assistance or players with mobility issues needing a carer to support them. Simply select the appropriate character after the introduction is complete and as the experience is beginning.
<br/><br/>
Always keep at least one Spare Device on hand, in case of technical issues through the experience.
</>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'reset'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('reset'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>RESET<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>RESET<IoChevronUpOutline/></h2>
        Upon completion of the experience the facilitator should check that all props have been reset to their original position as well as replenishing the salt in the zip-lock bag if necessary.
        <br/><br/>
The reset process should incorporate any cleaning required as part of your COVID-19 response plan. You should now be ready for the next group.
</>}
      </StandardContentBlock>

      <StandardContentBlock colour="white">
        { active !== 'documents'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('documents'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>DOCUMENTS<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>DOCUMENTS<IoChevronUpOutline/></h2>
        {Object.entries(docs).map(([name, path]) => (<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}><IoDocumentTextOutline></IoDocumentTextOutline> <a href={path} target="_blank" rel="noopener noreferrer" download={name+'.pdf'}>{name}</a></div>))}
      
        </>}
      </StandardContentBlock>
      <StandardContentBlock colour="white">
        { active !== 'videos'? <h2 style={{alignItems: 'center', display: 'flex'}} onClick={(e) => { setActive('videos'); const element = e.target; setTimeout(() => (element as any).scrollIntoView({behavior: 'smooth'}),100) }}>VIDEOS<IoChevronDownOutline/></h2>:<>
        <h2 style={{alignItems: 'center', display: 'flex'}} onClick={() => setActive(undefined)}>VIDEOS<IoChevronUpOutline/></h2>
        <Container>
          <Row>
            <Col md={4} lg={3} sm={6} xs={12} style={{paddingBottom: 15}}><IoAlbumsOutline/><a href={'https://www.youtube.com/playlist?list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip'} target="_blank" rel="noopener noreferrer">Playlist</a></Col>
          </Row>
          <Row>
            {Object.entries({
              'Unpacking your kit': 'https://www.youtube.com/watch?v=U1hRw7WZ7wY&list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip&index=1',
              'Preparing the Installation Space': 'https://www.youtube.com/watch?v=vvpG3HAFYSI&list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip&index=2',
              'Setting up the Props': 'https://www.youtube.com/watch?v=pWFkUOITwqU&list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip&index=3',
              'Dressing the Installation Space': 'https://www.youtube.com/watch?v=LhadZJm7ztQ&list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip&index=4',
              'Preparing your Tech Area': 'https://www.youtube.com/watch?v=SVmFrmXhhsE&list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip&index=5',
              'Turning on the WI-FI': 'https://www.youtube.com/watch?v=NLV4GDHvQsY&list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip&index=6',
              'Charging the Devices': 'https://www.youtube.com/watch?v=_O1CDBG_LzQ&list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip&index=7',
              'Turning in the Devices': 'https://www.youtube.com/watch?v=CR57pGP4haQ&list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip&index=8',
              'Setting the Device Volume': 'https://www.youtube.com/watch?v=S4sOiIG_8Ps&list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip&index=9',
              'Pinning the App': 'https://www.youtube.com/watch?v=GBgbj3Nulck&list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip&index=10',
              'Physical Setup Recap': 'https://www.youtube.com/watch?v=CGANhC8d0Lc&list=PL0wgNP08eY9aacc1BKBroaOI2K3QixZip&index=11',
            }).map(([name, link], index) => <Col key={index} lg={4} xl={3} md={6} sm={12} style={{paddingBottom: 5}}><IoLogoYoutube/><a href={link} target="_blank" rel="noopener noreferrer">{name}</a></Col>)}
          </Row>
        </Container>

        </>}
      </StandardContentBlock>
    </StandardContent>
  </HeaderPage>;
}

/*
The following Delivery Manual outlines all the technical details of how to set up and deliver an Audioplay Installation event.
We suggest that you allow 1 hour to go through the document and associated videos to familiarize yourself with the setup. We strongly recommend that you run a trial session with your colleagues or your own family, prior to your event, allowing you to put the training into action in a less pressured environment. Audioplay will run a short Zoom session with you prior to your event to answer any questions that may have arisen as you considered the training materials.


     Page 1|13
PRE-EVENT PLANNING
The Turners can be delivered with up to 4x Play Spaces running simultaneously, with 4 players in each play space. The physical layout will be tailored to suit your specific space, however factors to keep in mind:
• Players need space to move, so the play space should be a dedicated space (not otherwise used as a
thoroughfare) and free of obstacles.
• Regardless of the configuration of the Play Spaces it’s important that the facilitator has a clear line of sight to all
spaces so as to monitor the experience and respond quickly to any issues.
• All Play Spaces should be in the same room as the WiFi Modem to ensure the strength of the signal to all
devices.
You can download the Audioplay App at any time and try one of the play at home experiences. This will help to give you a sense of how Audioplay works and what to expect when facilitating the installation.
PLAY SPACES
▶️UNPACKING YOUR KIT (5:04min)
▶️PREPARING THE INSTALLATION SPACE (1:55min)
AUDIOPLAY APP DOWNLOAD LINKS
Google Play - https://tinyurl.com/AudioplayAndroid App Store - https://tinyurl.com/AudioplayiOS
▶️ DRESSING THE INSTALLATION SPACE (1:03min) ▶️ PREPARING YOUR TECH AREA (0:58min)
        Kids often loose themselves in the story so it’s useful to use visual markers to clearly delineate the perimeter of the space they are to play in. This can be achieved using specific flooring such as artificial turf, rugs or puzzle mats or even simply marking the space with adhesive tape, to visually differentiate the play space from the surrounding flooring. This is particularly important in a shared event space.
If you are presenting more than one play space it can be useful to create a visual barrier between the Play Spaces. This could be done with drapes or even putting pot plants between the spaces. This visual barrier helps kids overcome the sense that they are being watched by the other participants (which sometimes stifles their play) and also helps them know which set of props are to be used for their specific Play Space.
STAGING & PROPS
▶️ SETTING UP THE PROPS (1:54min) ▶️ PHYSICAL SETUP RECAP (0:52min)
All the props required for the play space should be pre-set in the space, making sure they are all visible to the players.
Lay out the following items on the floor in the centre of each play space. Make sure they are separated so that each item is clearly visible:
       1x Large blanket (double bed size or larger) 2x Flat sheets (double or queen size)
2x Chairs
 1x Prop Phone (place in the corner of the play space)
   Prop Phone - In the base of the prop phone there is a secret compartment. An “Antidote” zip lock bag with a small amount of salt (1/4 teaspoon)
PROP PHONE, 'ANTIDOTE' BAG AND SALT
 should be placed inside the secret compartment. The phone should then be placed in the corner of the play space.
Players are invited to sprinkle the ‘antidote’ during the experience, when players take up this invitation you will have a small amount of salt left on the floor which can be vacuumed up at the end of the day.
Page 2|13

WIFI NETWORK
▶️TURNING ON THE WIFI (2:00min)
To start, unpack your Wi-Fi Router and plug it into a GPO power outlet. The router does not need to be connected to the internet. To confirm it’s setup correctly make sure the Power light and the WiFi network lights are on.
         Page 3|13

MOBILE DEVICE SETUP
▶️CHARGING THE DEVICES (1:54min) ▶️TURNING ON THE DEVICES (2:25min)
Before you start ensure all mobile devices are fully charged, ready to last the duration of the event. To setup the devices:
1. Turn on all devices by holding down the middle button on the side of the device.
2. Select the Audioplay App icon on the home screen.
3. All devices are pre-programmed to open to one of the following three states. Check
   your equipment list to confirm how many of each device you should have. Player devices come in coloured cases and the Control Device have a sparkly case.
           CONTROL DEVICE
Sparkly Case
There should only ever be one Control Device per event.
Your Control Device triggers the experience to start on all the player devices. Once the devices are all switched on you will see the number of Player Devices and Spare Devices for each colour group that are connected to the network.
Ensure that there are 4 Player
Devices for each colour group before commencing the session.
PLAYER DEVICES
Coloured Case
Each Player Device will be pre-set to one of the four characters
1. Abbie, 2. Leo,
3. Rattle or 4. Boa.
Each group of four players will be allocated a colour group. The colour is indicated by the background colour behind the character and by the colour of the phone case.
SPARE DEVICES
Clear Case
Whilst you only need 4 devices for each play space you are running, we have included some Spare Devices, in case there are any issues during the session.
It is best to have all phones on and running the Audioplay App throughout the event, so they are ready to go if you do need to use one of the spares.
         Page 4|13

      PIN THE APP
▶️PINNING THE APP (2:15min)
To avoid members of the public closing the app during play it is important that the App is pinned to the main screen.
 To pin the app, firstly open the Audioplay App then return to the home screen and click the bottom left button (three vertical lines).
You will see the following message when the App is successfully pinned
Scroll across to the Audioplay App and hold down the Audioplay icon at the top.
The Audioplay App will need to be pinned at the beginning of each day.
To unpin the app press and hold the bottom left and bottom right buttons as shown in the image to the left.
A menu will appear, from which you should select “Pin this app”.
         Once all the devices have been switched on and pinned, lay them out on a table, place each one inside a character pouch and connect a pair of headphones to each ready for your audience to arrive. The pouches allow kids to carry the device whilst keeping their hands free. Put the spare devices to one side or carry one in your pocket so it’s at hand if needed.
Page 5|13

WIFI CONNECTION
All the devices will be pre-programed to automatically connect to the Audioplay Wi-Fi network. If you do have connectivity issues, here is how you check and reset the Wi-Fi.
Swipe down from the top and then press and hold the Wi-Fi symbol in the top left corner.
You will then see a list of all available Wi-Fi networks. If the Audioplay2 network isn’t already selected go ahead and select it from the list.
To exit this screen click on the circle in the centre at the bottom of the screen.
WIFI NETWORK
Network: Audioplay2 Password: theturners2017
        If you are unable to connect to the Audioplay network for some reason, the login details are noted here.
   TECHNICAL TROUBLESHOOTING
ISSUE
SOLUTION
     Four player devices are switched on but the Control Device isn’t reading 4 for that colour group?
   Check the WI-FI connection is the same for all devices in that colour group. Adjust the Wi-Fi settings if required (See instructions above).
   I have two control devices, which one do I use?
  Switch off one of the Control Devices, the system is designed to run with only one Control Device and the system will become unstable if you are operating two Control Devices simultaneously.
 Page 6|13

PRE-SHOW TESTS
Prior to families arriving it’s good to test the devices to check they are working fine and the volume is set to an appropriate level. We advise that you run these test 30-45mins before your scheduled start time, so you have time to resolve issues if they do arise.
Throughout the pre-show tests and whilst operating the installation the Facilitator should carry with them their own pair of ear bud headphones. Plug your personal ear-bud headphones into the device for testing the volume levels and whilst listening/overserving the experience so as to reduce the cleaning requirements.
VOLUME TESTS
▶️ SETTING THE DEVICE VOLUME (2:33min)
Its best to have the speaker volume set to silent and the headphone volume set to the maximum recommended level. All devices will be pre-set to these levels however details on how to check these levels are noted below. The volume can be adjusted using the button on the top right side of the device. Make sure your headphones are plugged in when adjusting the volume, in order to adjust the headphone volume specifically.
To test the volume use the Control Device to trigger all devices to start the introductory audio sequence. See instructions for operating the app on page 10. Whilst playing the introductory audio, use your headphones to check to the audio levels on each device as follows.
WARNING: Kids hearing is more sensitive than adults hearing and may be damaged by excessive volume. Never increase the volume over the maximum levels recommended by the device.
    Push the volume button on the top right of the device and the following menu will appear. Select the three dots at the top of the control bar to expand the menu.
In this main menu you can adjust the volume, making sure your headphones are plugged in whilst adjusting the levels.
Make sure that you keep the volume levels within the recommended range. The volume icon will turn orange if you exceed the recommended levels.
     Page 7|13

AUDIENCE MANAGEMENT
As families arrive in the space its useful to keep in mind the following:
KIDS & BUTTONS – Kids are generally familiar with phones and love to push ALL the buttons. If you refer to the phones as ‘Devices’ then kids will be less confident with them. We have found that kids are a lot less likely to push all the buttons when we refer to them as ‘Devices’.
LITTLE KIDS - This experience is not suitable for children under 6 years as they often struggle to keep up with the pace of the audio instructions and they quite often get scared. However, if a 5yr old is playing with a parent/older sibling then it may be OK. Talk it through with the parent and let them decide if their child will be OK with it.
ENGLISH LANGUAGE – The experience is very fast paced and does rely on the players having reasonable English language comprehension. If you have concerns about any players English language comprehension talk it through with their parent. You can partner kids up, so that two kids play as the same character using one of the spare devices, so a child with stronger English can help guide a child that may otherwise struggle.
MIXED ABILITIES – We welcome kids with diverse abilities and neuro-diversity to play. In instances where physical movement may be challenging or they may struggle to keep up, an older child or carer can be given one of the spare devices to play as the same character so they can support a child through the experience.
GETTING KIDS IN THE ZONE - If kids seem hyped up on entering the space it can be useful to get them to take their shoes off, this action provides a momentary breather as they enter the space. It also saves them tramping their shoes all over the blankets/sheets. This strategy should be considered in the context of your COVID-19 response plan.
PARENTS PLAYING & OBSERVING – If parents are keen to play they are most welcome and they generally really enjoy it. If parents are keen to watch their kids play, ask them sit back from the edge of the play space to reduce the feeling of the kids being watched.
GETTING PLAYERS SETUP
As families arrive ask them to get into groups of 4 (2x pairs) – you will need 4 players for the experience to start. If you don’t have enough players invite parents to play to make up the numbers.
In assembling your groups consider the following
- Kids have a better time playing with friends or people they know
- Where possible have little kids paired with older kids/adults
If you need a parent or carer to support a player, then offer them one of the spare devices locked to the same character as the player they are supporting. Always keep at least one spare device on hand in case of issues.
     Page 8|13

OPERATING THE SOFTWARE
The following outlines the screen sequence you will see on each device, the audio that will be heard and the actions the facilitator will need to take, throughout the experience.
PLAYER DEVICES AUDIO FACILITATOR ACTION CONTROL DEVICE
              Greet the audience and get them setup with a device and headphones.
As you provide them with a device + headphones tell them the name of their character.
Ensure there are 4 players for each colour group before pressing “Start Introduction”
         Have you got your headphones on? Sweet, OK let’s get started.
When you’re playing you need to stay in the play space we’ve setup, sometimes we call it a room. The facilitator is pointing it out now, can you see where the corners are? Great, so stay inside that space.
   Point out permitter of the play space noting the corners which are referred to in the audio instructions
         Page 9|13

     While we’re playing we are going to use some props
There’s a
Blanket
Two Sheets
A couple of chairs and An old style phone
Can you see everything? If there is anything missing, ask your facilitator to point it out.
   Answer any questions players may have about the props.
       Right, we’re almost ready.
Now remember, everyone will hear something different, so if you’re asked to do something different from your partner, it’s totally fine, you’re not doing it wrong.
You can’t break it, so just follow the instructions as best you can... Remember, we’re here to have FUN!
           Once you start, you need to be able to use your hands, you don’t need to look at the screen. When you hit start, put your device in your pocket.
Lastly, if you have any issues, just put your hand up and the facilitator will come and help you out.
Ok, give your facilitator a thumbs up to let them know you’re ready to start.
    When all players are giving a thumbs up the facilitator presses “Start” to initiate the experience from the Control Device
          Page 10|13

      Experience audio plays through – 17.5 mins.
    Facilitator should observe the play.
You can listen to the audio of any of the four characters by selecting the character image.
Critical Moment:
35sec - ensure the BLANKET is fully laid out by Boa. (Not the sheet!)
Note, pressing ‘Pause’ will pause the audio on all devices.
          Nice one! That was great!
OK let’s just grab all the bits and pieces and put them back where they were when you started.
If you enjoyed playing we have more Audioplay stories that you can play at home, anytime you like! Check out the App on your phone to see all the other great episodes we’ve got available.
Thanks for playing! Catch you soon!
   Check all props have been reset to their original position.
Check the antidote in the zip lock bag – top up as required and reposition the zip lock bag at the base of the phone.
The devices will reset back to the beginning ready for the next session.
         PLAY
From the point you hit “Start” on the Control Device, the experience will run for 17.5 minutes. If you are delivering the experience to a fixed schedule the facilitator will need to keep patrons moving through to ensure you stay to time.
Whilst patrons are playing its best to be relatively hands off and let the kids figure out how they are navigating the space however if kids are struggling to find props or understand what they are doing please step in to help. We want them to enjoy the experience.
There is one critical point to ensure runs smoothly. 35 seconds in it is important that Boa lays the BLANKET out on the floor (not a sheet). Many subsequent instructions hang off this action being completed correctly, so if the players don’t do this, please step in and lay the blanket out for them.
Page 11|13

If kids get scared at any point it’s fine for them to stop playing. Kids will often respond well to a short break, taking the headphones off and sitting on the sidelines whilst they overcome the sense of fear before being ready to re-join the experience. If a child leaves the experience in the first half (before the point where the Vipermen and the Turners are working together) it is advisable that the facilitator join the game and play as the absent character. Simply select that character on the Control Device and join in.
SPARE DEVICES
We have included some spare devices, in case there is an issue with any of the Player Devices. These devices will be connected to the same colour group as the player devices – as indicated by the frame and number colour. However these devices are not set to be a specific character.
In the event there is an issue with a Player Device, grab a Spare Device and simply
• selecttherelevantcharacter
• press the ‘lock button’ and
• switch the spare device with the problematic Player Device.
This will minimise disruption for the player.
Spare Devices can also be used to enable two people to play as the same character. This is useful for little kids who may need parental assistance or players with mobility issues needing a carer to support them. Simply select the appropriate character after the introduction is complete and
     as the experience is beginning.
Always keep at least one Spare Device on hand, in case of technical issues through the experience.
RESET
Upon completion of the experience the facilitator should check that all props have been reset to their original position as well as replenishing the salt in the zip-lock bag if necessary.
The reset process should incorporate any cleaning required as part of your COVID-19 response plan. You should now be ready for the next group.
  VIDEO LINK SUMMARY
▶️UNPACKING YOUR KIT (5:04min)
▶️PREPARING THE INSTALLATION SPACE (1:55min) ▶️ SETTING UP THE PROPS (1:54min)
▶️TURNING ON THE DEVICES (2:25min)
▶️ SETTING THE DEVICE VOLUME (2:33min)
▶️ PHYSICAL SETUP RECAP (0:52min)
▶️ DRESSING THE INSTALLATION SPACE (1:03min) ▶️ PREPARING YOUR TECH AREA (0:58min) ▶️TURNING ON THE WIFI (2:00min)
▶️CHARGING THE DEVICES (1:54min)
▶️PINNING THE APP (2:15min)
           Page 12|13

 SETUP CHECKLIST
Run through this checklist at the start of each day
Night before
o All devices put on charge
2 Hrs before audience arrives
o The antidote is in the back of the phone and the phone is placed in the corner of the space
1 Hr before audience arrives
o Checkalldevices100%charged
o All devices are switched on
o The Control Device is showing the correct number of Player Devices and Spare Devices o The Audioplay App pinned on all devices
o Checkvolumelevelonalldevices
o Player devices are laid out on the table, each with a pouch and headphones plugged in RESET CHECKLIST
o Check and refill the antidote, placing it back in the secret compartment of the phone o COVID cleaning complete – e.g. all hard surfaces wiped down
PACK DOWN CHECKLIST - Experience to continue on a subsequent day
o All phones switched off
o All phones moved to secure overnight storage and plugged into the charging unit to charge overnight
o Check and refill the antidote, placing it back in the secret compartment of the phone o COVIDcleaningcompleted
PACK DOWN CHECKLIST - No subsequent sessions planned
o All phones switched off
o COVIDcleaningcompleted
o All devices and technical elements packed in their designated spaces ready for shipping
o The physical elements in the room are set up and the play spaces clearly defined o All props are on the floor in the centre of the play space
- 1x Large blanket (double bed size or larger)
- 2x Flat sheets (double or queen size)
- 2x Chairs
- 1x Old style phone
    
o All props are unravelled/unfolded and placed in the centre of the play space
- 1x Large blanket (double bed size or larger)
- 2x Flat sheets (double or queen size)
- 2x Chairs
- 1x Old style phone
 
o All props are unravelled/unfolded and placed in the centre of the play space
- 1x Large blanket (double bed size or larger)
- 2x Flat sheets (double or queen size)
- 2x Chairs
- 1x Old style phone
 o All props neatly folded and packed for shipping o Shippinglabelsprintedandsecurelyattached

 */