import React, { Component } from 'react';
import { Col } from 'react-bootstrap';

export interface Props {
  alternate?: boolean,
  children?: React.ReactNode,
};

export interface State {

}

export class Block extends Component<Props, State> {

  render() {
    return <Col className={`content-block ${this.props.alternate === true? 'alternate': 'base'}`}>
      {this.props.children}
    </Col>
  }
}