import { Form } from 'react-bootstrap';
import { Link as L } from 'react-router-dom';
import { StandardControlProps, useControlState, wrapFormControl, labelHelper } from '../Base';

export function Dropdown(props: StandardControlProps & { type: 'dropdown' }) {
  const { value, change, outputProps } = useControlState(props);
  const { display = {}, options = {} } = props;
  const reverseOptions: { [key: string]: string } = {};
  let defaultValue: string | undefined = undefined;

  Object.entries(options).forEach(([k,v]) => {
    if (typeof v === 'object' && v.default === true) {
      defaultValue = k;
    }
    reverseOptions[typeof v === 'object'? v.value :v] = k;
  });
  (outputProps as any).onChange = (event: React.ChangeEvent<any>) => {
    if (event.target === undefined){
      return;
    }
    change(reverseOptions[event.target.value]);
  }
  const inputValue = value !== undefined ? value.toString():'';
  let v = options[value !== undefined ? value.toString():''];
  if (v === undefined && defaultValue !== undefined) {
    v = defaultValue;
    change(defaultValue);
  }

  const selectValue = typeof v === 'object'? v.value :v;
  return wrapFormControl(props, outputProps, props.title, undefined, <Form.Control ref={props.inputRef} {...outputProps} value={selectValue || ''} as="select">
    {selectValue === undefined? <option disabled={true} value=''></option>: undefined}
    {Object.entries(options).map(([k,v]) => {
      const label = typeof v === 'object'? v.value :v;
      const disabled = typeof v === 'object'? v.disabled :false;
      return <option disabled={disabled} value={label}>{label}</option>
    })}
  </Form.Control>);
}