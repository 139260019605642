export const southAustralianSchools = [
  "A. Pushkin Russian School",
  "Aberfoyle Campus Schools OSHC Incorporated",
  "Aberfoyle Hub Preschool - Taylors Road",
  "Aberfoyle Hub Primary School OSHC/Vac",
  "Aberfoyle Hub R-7 School",
  "Aberfoyle Park Campus Preschool",
  "Aberfoyle Park High School",
  "Acacia Kindergarten",
  "Adams Road Children's Centre",
  "Adelaide Botanic High School",
  "Adelaide Centre for Hearing Impaired",
  "Adelaide Centre for Hearing Impaired",
  "Adelaide Chinese School",
  "Adelaide East Education Centre",
  "Adelaide Festival Centre - Education Service",
  "Adelaide High School",
  "Adelaide Hills YMCA OSHC/Vac",
  "Adelaide International School",
  "Adelaide Japanese Community School",
  "Adelaide Lithuanian School",
  "Adelaide Miethke Kindergarten",
  "Adelaide Miethke Kindergarten Occasional Care Ctr",
  "Adelaide Nepali Vidhyalaya",
  "Adelaide North Special School",
  "Adelaide North Special School OSHC",
  "Adelaide Russian Ethnic School Incorporated",
  "Adelaide Secondary School of English",
  "Adelaide Tamil Language School",
  "Adelaide West Special Education Centre",
  "Adelaide West Special Education Centre OSHC",
  "Adelaide-Bangladeshi Cultural Club Inc",
  "Afghan Ethnic School",
  "Agnes Goode Kindergarten Inc",
  "Agnes Goode Kindergarten Occasional Care",
  "Airdale Primary School",
  "Airdale Primary School OSHC/Vac",
  "Akuna Kindergarten",
  "Al Farooq Arabic School",
  "Al-Noor Ethnic School",
  "Al-Salam Arabic School",
  "Alberton Preschool",
  "Alberton Primary School",
  "Alberton Primary School OSHC/Vac",
  "Aldgate - Primary School OSHC",
  "Aldgate Kindergarten Inc",
  "Aldgate Primary School",
  "Aldinga Beach B-7 School",
  "Aldinga Beach Children's Centre",
  "Aldinga Beach Children's Centre Occ Care",
  "Aldinga Beach OSHCers/Vac",
  "Aldinga Community Kindergarten",
  "All Saints Catholic Primary School",
  "Allenby Gardens Child Parent Centre",
  "Allenby Gardens Primary School",
  "Allenby Gardens Primary School OSHC",
  "Allendale East Area School",
  "Allendale East Area School OSHC/Vac",
  "Alliance Francaise d' Adelaide Inc",
  "Amata Anangu School",
  "Amata Preschool",
  "Anangu Education Services - Ernabella Office",
  "Anangu Pitjanytjara Yankunytjatjara Trade Training",
  "Andamooka Children's Centre",
  "Andamooka Occasional Care",
  "Andamooka Preschool",
  "Andamooka Primary School",
  "Andrews Farm Community Preschool",
  "Angaston Kindergarten",
  "Angaston Primary School",
  "Angle Vale PS OSHC/Vac - Camp Australia",
  "Angle Vale Preschool",
  "Angle Vale Primary School",
  "Annesley Junior School",
  "Annesley Junior School OSHC",
  "Antonio Catholic School",
  "Antonio School OSHC/Vac",
  "Arbury Park Outdoor School",
  "Ardrossan & Districts Community Kindergarten",
  "Ardrossan & Districts Kindergarten Occasional Care",
  "Ardrossan Area School",
  "Ardtornish Children's Centre",
  "Ardtornish Children's Centre Occasional Care",
  "Ardtornish Primary School",
  "Ardtornish Primary School OSHC/Vac",
  "Ariana Farsi School",
  "Ariana Farsi School",
  "Arno Bay Playcentre",
  "Ascot Park Kindergarten",
  "Ascot Park Primary School",
  "Ascot Park Primary School OSHC",
  "Aspect Treetops School",
  "Association of Ukrainians Community School Adel",
  "Athelstone Preschool",
  "Athelstone School",
  "Athelstone School OSHC/Vac",
  "Auburn Playcentre",
  "Auburn Primary School",
  "Audioplay/Windmill Test",
  "Augusta Park Childhood Services Centre",
  "Augusta Park Primary School",
  "Auslan Early Learning Program",
  "Australian Druze Arabic School",
  "Australian Islamic College (Adelaide) ELC",
  "Australian Islamic College Adelaide",
  "Australian Science & Mathematics School",
  "Autism Intervention Program - The Heights School",
  "Autism Intervention Program - The Heights School",
  "Avenues College",
  "Avenues College Beatty Ave Campus",
  "Avenues College Camp Australia OSHC",
  "Avenues College Centre for Hearing Impaired",
  "Avenues College Children's Centre for ECD&P",
  "Avenues College Children's Centre for ECD&P",
  "Baden Pattinson Kindergarten",
  "Bains Road Preschool",
  "Bains Road Preschool Occ Care",
  "Balaklava Community Children's Centre",
  "Balaklava High School",
  "Balaklava Primary School",
  "Balharry Memorial Kindergarten",
  "Balharry Rural Care",
  "Ballara Park Kindergarten",
  "Bangla Language and Cultural School",
  "Bangla School",
  "Bangladeshi Community School",
  "Banksia Park International High School",
  "Banksia Park Kindergarten",
  "Banksia Park School R-7",
  "Banksia Park School R-7 OSHC/Vac",
  "Bantu Ethnic School of SA",
  "Barbara Kiker Memorial Kindergarten",
  "Barker Kindergarten",
  "Barmera Kindergarten",
  "Barmera Kindergarten Occasional Care",
  "Barmera Primary School",
  "Barmera Primary School OSHC/Vac",
  "Barossa Recreation & Fitness Centre Vacation Care",
  "Basket Range Primary School",
  "Beachport Preschool",
  "Beachport Primary School",
  "Beafield EC McKay Avenue Campus",
  "Beafield EC:Brahma Lodge Campus",
  "Beafield EC:Silkes Road Campus",
  "Beafield Education Centre",
  "Beit Shalom Hebrew Language School",
  "Belair Jean Bonython Kindergarten",
  "Belair Primary School",
  "Belair Primary School OSHC/Vac",
  "Bellevue Heights PS Intensive English Lang Centre",
  "Bellevue Heights PS Intensive English Lang Centre",
  "Bellevue Heights Primary School",
  "Bellevue Heights Primary School OSHC",
  "Berri Community Preschool",
  "Berri Community Preschool Occasional Care",
  "Berri Education Director Office",
  "Berri Family Day Care",
  "Berri Greek Orthodox Community School",
  "Berri Primary School",
  "Berri Primary School OSHC/Vac",
  "Bertram Hawker Kindergarten",
  "Bethany Christian School Inc",
  "Bethany Christian School OSHC Camp Australia",
  "Better Behaviour Centre Elizabeth East",
  "Better Behaviour Centre Huntfield Heights",
  "Better Behaviour Centre Murray Bridge",
  "Better Behaviour Centre Murray Bridge",
  "Better Behaviour Centre Port Lincoln",
  "Better Behaviour Centre Port Lincoln",
  "Better Behaviour Centre Salisbury Downs",
  "Better Behaviour Centre Woodville",
  "Bhutanese Ethnic School",
  "Birdwood High School",
  "Birdwood Primary School",
  "Birdwood Primary School OSHC/Vac",
  "Bishop Kindergarten",
  "Black Forest PS OSHC/Vac",
  "Black Forest Primary School",
  "Blackfriars Priory School",
  "Blackfriars Priory School OSHC/Vac",
  "Blackwood High School",
  "Blackwood Kindergarten",
  "Blackwood Primary School",
  "Blackwood Primary School OSHC/Vac",
  "Blair Athol North Children's Centre Occ Care",
  "Blair Athol North School B-7",
  "Blair Athol North School B-7 Children's Centre",
  "Blair Athol North School B-7 Int Eng Lang Centre",
  "Blair Athol North School B-7 Int Eng Lang Centre",
  "Blakes Crossing Christian College",
  "Blakes Crossing Christian College OSHC Camp Aust",
  "Blakeview Preschool",
  "Blakeview Primary School",
  "Blakeview Primary School OSHC/Vac",
  "Blanchetown Playcentre",
  "Blanchetown Primary School",
  "Blyth PS OSHC",
  "Blyth Primary School",
  "Bo De School",
  "Booborowie Playcentre",
  "Booborowie Primary School",
  "Booleroo Centre & District Kindergarten",
  "Booleroo Centre District School",
  "Booleroo Rural Care",
  "Bordertown High School",
  "Bordertown Primary School",
  "Bosniak Ethnic School",
  "Botanic Gardens of Adelaide - Educ Service",
  "Bowden Brompton CS Beach Campus",
  "Bowden Brompton CS Northern Middle School Campus",
  "Bowden Brompton CS Torrens Road Campus",
  "Bowden Brompton Community School",
  "Braeview Primary School OSHC/Vac",
  "Braeview School R-7",
  "Brahma Lodge Kindergarten Inc",
  "Brahma Lodge Primary School",
  "Brazilian Ethnic School of SA Inc",
  "Brentwood Drive Kindergarten",
  "Bridgewater Kindergarten Inc",
  "Bridgewater PS OSHC/Vac Camp Australia",
  "Bridgewater Primary School",
  "Brighton Centre for Hearing Impaired",
  "Brighton Primary School",
  "Brighton Primary School OSHC/Vac",
  "Brighton Secondary School",
  "Brinkworth Occasional Care",
  "Brinkworth Preschool Centre",
  "Brinkworth Primary School",
  "Brompton PS OSHC/Vac",
  "Brompton Primary School",
  "Bulgarian Ethnic School",
  "Burnside Primary School",
  "Burnside Primary School OSHC/Vac",
  "Burra Community School",
  "Burra Community School OSHC/Vac",
  "Burra Early Learning Centre",
  "Burra Rural Care",
  "Burton Park Preschool",
  "Burton Park Preschool Occ Care",
  "Burton Primary School",
  "Burton Primary School OSHC/Vac",
  "Burundi Intamba Gasimbo Association of SA",
  "Bute Primary School",
  "Bute Rural Care",
  "Bute and District Kindergarten",
  "CSIRO Education",
  "CaFE Enfield Children's Centre",
  "Cabra Dominican College",
  "Cadell Playcentre",
  "Cadell Primary School",
  "Callington Kindergarten",
  "Callington Kindergarten Occasional Care",
  "Callington Primary School",
  "Calvary Kindergarten",
  "Calvary Lutheran Primary School",
  "Calvary Lutheran Primary School OSHC Camp Aust",
  "Cambrai Preschool",
  "Cambrai Preschool Occasional Care",
  "Cambrai Primary School",
  "Campbelltown Preschool",
  "Cardijn College",
  "Caritas College",
  "Carlton Preschool",
  "Carlton School",
  "Carol Murray Children's Centre",
  "Catherine McAuley School",
  "Cavan Education Centre",
  "Cedar College",
  "Cedar College Out of School Hours Care",
  "Ceduna Area School",
  "Ceduna Community Children's Centre OSHC/Vac",
  "Central Metro Family Day Care Scheme",
  "Central Yorke Early Learning Centre",
  "Central Yorke School",
  "Central Yorke School - Point Pearce Campus",
  "Central Yorke School Rural Care",
  "Challa Gardens Child Parent Centre",
  "Challa Gardens Primary School",
  "Chandlers Hill Kindergarten",
  "Charles Campbell College",
  "Charles Campbell College OSHC/Vac",
  "Chinese Assoc of SA Inc Chinese Ethnic School",
  "Chinese School of Chinese Welfare Services of SA",
  "Chinese School of South Australia Inc",
  "Christ the King School",
  "Christ the King School OSHC",
  "Christian Brothers College Junior School OSHC/Vac",
  "Christian Brothers' College - Senior",
  "Christian Brothers' College:Junior School",
  "Christie Downs Kindergarten",
  "Christie Downs Kindergarten Occ Care",
  "Christie Downs Learning Together @ Home",
  "Christie Downs Primary School",
  "Christies Beach HS & Southern Vocational College",
  "Christies Beach PS OSHC/Vac Camp Australia",
  "Christies Beach PS OSHC/Vac Camp Australia",
  "Christies Beach Primary School",
  "Christies North Kindergarten",
  "Christies North Kindergarten Occ Care",
  "Clapham Primary School",
  "Clapham Primary School Happy Haven OSHC",
  "Clare High School",
  "Clare Learning Together @ Home",
  "Clare Primary School",
  "Clare Primary School OSHC/Vac",
  "Clare Valley Children's Centre",
  "Clarence Gardens Kindergarten",
  "Clarence Park Community Kindergarten",
  "Clarendon Kindergarten",
  "Clarendon Primary School",
  "Cleve Area School",
  "Cleve District Children's Centre",
  "Cleve Rural Care",
  "Clovelly Park PS Intensive English Lang Centre",
  "Clovelly Park PS Intensive English Lang Centre",
  "Clovelly Park Primary School",
  "Clovelly Park Primary School OSHC/Vac",
  "Cobbler Creek OSHC/Vac - Golden Grove PS",
  "Cobdogla Primary School",
  "Colonel Light Gardens Primary School",
  "Colonel Light Gardens Primary School OSHC/Vac",
  "Communication & Language Disorder Support Service",
  "Compton Primary School",
  "Concordia College",
  "Concordia College - St John's Campus",
  "Concordia Kindergarten",
  "Coober Pedy Area School",
  "Coober Pedy Area School OSHC",
  "Coober Pedy Child Parent Centre",
  "Coomandook & Districts Kgn Occasional Care",
  "Coomandook Area School",
  "Coomandook and Districts Kindergarten",
  "Coonalpyn & District Kindergarten Occasional Care",
  "Coonalpyn Preschool",
  "Coonalpyn Preschool",
  "Coonalpyn Primary School",
  "Coorabie Rural School-Annexe of Penong PS",
  "Coorara PS OSHC/Vac",
  "Coorara Preschool Centre",
  "Coorara Primary School",
  "Cornerstone College",
  "Corny Point Playcentre",
  "Coromandel Valley Kindergarten",
  "Coromandel Valley PS OSHC/Vac",
  "Coromandel Valley Primary School",
  "Country Central Family Day Care Scheme",
  "Cowandilla Children's Centre",
  "Cowandilla Children's Centre Occasional Care",
  "Cowandilla Learning Centre",
  "Cowandilla PS Intensive English Lang Centre",
  "Cowandilla PS Intensive English Lang Centre",
  "Cowandilla Primary School",
  "Cowandilla Primary School OSHC/Vac",
  "Cowell Area School",
  "Cowell Early Childhood Centre",
  "Cowell Rural Care",
  "Crafers - Primary School OSHC",
  "Crafers Primary School",
  "Craigburn Primary School",
  "Craigburn Primary School OSHC/Vac",
  "Craigmore High School",
  "Craigmore Kindergarten",
  "Craigmore South Primary School",
  "Croatian Ethnic School",
  "Croatian Language School",
  "Crossways Lutheran School",
  "Crystal Brook Kindergarten",
  "Crystal Brook Kindergarten Occ Care",
  "Crystal Brook Primary School",
  "Cumberland Preschool Kindergarten Inc",
  "Cummins Area School",
  "Cummins Child Parent Centre",
  "Cummins Rural Care",
  "Curramulka Primary School",
  "Dac Lo Vietnamese Ethnic School",
  "Dara Village School",
  "Darke Peak Playcentre",
  "Darlington Children's Centre",
  "Darlington Kindergarten Occ Care",
  "Darlington PS Intensive English Lang Centre",
  "Darlington PS Intensive English Lang Centre",
  "Darlington Primary School",
  "Davison Street Occasional Care",
  "Dernancourt Kindergarten",
  "Dernancourt Primary School OSHC/Vac",
  "Dernancourt School R-7",
  "Dinka Bor Ethnic School",
  "Disability Unit Blakeview PS",
  "Disability Unit Christie Downs Primary School",
  "Disability Unit Christies Beach",
  "Disability Unit Clare High School",
  "Disability Unit Craigmore HS",
  "Disability Unit Findon High School",
  "Disability Unit Findon High School",
  "Disability Unit Gawler and District College",
  "Disability Unit Golden Grove High School",
  "Disability Unit Golden Grove High School",
  "Disability Unit Hamilton Secondary College",
  "Disability Unit Hamilton Secondary College",
  "Disability Unit Henley High School",
  "Disability Unit Hewett Primary School",
  "Disability Unit Ingle Farm East Primary School",
  "Disability Unit Kadina Memorial School",
  "Disability Unit Keller Road Primary School",
  "Disability Unit Kidman Park",
  "Disability Unit Madison Park School",
  "Disability Unit Madison Park School",
  "Disability Unit Mount Barker High School",
  "Disability Unit Mount Barker Primary School",
  "Disability Unit Murray Bridge High School",
  "Disability Unit Nuriootpa HS Learning Unit",
  "Disability Unit Para Hills HS",
  "Disability Unit Riverdale PS",
  "Disability Unit Roma Mitchell Secondary College",
  "Disability Unit Roma Mitchell Secondary College",
  "Disability Unit Salisbury HS",
  "Disability Unit Salisbury Park Primary School",
  "Disability Unit Salisbury Park Primary School",
  "Disability Unit Seaford Rise Primary School",
  "Disability Unit Seaford Rise Primary School",
  "Disability Unit South Downs Primary School",
  "Disability Unit Springbank Secondary College",
  "Disability Unit St Morris",
  "Disability Unit Tanunda PS",
  "Disability Unit Victor Harbor High School",
  "Disability Unit Victor Harbor High School",
  "Disability Unit Victor Harbor PS",
  "Disability Unit Wirreanda Secondary School",
  "Dominican School",
  "Dominican School OSHC",
  "Domino Servite College",
  "Dorothy Hughes Kindergarten",
  "Dorothy Hughes Occasional Care",
  "Dover Kindergarten",
  "Dover Kindergarten Occ Care",
  "Dunbar Terrace Kindergarten",
  "East Adelaide OSHC/Vac",
  "East Adelaide School",
  "East Marden Primary School",
  "East Marden Primary School OSHC/Vac",
  "East Para Primary School",
  "East Para Primary School OSHC/Vac",
  "East Torrens PS - Kidsfun OSHC/Vac",
  "East Torrens PS Intensive English Lang Centre",
  "East Torrens PS Intensive English Lang Centre",
  "East Torrens Primary School",
  "Eastern Districts Family Day Care Scheme",
  "Eastern Eyre Family Day Care Scheme",
  "Eastern Fleurieu Ashbourne Campus",
  "Eastern Fleurieu Langhorne Creek Campus",
  "Eastern Fleurieu Milang Campus",
  "Eastern Fleurieu R-12 School",
  "Eastern Fleurieu School OSHC/Vac",
  "Eastern Fleurieu Strathalbyn 7-12 Campus",
  "Eastern Fleurieu Strathalbyn R-6 Campus",
  "Echunga Child Parent Centre",
  "Echunga Primary School",
  "Echunga Primary School OSHC/Vac",
  "Eden Hills Kindergarten",
  "Eden Hills Primary School",
  "Eden Hills Primary School OSHC/Vac",
  "Education Development Centre",
  "Education Support Hub",
  "Edward John Eyre High School",
  "Edwardstown Primary School",
  "Edwardstown Primary School OSHC/Vac",
  "Elizabeth Downs Child Parent Centre",
  "Elizabeth Downs Child Parent Centre Occ Care",
  "Elizabeth Downs PS Intensive English Lang Centre",
  "Elizabeth Downs PS Intensive English Lang Centre",
  "Elizabeth Downs Primary School",
  "Elizabeth East Kindergarten",
  "Elizabeth East Occasional Care",
  "Elizabeth East Primary School",
  "Elizabeth Family Day Care",
  "Elizabeth Grove Children's Centre",
  "Elizabeth Grove Children's Centre Occasional Care",
  "Elizabeth Grove Primary School",
  "Elizabeth Grove Primary School OSHC",
  "Elizabeth North CPC Occasional Care",
  "Elizabeth North Preschool",
  "Elizabeth North Primary School",
  "Elizabeth North Primary School OSHC/Vac",
  "Elizabeth O'Grady Kindergarten",
  "Elizabeth Park Child Parent Centre",
  "Elizabeth Park Primary School",
  "Elizabeth South Primary School",
  "Elizabeth Vale PS Intensive English Lang Centre",
  "Elizabeth Vale PS Intensive English Lang Centre",
  "Elizabeth Vale Preschool",
  "Elizabeth Vale Primary School",
  "Elliston Area School",
  "Elliston RSL Memorial Children's Centre",
  "Elsie Ey Children's Centre",
  "Emmaus Catholic School",
  "Emmaus Catholic School Camp Australia OSHC/Vac",
  "Emmaus Christian College",
  "Emmaus Christian College OSHC",
  "Encounter Lutheran College",
  "Encounter Lutheran School OSHC/Vac",
  "Endeavour College",
  "Enfield Folland Park Kindergarten",
  "Enfield Primary School",
  "Enfield Primary School OSHC",
  "Eritrean Ethnic School of South Australia",
  "Ernabella Anangu Child Parent Centre",
  "Ernabella Anangu School",
  "Ernabella Children and Family Centre",
  "Errington Special Education Centre",
  "Eudunda Area School",
  "Eudunda Community Preschool",
  "Eudunda Community Preschool Occ Care",
  "Evanston Gardens Primary School",
  "Eynesbury College Coglin St Campus",
  "Eynesbury Senior College",
  "Fairview Park Child Parent Centre",
  "Fairview Park Kindergarten",
  "Fairview Park Primary School",
  "Fairview Park Primary School OSHC",
  "Faith Lutheran College",
  "Faith Lutheran College Early Learning Centre",
  "Family Day Care Business & Customer Support Ctr",
  "Far North Family Day Care Scheme",
  "Felixstow 1 Education Director Office",
  "Felixstow 2 Education Director Office",
  "Felixstow Community School",
  "Felixstow Community School OSHC",
  "Felixstow3 Education Director Office",
  "Filipino Ethnic School of SA Inc (Salisbury)",
  "Findon High School",
  "Fisk Street Primary School",
  "Flagstaff Hill Kindergarten",
  "Flagstaff Hill R-7 School",
  "Flagstaff Hill R-7 School OSHC/Vac",
  "Flagstaff Oval Kindergarten",
  "Flaxmill Preschool",
  "Flaxmill School P-7",
  "Flaxmill Schools OSHC Camp Australia",
  "Fleurieu Learning Together @ Home",
  "Flexi Centre",
  "Flexible Accredited Meaningful Education",
  "Flinders Children's Centre",
  "Flinders Park 1 Education Director Office",
  "Flinders Park 2 Education Director Office",
  "Flinders Park 3 Education Director Office",
  "Flinders Park 4 Education Director Office",
  "Flinders Park Family Day Care",
  "Flinders Park Primary School",
  "Flinders Park Primary School OSHC/Vac",
  "Flinders View Child Parent Centre",
  "Flinders View Primary School",
  "Forbes Children's Centre",
  "Forbes Primary School",
  "Forbes Primary School OSHC/Vac",
  "Frances Playcentre",
  "Frances Primary School",
  "Fraser Park Child Parent Centre",
  "Fraser Park Primary School",
  "Free Serbian Orthodox Church Comm Schl St Sava",
  "Freeling PS OSHC/Vac Happy Haven",
  "Freeling Preschool",
  "Freeling Primary School",
  "Fregon Anangu School",
  "Fregon Child Parent Centre",
  "Frieda Corpe Community Kindergarten",
  "Fulham Gardens Primary School",
  "Fulham North PS OSHC",
  "Fulham North Primary School",
  "Fulham Park Preschool Kindergarten",
  "Gabmididi Manoo Child & Family Ctr Occasional Care",
  "Gabmididi Manoo Children And Family Centre",
  "Gabriela Mistral Spanish Speaking School",
  "Galilee Catholic School",
  "Galilee Catholic School",
  "Galilee Catholic School OSHC/Vac",
  "Garden College",
  "Gawler & District College B-12 Children's Centre",
  "Gawler & District College Child Ct Occasional Care",
  "Gawler 1 Education Director Office",
  "Gawler 2 Education Director Office",
  "Gawler East Preschool Centre",
  "Gawler East Primary School",
  "Gawler East Primary School OSHC/Vac",
  "Gawler Primary School",
  "Gawler Primary School OSHC Camp Australia",
  "Gawler and District College B-12",
  "Gawler and District College OSHC Camp Australia",
  "Gawler and District College OSHC Camp Australia",
  "Geranium Kindergarten",
  "Geranium Primary School",
  "Gidja Club Vacation Care",
  "Gilles Street PS Intensive English Lang Centre",
  "Gilles Street PS Intensive English Lang Centre",
  "Gilles Street Primary School",
  "Gilles Street Primary School OSHC/Vac",
  "Gladigau Park Kindergarten",
  "Gladigau Park Kindergarten Occ Care",
  "Gladstone High School",
  "Gladstone Kindergarten",
  "Gladstone Occasional Care",
  "Gladstone Primary School",
  "Glandore Community Kindergarten",
  "Gleeson College",
  "Glen Osmond Primary School",
  "Glen Osmond Primary School OSHC",
  "Glenburnie Primary School",
  "Glencoe & District Kindergarten",
  "Glencoe & District Kindergarten Occasional Care",
  "Glencoe Central Primary School",
  "Glenelg Primary School",
  "Glenelg Primary School OSHC/Vac",
  "Glenunga International High School",
  "Glossop Community Preschool",
  "Glossop High School",
  "Glossop High School Middle Campus",
  "Glossop High School Senior Campus",
  "Glossop Primary Community School K-7",
  "Golden Grove High School",
  "Golden Grove Kindergarten",
  "Golden Grove Lutheran Primary School",
  "Golden Grove Lutheran School OSHC/Vac",
  "Golden Grove Primary School",
  "Good Shepherd Lutheran College Angaston OSHC",
  "Good Shepherd Lutheran Sch - Para Vista OSHC/Vac",
  "Good Shepherd Lutheran School - Angaston",
  "Good Shepherd Lutheran School - Para Vista",
  "Goodwood Preschool",
  "Goodwood Primary School",
  "Goodwood Primary School OSHC/Vac",
  "Goolwa Children's Centre",
  "Goolwa PS OSHC Camp Australia",
  "Goolwa Primary School",
  "Gordon Education Centre",
  "Grange Primary School",
  "Grange Primary School OSHC/Vac",
  "Grant High School",
  "Greek Orthodox Church Comm of St Anthony & Nthn S",
  "Greek Orthodox Comm of Nativity of Christ Pt Adel",
  "Greek Orthodox Community & Parish of St George",
  "Greek Orthodox Community of Norwood/Eastern Suburb",
  "Greek Orthodox Community of South Australia Inc",
  "Greenock Primary School",
  "Greenwith Kindergarten",
  "Greenwith Primary School",
  "Grey Ward Children's Centre",
  "Grove Kindergarten",
  "Gulfview Heights Primary School",
  "Gulfview Heights Primary School OSHC",
  "Gumeracha Primary School",
  "Gumeracha Primary School OSHC/Vac",
  "Guru Nanak School",
  "Hackham East Kindergarten",
  "Hackham East Primary School",
  "Hackham East Primary School OSHC",
  "Hackham West Children's Centre",
  "Hackham West R-7 School",
  "Hackney Kindergarten",
  "Hahndorf Preschool",
  "Hahndorf Primary School",
  "Hahndorf Primary School OSHC/Vac",
  "Halifax St Children's Centre & Preschool",
  "Hallett Cove East PS OSHC/Vac",
  "Hallett Cove East PS OSHC/Vac",
  "Hallett Cove East Primary School",
  "Hallett Cove Karrara Kindergarten",
  "Hallett Cove Preschool",
  "Hallett Cove School",
  "Hallett Cove School OSHC/Vac",
  "Hallett Cove School OSHC/Vac",
  "Hallett Cove South Preschool",
  "Hallett Cove South Primary School",
  "Hallett Cove South Primary School OSHC",
  "Hallett/Mt Bryan Playcentre",
  "Hamilton Secondary College",
  "Hamilton Secondary College Vac",
  "Hamley Bridge Kindergarten",
  "Hamley Bridge Occasional Care",
  "Hamley Bridge Primary School",
  "Hampstead PS Intensive English Lang Centre",
  "Hampstead PS Intensive English Lang Centre",
  "Hampstead Preschool",
  "Hampstead Primary School",
  "Happy Valley Kindergarten",
  "Happy Valley Primary School",
  "Happy Valley Primary School OSHC/Vac",
  "Harvest Christian College",
  "Harvest Christian College Camp Australia OSHC",
  "Hawker Area School",
  "Hawker Childhood Services Centre",
  "Hawthorndene Kindergarten",
  "Hawthorndene Primary School",
  "Hawthorndene Primary School OSHC/Vac",
  "Heathfield High School",
  "Heathfield Primary School",
  "Hendon Preschool",
  "Hendon Preschool Occasional Care",
  "Hendon Primary School",
  "Hendon Primary School OSHC/Vac",
  "Henley Beach Primary Out of School Hours Care",
  "Henley Beach Primary School",
  "Henley Community Kindergarten",
  "Henley High School",
  "Heritage College Inc",
  "Hewett PS OSHC Camp Aust",
  "Hewett Primary School",
  "Highbury Preschool",
  "Highbury Preschool Occasional Care",
  "Highbury Primary School",
  "Highbury Primary School OSHC/Vac",
  "Highbury Primary School OSHC/Vac",
  "Highgate Primary School OSHC/Vac",
  "Highgate School",
  "Hillcrest Primary School",
  "Hillcrest Primary School OSHC/Vac",
  "Hills Murraylands Family Day Care Scheme",
  "Hincks Avenue Primary School",
  "Holden Hill Kindergarten",
  "Holden Hill Kindergarten Occasional Care",
  "Holy Family Catholic School",
  "Holy Family Catholic School OSHC/Vac",
  "Holy Monastery of St Nectarios Greek School",
  "Hope Christian College",
  "Hope Christian College OSHC/Vac",
  "Horizon Christian School",
  "Hospital School SA - Flinders Medical Centre",
  "Hospital School SA - Flinders Medical Centre",
  "Hospital School SA - Women's & Children's Hospital",
  "Hospital School South Australia",
  "Hospital School South Australia",
  "Houghton Preschool",
  "Hungarian Community School Adelaide Inc",
  "Huntfield Heights Child Parent Centre",
  "Huntfield Heights School P-7",
  "ICMG Arabic School",
  "IQRA Islamic College of South Australia",
  "Imagine Kilburn Kids School Holiday Program",
  "Imam Ali School",
  "Immaculate Heart of Mary School",
  "Immanuel College",
  "Immanuel Greek School",
  "Immanuel Lutheran Schl Gawler Camp Australia OSHC",
  "Immanuel Lutheran School",
  "Immanuel Primary School",
  "Immanuel Primary School Camp Australia OSHC",
  "Immanuel Primary School Early Learning Centre",
  "Indulkana Anangu School",
  "Indulkana Child Parent Centre",
  "Ingle Farm Children's Centre",
  "Ingle Farm Community Kindergarten Occ Care",
  "Ingle Farm East Primary School",
  "Ingle Farm East Primary School OSHC/Vac",
  "Ingle Farm PS Intensive English Lang Centre",
  "Ingle Farm PS Intensive English Lang Centre",
  "Ingle Farm Primary School",
  "Ingle Farm Primary School OSHC/Vac",
  "Inner North East Adelaide YMCA OSHC/Vac",
  "Inner North Learning Together @ Home",
  "Inner South West Learning Together @ Home",
  "Instrumental Music - Klemzig",
  "Instrumental Music - Morphett Vale Office",
  "Instrumental Music APY Lands",
  "Instrumental Music Belair Primary School",
  "Instrumental Music Berri Primary School",
  "Instrumental Music Brighton Secondary School",
  "Instrumental Music Hendon Primary School",
  "Instrumental Music Kadina Memorial School",
  "Instrumental Music Magill School",
  "Instrumental Music Marryatville High School",
  "Instrumental Music Modbury High School",
  "Instrumental Music Mount Gambier North PS",
  "Instrumental Music Murray Bridge High School",
  "Instrumental Music Nuriootpa High School",
  "Instrumental Music Open Access College",
  "Instrumental Music Playford International College",
  "Instrumental Music Port Lincoln High School",
  "Instrumental Music Reynella East College",
  "Instrumental Music Seaford Secondary College",
  "Instrumental Music Whyalla High School",
  "Instrumental Music Woodville High School",
  "Investigator College - Goolwa Campus",
  "Investigator College - Victor Harbor Campus",
  "Islamic Information Centre of SA Inc - Arabic Schl",
  "J B Cleland Kindergarten Inc",
  "Jamestown Community Children's Centre",
  "Jamestown Community Children's Centre",
  "Jamestown Community School",
  "Jean Horan Kindergarten",
  "Jervois Primary School",
  "Jescott Montessori Preschool",
  "John Hartley School (B-7)",
  "John Hartley School B-7 Children's Centre",
  "John Hartley School Children's Centre Occ Care",
  "John Hartley School Happy Haven OSHC",
  "John Pirie Secondary School",
  "KICE - Parndana Campus Preschool",
  "Kadina Education Director Office",
  "Kadina Memorial School",
  "Kadina Preschool Centre",
  "Kalangadoo Kindergarten",
  "Kalangadoo Kindergarten Occasional Care",
  "Kalangadoo Kindergarten Occasional Care",
  "Kalangadoo Primary School",
  "Kalaya Children's Centre",
  "Kangarilla Primary School",
  "Kangarilla/Clarendon OSHC - Kangarilla",
  "Kangaroo Inn Area School",
  "Kangaroo Inn Preschool",
  "Kangaroo Island Children's Services - Kingscote",
  "Kangaroo Island Community Education",
  "Kapunda High School",
  "Kapunda Kindergarten",
  "Kapunda Kindergarten Occ Care",
  "Kapunda Kindergarten Occ Care",
  "Kapunda PS OSHC/Vac",
  "Kapunda Primary School",
  "Karcultaby Area School",
  "Karcultaby Preschool",
  "Karkoo Playcentre",
  "Karoonda Area School",
  "Karoonda Child Parent Centre",
  "Karrendi Primary School",
  "Kathleen Mellor Preschool Kindergarten",
  "Kaurna Plains Children's Centre",
  "Kaurna Plains School",
  "Keith Area School",
  "Keith War Memorial Community Centre Kgtn",
  "Keithcot Farm Children's Centre",
  "Keithcot Farm Primary School",
  "Keithcot Kings OSHC",
  "Keller Road Primary School",
  "Kenmore Park Anangu School",
  "Kensington Gardens Preschool",
  "Kenyan Association of SA (KASA) Swahili School",
  "Kersbrook Primary School",
  "Kersbrook Primary School Happy Haven OSHC",
  "Keyneton Primary School",
  "Kidman Park PS OSHClub",
  "Kidman Park Primary School",
  "Kildare College",
  "Kilkenny PS Intensive English Lang Centre",
  "Kilkenny PS Intensive English Lang Centre",
  "Kilkenny Preschool",
  "Kilkenny Primary School",
  "Kilkenny Primary School OSHC",
  "Kilparrin Early Learning Centre",
  "Kilparrin Teaching & Assessment School & Services",
  "Kimba Area School",
  "Kimba Community Kindergarten",
  "Kimba Community Kindergarten Occ Care",
  "King's Baptist Grammar School",
  "Kingscote Campus",
  "Kingscote OSHC/Vac KI CC",
  "Kingston Community School",
  "Kingston Early Learning Centre",
  "Kingston Rural Care",
  "Kingston-on-Murray Primary School",
  "Kirinari Kindergarten",
  "Kirton Point Children's Centre",
  "Kirton Point Children's Centre Occasional Care",
  "Kirton Point Primary School",
  "Klemzig Centre for Hearing Impaired",
  "Klemzig Kindergarten",
  "Klemzig Kindergarten Occ Care",
  "Klemzig Primary School",
  "Klemzig Primary School Happy Haven OSHC",
  "Klemzig Primary School Happy Haven OSHC",
  "Kongorong Child Parent Centre",
  "Kongorong Primary School",
  "Koolunga Primary School",
  "Koonibba Aboriginal School",
  "Koonibba Child Parent Centre",
  "Korean School of Adelaide",
  "Kurralta Park Comm Kindergarten Occ Care",
  "Kurralta Park Community Kindergarten",
  "Lac-Viet Vietnamese School",
  "Lady Gowrie Child Care - Thebarton Campus",
  "Lake Wangary Preschool Centre",
  "Lake Wangary School",
  "Lake Windemere B-7 School",
  "Lake Windemere B-7 School CC Occ Care",
  "Lake Windemere B-7 School Children's Centre",
  "Lameroo & District Kindergarten Occasional Care",
  "Lameroo Regional Community School",
  "Lameroo and District Kindergarten",
  "Langhorne Creek Playcentre",
  "Lantana Kindergarten",
  "Lantana Kindergarten Occ Care",
  "Largs Bay Primary School OSHC/Vac",
  "Largs Bay School",
  "Largs North Kindergarten",
  "Latvian School of Adelaide Inc",
  "Laura Preschool Centre",
  "Laura Primary School",
  "Laura Primary School Happy Haven OSHC",
  "Laura Rural Care",
  "Le Fevre High School",
  "Le Fevre Kindergarten Inc",
  "Le Fevre Peninsula PS - Nth Haven Schools OSHC",
  "Le Fevre Peninsula Primary School",
  "Learning at the Gallery",
  "Leigh Creek Area School",
  "Leigh Creek Kindergarten",
  "Lenswood Primary School",
  "Liberman Kindergarten",
  "Light Pass Primary School",
  "Lilliput Village Montessori",
  "Limestone Coast Learning Together @ Home",
  "Lincoln Gardens Primary School",
  "Linden Park Primary School",
  "Linden Park Primary School OSHC/Vac",
  "Littlehampton Preschool",
  "Littlehampton Primary School",
  "Littlehampton Primary School OSHC/Vac",
  "Lobethal Community Kindergarten",
  "Lobethal Lutheran School",
  "Lobethal PS OSHC/Vac",
  "Lobethal Primary School",
  "Lock Area School",
  "Lock Early Learning Centre",
  "Lock Occasional Care",
  "Lockleys Children's Centre",
  "Lockleys Children's Centre Occasional Care",
  "Lockleys North Primary School",
  "Lockleys North Primary School OSHC/Vac",
  "Lockleys PS OSHC",
  "Lockleys Primary School",
  "Long Street Primary School",
  "Lonsdale Heights Preschool",
  "Lonsdale Heights Primary School",
  "Loreto College",
  "Loreto College Junior School OSHC/Vac",
  "Lower Yorke Peninsula Learning Together@Home",
  "Loxton District Outside School Hours Care Program",
  "Loxton High School",
  "Loxton Lutheran School",
  "Loxton Lutheran School Early Learning Centre",
  "Loxton North Kindergarten",
  "Loxton North Preschool Occasional Care",
  "Loxton North School",
  "Loxton Preschool Centre Inc",
  "Loxton Primary School",
  "Lucindale Area School",
  "Lucy Morice Kindergarten",
  "Lucy Morice Kindergarten Occ Care",
  "Lucy Morice Kindergarten Occ Care",
  "Lyndoch PS OSHC/Vac",
  "Lyndoch PS OSHC/Vac",
  "Lyndoch Primary School",
  "Lyndoch Valley Family Centre",
  "Lyndoch Valley Occasional Care",
  "Macclesfield PS Happy Haven OSHC",
  "Macclesfield Primary School",
  "Madge Sexton Kindergarten",
  "Madison Park Kindergarten",
  "Madison Park Primary School OSHC/Vac",
  "Madison Park School",
  "Magill Kindergarten",
  "Magill Kindergarten",
  "Magill School",
  "Magill School OSHC/Vac",
  "Maitland Family Day Care",
  "Maitland Lutheran School",
  "Mallala Preschool",
  "Mallala Preschool Occasional Care",
  "Mallala Primary School",
  "Mana Telugu Badi",
  "Mannum Community College",
  "Mannum Kindergarten",
  "Mannum Rural Care",
  "Manoora Primary School",
  "Manor Farm Kindergarten",
  "Maple Leaf School - University of South Australia",
  "Marcellin Technical College",
  "Marden Senior College",
  "Margaret Ives Community Children's Centre",
  "Margaret Lohmeyer Kindergarten",
  "Marion Bay Playcentre",
  "Marion Primary School",
  "Marion Primary School OSHC",
  "Mark Oliphant College (B-12)",
  "Mark Oliphant College (B-12) OSHC - Camp Australia",
  "Mark Oliphant College CPC Occasional Care",
  "Mark Oliphant College Children's Centre",
  "Marree Aboriginal School",
  "Marree Preschool",
  "Marryatville High School",
  "Marryatville PS - The Red Shed OSHC/Vac",
  "Marryatville Primary School",
  "Mary Bywaters Memorial Kindergarten",
  "Mary MacKillop College",
  "Mary MacKillop Memorial School",
  "Mawson Lakes Preschool",
  "Mawson Lakes School",
  "Mawson Lakes School OSHC/Vac",
  "McArthur Park Kindergarten",
  "McDonald Park School",
  "McKay Children's Centre",
  "McKellar Stewart Kindergarten",
  "McLaren Flat Primary School",
  "McLaren Flat Primary School OSHC",
  "McLaren Vale - Willunga PS OSHC",
  "McLaren Vale Primary School",
  "McRitchie Crescent Children's Services Centre",
  "McRitchie Crescent Occasional Care",
  "Meadows & Districts Kindergarten",
  "Meadows & Districts Kindergarten Occasional Care",
  "Meadows PS OSHC/Vac",
  "Meadows Primary School",
  "Melaleuca Park Kindergarten",
  "Melaleuca Park Kindergarten Occasional Care",
  "Melaleuca Park Primary School",
  "Melrose Playcentre",
  "Melrose Primary School",
  "Melrose Rural Care",
  "Memorial Oval Primary School",
  "Memorial Oval Primary School OSHC/Vac",
  "Meningie Area School",
  "Meningie Preschool",
  "Mercedes College",
  "Mercedes College OSHC/Vac",
  "Michelle DeGaris Memorial Kindergarten",
  "Michelle DeGaris Memorial Kindergarten Occ Care",
  "Mid North Christian College",
  "Mid North Education Centre",
  "Middle Campus - Norwood Morialta",
  "Midway Road Community House Vac",
  "Migration Museum - Education Service",
  "Mil Lel Primary School",
  "Milang Playcentre",
  "Millicent High School",
  "Millicent North Kindergarten",
  "Millicent North Kindergarten Occasional Care",
  "Millicent North Primary School",
  "Miltaburra Area School",
  "Miltaburra Children's Centre",
  "Mimili Anangu School",
  "Mimili Child Parent Centre",
  "Minda Incorporated Vacation Care",
  "Minlaton District ELC Occ Care",
  "Minlaton District Early Learning Ctr",
  "Minlaton District School",
  "Mitcham Girls High School",
  "Mitcham OSHClub Kingswood",
  "Mitcham Preschool Centre",
  "Mitcham Primary School",
  "Mitcham Village Kindergarten",
  "Mitchell Park Kindergarten",
  "Mitchell Park Kindergarten Occ Care",
  "Moana Kindergarten",
  "Moana Primary School",
  "Modbury CPC-7 OSHC/Vac",
  "Modbury Child Parent Centre",
  "Modbury High School",
  "Modbury Kindergarten",
  "Modbury North Kindergarten",
  "Modbury School Preschool to Year 7",
  "Modbury South PS Happy Haven OSHC",
  "Modbury South Preschool",
  "Modbury South Primary School",
  "Modbury Special School",
  "Modbury Special School OSHC/Vac",
  "Modbury West School",
  "Modbury West School OSHC/Vac",
  "Monash Kindergarten",
  "Monash Kindergarten",
  "Monash Primary School",
  "Montessori Kids",
  "Moonta Area School",
  "Moonta Area School OSHC",
  "Moonta Kindergarten",
  "Moonta Kindergarten Occasional Care",
  "Moorak Child Parent Centre",
  "Moorak Primary School",
  "Moorook Playcentre",
  "Moorook Primary School",
  "Morgan Kindergarten",
  "Morgan Kindergarten",
  "Morgan Primary School",
  "Morphett Vale East Kindergarten",
  "Morphett Vale East PS OSHC/Vac",
  "Morphett Vale East School R-7",
  "Morphett Vale Primary School",
  "Morphett Vale Primary School - Occasional Care",
  "Morphett Vale Primary School - Preschool",
  "Morphett Vale Primary School OSHC",
  "Mount Barker 1 Education Director Office",
  "Mount Barker 2 Education Director Office",
  "Mount Barker High School",
  "Mount Barker Kindergarten Inc",
  "Mount Barker Primary School",
  "Mount Barker Primary School Combined OSHC/Vac",
  "Mount Barker South PS OSHC/Vac",
  "Mount Barker South Primary School",
  "Mount Barker Waldorf School",
  "Mount Barker Waldorf School OSHC Camp Australia",
  "Mount Burr Child Parent Centre",
  "Mount Burr Primary School",
  "Mount Carmel College",
  "Mount Compass Area School",
  "Mount Compass Area School OSHC",
  "Mount Compass Area School OSHC",
  "Mount Compass Preschool Centre",
  "Mount Gambier 1 Education Director Office",
  "Mount Gambier 2 Educ Director Office",
  "Mount Gambier Children's Centre",
  "Mount Gambier Children's Centre Occasional Care",
  "Mount Gambier Family Day Care",
  "Mount Gambier High School",
  "Mount Gambier High School New Arrivals Program",
  "Mount Gambier North Primary School",
  "Mount Gambier Nth PS Intensive English Lang Centre",
  "Mount Gambier Nth PS Intensive English Lang Centre",
  "Mount Pleasant & District Kindergarten",
  "Mount Pleasant PS OSHC",
  "Mount Pleasant Primary School",
  "Mt Barker Waldorf School Preschool",
  "Mt Pleasant & District Kindergarten Occ Care",
  "Muirden Senior College",
  "Mulga Street Primary School",
  "Mundulla Primary School",
  "Munno Para Kindergarten",
  "Munno Para PS Happy Haven OSHC",
  "Munno Para Primary School",
  "Murputja Anangu School",
  "Murputja Child Parent Centre",
  "Murray Bridge 1 Education Director Office",
  "Murray Bridge 2 Education Director Office",
  "Murray Bridge Family Day Care",
  "Murray Bridge High School",
  "Murray Bridge Learning Together @ Home",
  "Murray Bridge North OSHC/Vac",
  "Murray Bridge North School R-7",
  "Murray Bridge Preschool Kindergarten",
  "Murray Bridge South Primary School",
  "Murray Bridge Special School",
  "Myanmar Ethnic School",
  "Mylor Primary School",
  "Mylor Primary School OSHC/Vac",
  "Mypolonga Primary School",
  "Myponga Playcentre",
  "Myponga Primary School",
  "Myponga Primary School OSHC",
  "NACYS OSHC",
  "Nailsworth Primary School",
  "Nailsworth Primary School OSHC/Vac",
  "Nairne Child Parent Centre",
  "Nairne PS OSHC/Vac",
  "Nairne Primary School",
  "Nangwarry Child Parent Centre",
  "Nangwarry Primary School",
  "Napperby Preschool",
  "Napperby Primary School",
  "Naracoorte High School",
  "Naracoorte North Kindergarten",
  "Naracoorte PS OSHC/Vac",
  "Naracoorte Primary School",
  "Naracoorte South Primary School",
  "Nature Education Centre",
  "Navigator College",
  "Nazareth Catholic College",
  "Nazareth Catholic College Findon Primary Campus",
  "Nazareth Catholic College Flinders Sec Campus",
  "Nazareth Catholic College OSHC - Findon",
  "Neta Kranz Children's Centre",
  "Netherby Kindergarten",
  "Netley Kindergarten",
  "New Era Persian Language & Culture School Inc",
  "Newbery Park Primary School",
  "Newland Park Kindergarten",
  "Ngura Yadurirn Children and Family Centre",
  "Ngura Yadurirn Occasional Care",
  "Nicolson Avenue Primary School",
  "Noarlunga 1 Education Director Office",
  "Noarlunga 2 Education Director Office",
  "Noarlunga 3 Education Director Office",
  "Noarlunga 4 Education Director Office",
  "Noarlunga Downs Preschool",
  "Noarlunga Downs Primary School",
  "Noarlunga Downs Primary School OSHC",
  "Noarlunga Family Day Care",
  "Norrie Stuart Childhood Services Centre",
  "Norrie Stuart Occasional Care",
  "North Adelaide Primary School",
  "North Adelaide Primary School OSHC/ Vac",
  "North East Secondary Community Learning Centre",
  "North Haven Kindergarten",
  "North Haven Kindergarten Occasional Care",
  "North Haven School",
  "North Haven School OSHC/ Vac",
  "North Ingle Preschool",
  "North Ingle School",
  "North Ingle School OSHC Camp Aust",
  "North Metro Family Day Care Scheme",
  "Northern Adelaide Learning Together @ Home",
  "Northern Adelaide Senior College",
  "Northfield PS OSHC Camp Australia",
  "Northfield Preschool",
  "Northfield Primary School",
  "Northside Family Day Care Scheme",
  "Norton Summit Primary School",
  "Norton Summit Primary School OSHC",
  "Norwood Morialta High School",
  "Norwood Primary School",
  "Norwood Primary School OSHC/Vac",
  "Nuriootpa - Barossa OSHC/Vac",
  "Nuriootpa Community Children's Centre",
  "Nuriootpa High School",
  "Nuriootpa Primary School",
  "O'Halloran Hill Kindergarten",
  "O'Sullivan Beach Children's Centre",
  "O'Sullivan Beach PS OSHC/Vac",
  "O'Sullivan Beach Primary School",
  "Oak Valley Aboriginal School",
  "Oak Valley Aboriginal School CPC",
  "Oakbank Area School",
  "Oakbank Kindergarten",
  "Oaklands Estate Kindergarten",
  "Ocean View College Children's Centre",
  "Ocean View College OSHC/Vac",
  "Ocean View P-12 College",
  "Old Noarlunga Primary School",
  "One Tree Hill Preschool",
  "One Tree Hill Primary School",
  "OneSchool Global",
  "OneSchool Global - Mount Gambier Campus",
  "Oodnadatta Aboriginal School",
  "Oodnadatta Child Parent Centre",
  "Open Access College",
  "Open Access College Marden Campus",
  "Open Access College Port Augusta School of the Air",
  "Orroroo Area School",
  "Orroroo Kindergarten",
  "Orroroo Occasional Care",
  "Our Lady Queen of Peace School",
  "Our Lady Queen of Peace School Combined OSHC",
  "Our Lady of Grace OSHC",
  "Our Lady of Grace School",
  "Our Lady of Hope Greenwith OSHC/Vac",
  "Our Lady of Hope School",
  "Our Lady of La Vang School",
  "Our Lady of Mount Carmel PS OSHC/Vac",
  "Our Lady of Mt Carmel Parish School",
  "Our Lady of the River School",
  "Our Lady of the Sacred Heart College",
  "Our Lady of the Visitation OSHC/Vac",
  "Our Lady of the Visitation Preschool - Taperoo",
  "Our Lady of the Visitation School",
  "Our Saviour Lutheran School",
  "Our Saviour Lutheran School OSHC/Vac",
  "Outreach Education",
  "Overseas Chinese Association of SA Inc",
  "Owen Playcentre",
  "Owen Primary School",
  "Padthaway Child Parent Centre",
  "Padthaway Primary School",
  "Palmer Primary School",
  "Para Hills 1 Education Director Office",
  "Para Hills 2 Education Director Office",
  "Para Hills 3 Education Director Office",
  "Para Hills 4 Education Director Office",
  "Para Hills Child Parent Centre",
  "Para Hills High School",
  "Para Hills PS OSHC",
  "Para Hills School P-7",
  "Para Hills West PS OSHC/Vac",
  "Para Hills West Preschool",
  "Para Hills West Primary School",
  "Para Vista Child Parent Centre",
  "Para Vista Primary School",
  "Para Vista Primary School OSHC/Vac",
  "Paracombe Primary School",
  "Paradise Community Church OSHC/Vac",
  "Paradise Kindergarten",
  "Paradise Primary School",
  "Paradise Primary School OSHC/Vac",
  "Parafield Gardens Children's Centre",
  "Parafield Gardens Children's Ctr Occasional Care",
  "Parafield Gardens High School",
  "Parafield Gardens Primary School OSHC/Vac",
  "Parafield Gardens R-7 School",
  "Paralowie Kindergarten",
  "Paralowie School",
  "Paringa Park PS OSHC Camp Australia",
  "Paringa Park Primary School",
  "Parish of St Raphael Nicholas & Irene Greek School",
  "Park Terrace Kindergarten",
  "Parks Children's Centre",
  "Parks Children's Centre Occasional Care",
  "Parks Community Centre Vacation Care",
  "Parkside OSHC Centre",
  "Parkside Primary School",
  "Parliament House - Education Service",
  "Parndana Campus",
  "Pasadena Kindergarten",
  "Pedare Christian College",
  "Pembroke School",
  "Pembroke School Camp Australia OSHC/Vac",
  "Pembroke School Early Learning Centre",
  "Penneshaw Campus",
  "Penneshaw Kindergarten",
  "Pennington Children's Centre Occasional Care",
  "Pennington Children's Centre for Early Childhood",
  "Pennington R-7 School Intensive English Lang Cent",
  "Pennington R-7 School Intensive English Lang Cent",
  "Pennington School R-7",
  "Penola High School",
  "Penola OSHC",
  "Penola Primary School",
  "Penong Preschool",
  "Penong Preschool",
  "Penong Primary School",
  "Peterborough Community Preschool",
  "Peterborough High School",
  "Peterborough Primary School",
  "Peterborough Primary School - Yunta Campus",
  "Peterborough Rural Care",
  "Pilgrim School",
  "Pimpala Kindergarten",
  "Pimpala PS OSHC/Vac",
  "Pimpala Primary School",
  "Pinnacle College",
  "Pinnacle College - Elizabeth East Campus",
  "Pinnacle College - Golden Grove Campus",
  "Pinnaroo Kindergarten",
  "Pinnaroo Primary School",
  "Pipalyatjara Anangu Preschool",
  "Pipalyatjara Anangu School",
  "Pirie Education Director Office",
  "Playford College",
  "Playford International College",
  "Playford McAuley Campus OSHC/Vac",
  "Playford Primary School",
  "Plympton International College",
  "Plympton Primary School",
  "Plympton Primary School OSHC/Vac",
  "Plympton South Kindergarten",
  "Point Pearce Child Parent Centre",
  "Point Pearce Occasional Care",
  "Point Pearce Playcentre",
  "Polish Language School 'Adam Mickiewicz'",
  "Polish Language School 'Tadeusz Kosciuszko' Inc",
  "Poonindie Community Learning Centre",
  "Poonindie Early Childhood Centre",
  "Poonindie Early Childhood Centre Occasional Care",
  "Pooraka Community Kindergarten",
  "Pooraka Primary School",
  "Pooraka Primary School OSHC/Vac",
  "Port Augusta 1 Education Director Office",
  "Port Augusta 2 Whyalla Education Office",
  "Port Augusta Children's Centre",
  "Port Augusta Children's Centre Occasional Care",
  "Port Augusta Learning Together@Home",
  "Port Augusta Secondary School",
  "Port Augusta Special School",
  "Port Augusta West Childhood Services Centre",
  "Port Augusta West PS OSHC/Vac",
  "Port Augusta West Primary School",
  "Port Broughton Area School",
  "Port Broughton Kindergarten",
  "Port Broughton Kindergarten Occasional Care",
  "Port Elliot Kindergarten",
  "Port Elliot PS OSHC Camp Australia",
  "Port Elliot Primary School",
  "Port Lincoln 1 Education Director Office",
  "Port Lincoln 2 Education Director Office",
  "Port Lincoln Children's Centre",
  "Port Lincoln Family Day Care",
  "Port Lincoln High School",
  "Port Lincoln Junior Primary School",
  "Port Lincoln Learning Together @ Home",
  "Port Lincoln Out of School Hours Care - YMCA",
  "Port Lincoln Primary School",
  "Port Lincoln Special School",
  "Port Neill Playcentre",
  "Port Neill Primary School",
  "Port Noarlunga PS OSHC/Vac",
  "Port Noarlunga Primary School",
  "Port Pirie Community Kindergarten",
  "Port Pirie Family Day Care",
  "Port Pirie Learning Together @ Home",
  "Port Pirie West Children's Centre",
  "Port Pirie West Primary School",
  "Port Wakefield Kindergarten",
  "Port Wakefield Kindergarten Occasional Care",
  "Port Wakefield Primary School",
  "Portside Christian College",
  "Portside Christian College OSHC",
  "Portuguese Ethnic School of SA Inc",
  "Precious Cargo Blackwood",
  "Prescott College",
  "Prescott College Southern",
  "Prescott College Southern OSHC/Vac",
  "Prescott Primary Northern",
  "Primary Schools Music Festival",
  "Prince Alfred College",
  "Prince Alfred College OSHC",
  "Prospect Kindergarten",
  "Prospect Kindergarten Occ Care",
  "Prospect North Primary School",
  "Prospect North Primary School OSHC/Vac",
  "Prospect Primary School",
  "Prospect Primary School OSHC/Vac",
  "Pukatja Family Wellbeing Centre",
  "Pulteney Grammar School",
  "Pulteney Grammar School OSHC/Vac",
  "Punjabi Gurmukhi School Adelaide",
  "Punjabi School - Adelaide",
  "Quorn Area School",
  "Quorn Kindergarten",
  "Quorn Kindergarten Occasional Care",
  "RICE Project Preschool",
  "Ramco Primary School",
  "Rapid Bay Primary School",
  "Raukkan Aboriginal School",
  "Raukkan Child Parent Centre",
  "Redeemer Lutheran School",
  "Redeemer Lutheran School Camp Australia OSHC",
  "Redwood Park Preschool",
  "Redwood Park Primary School",
  "Redwood Park Primary School OSHC/Vac",
  "Reidy Park PS - Mt Gambier OSHC",
  "Reidy Park Primary School",
  "Rendelsham Preschool",
  "Rendelsham Primary School",
  "Renmark Children's Centre",
  "Renmark Greek Orthodox Afternoon School",
  "Renmark High School",
  "Renmark North Preschool",
  "Renmark North Primary School",
  "Renmark OSHC/Vac",
  "Renmark Primary School",
  "Renmark West Preschool",
  "Renmark West Primary School",
  "Renown Park Children's Centre Occasional Care",
  "Renown Park Children's Centre for Early Childhood",
  "Renown Park Children's Centre for Early Childhood",
  "Respite Care Program",
  "Reynella East College",
  "Reynella East Preschool",
  "Reynella East Primary School Life Leisure OSHC/Vac",
  "Reynella Kindergarten",
  "Reynella Primary School",
  "Reynella Primary School YMCA OSHC",
  "Reynella South Preschool",
  "Reynella South Preschool",
  "Reynella South Primary School",
  "Reynella South Primary School OSHC/Vac",
  "Richmond PS Intensive English Lang Centre",
  "Richmond PS Intensive English Lang Centre",
  "Richmond Primary School",
  "Richmond Primary School OSHC/Vac",
  "Ridgehaven Child Parent Centre",
  "Ridgehaven Primary School",
  "Ridgehaven Primary School OSHC/Vac",
  "Risdon Park Primary School",
  "Risdon Park South Kindergarten",
  "Riverdale OSHC/Vac",
  "Riverdale Primary School",
  "Rivergum College",
  "Riverland Special School",
  "Riverton Kindergarten",
  "Riverton Primary School",
  "Riverton Rural Care",
  "Riverton and District High School",
  "Riverview Preschool Centre",
  "Riverview Preschool Centre Occ Care",
  "Robe Primary School",
  "Robe Soldiers Memorial Kindergarten",
  "Robertstown Preschool",
  "Robertstown Primary School",
  "Roma Mitchell Secondary College",
  "Rosary School",
  "Rose Park Preschool",
  "Rose Park Primary School",
  "Rose Park Primary School OSHC/Vac Camp Australia",
  "Rosemont House Montessori Preschool",
  "Roseworthy Primary School",
  "Roseworthy Primary School OSHC Camp Australia",
  "Rostrevor College",
  "Rostrevor College Camp Aust OSHC/Vac",
  "Rostrevor Kindergarten",
  "Roxby Downs Area School",
  "Roxby Downs Area School OSHC/Vac",
  "Roxby Downs Kindergarten",
  "Russian Molokan School Inc",
  "SA Law Courts - Education Service",
  "SA Maritime Museum - Education Service",
  "SA Museum - Education Service",
  "SA School for Vision Impaired",
  "SA Water - Education Service",
  "SEDA College",
  "SEDA College - West Adelaide Football Club Campus",
  "Sacred Heart College",
  "Sacred Heart College Middle School",
  "Saddleworth Early Learning Centre",
  "Saddleworth Primary School",
  "Saint David's Parish School",
  "Saint Francis de Sales College",
  "Saint George College:Junior School",
  "Saint Ignatius' College",
  "Saint Ignatius' College:Junior School",
  "Saint John's Grammar School:Junior School",
  "Saint John's Lutheran School - Eudunda",
  "Saint Joseph's Memorial School:Early Years",
  "Saint Margaret Mary's Preschool Centre",
  "Saint Margaret Mary's School",
  "Saint Martins Lutheran College",
  "Saint Michael's College",
  "Saint Michael's College Junior School",
  "Saint Peter's Woodlands Grammar School",
  "Saint Spyridon College",
  "Saint Spyridon College OSHC - Camp Australia",
  "Salisbury Downs Preschool Centre",
  "Salisbury Downs Primary School",
  "Salisbury East High School",
  "Salisbury Heights PS OSHC/Vac",
  "Salisbury Heights PS OSHC/Vac",
  "Salisbury Heights Preschool",
  "Salisbury Heights Primary School",
  "Salisbury High School",
  "Salisbury Kindergarten",
  "Salisbury Lutheran Kindergarten",
  "Salisbury North R-7 Intensive English Lang Centre",
  "Salisbury North R-7 Intensive English Lang Centre",
  "Salisbury North R-7 School",
  "Salisbury North School OSHC/Vac",
  "Salisbury Park Kindergarten",
  "Salisbury Park Primary School",
  "Salisbury Park Primary School OSHC",
  "Salisbury Primary School",
  "Salisbury Primary School OSHC/Vac",
  "Samaritan College",
  "Samaritan College - Saint John's Campus",
  "Samaritan College - St Teresa's Campus",
  "Samaritan College OSHC/Vac",
  "Samaritan:Our Lady Help of Christians Campus",
  "Sandy Creek Primary School",
  "Saraswati Community School",
  "Saraswati Community School",
  "Sarbath Khalsa Punjabi School",
  "School Sport Team",
  "School for the German Language Inc",
  "School of Languages",
  "School of Russian Language Inc",
  "School of St Nicholas Parish of Russian Orth Chrch",
  "School of the Nativity",
  "Scotch College",
  "Scotch College Camp Australia OSHC/Vac",
  "Scott Creek Primary School",
  "Seacliff Community Kindergarten",
  "Seacliff Primary School",
  "Seacliff Primary School OSHC/Vac",
  "Seaford District CS OSHC - All Saints",
  "Seaford Early Learning Centre 2",
  "Seaford K-7 A Birth to Year 7 Campus",
  "Seaford K-7 A Birth to Year 7 Campus Preschool",
  "Seaford Rise Kindergarten",
  "Seaford Rise Kindergarten Occasional Care",
  "Seaford Rise Primary School",
  "Seaford Rise Primary School OSHC/Vac - Camp Austra",
  "Seaford Secondary College",
  "Seaford Secondary College Disability Unit",
  "Seaton Children's Centre",
  "Seaton Children's Centre Occasional Care",
  "Seaton High School",
  "Seaton Park Primary School",
  "Seaview Christian College",
  "Seaview Downs Kindergarten",
  "Seaview Downs Primary School",
  "Seaview Downs Primary School OSHC/Vac",
  "Seaview High School",
  "Secondary School Sport SA",
  "Senior Campus - Norwood",
  "Serbian Ethnic School 'Saint Sava' Woodville Park",
  "Settlers Farm Campus Kindergarten",
  "Settlers Farm Campus R-7",
  "Settlers Farm Primary School OSHC/Vac",
  "Settlers Farm Primary School OSHC/Vac",
  "Seymour College",
  "Seymour College Camp Australia OSHC",
  "Sheidow Park Primary School",
  "Sheidow Park Primary School OSHC/Vac",
  "Sherpa Kids Lockleys",
  "Sherpa Kids Navigator College Port Lincoln",
  "Sherpa Kids One Tree Hill",
  "Sherpa Kids Prescott",
  "Sinhala Buddhist School",
  "Sir Thomas Playford Kindergarten",
  "Sir Thomas Playford Kindergarten Occ Care",
  "Slavic Baptist Church School of Russian Language",
  "Smithfield Plains Kindergarten",
  "Snowtown Primary School",
  "Snowtown School Based Preschool",
  "Solomontown Kindergarten",
  "Solomontown Primary School",
  "Somali Ethnic School of SA",
  "Somerton Park Kindergarten",
  "South Australian International School",
  "South Australian Tamil School",
  "South Downs Primary School",
  "South East Riverland Family Day Care Scheme",
  "Southern Area Polish School 'Ala I As'",
  "Southern Districts Family Day Care Scheme",
  "Southern Learning Centre",
  "Southern Montessori School",
  "Southern Montessori School Middle Years",
  "Southern Vales Christian College Aldinga OSHC",
  "Southern Vales Christian College Combined OSHC",
  "Southern Vales Christian College Morphett Vale",
  "Southern Vales Christian College:Aldinga Campus",
  "Southern Vales Family Day Care Scheme",
  "Spalding Playcentre",
  "Spalding Primary School",
  "Spanish School of  Language and Culture",
  "Special Education Resource Unit",
  "Specialised Assistance School for Youth",
  "Springbank Secondary College",
  "Springton Primary School",
  "Springton Primary School Happy Haven OSHC",
  "St Agnes Campus - Modbury South OSHC",
  "St Agnes Preschool",
  "St Agnes Primary School",
  "St Albert's Catholic School",
  "St Aloysius College",
  "St Aloysius College OSHC",
  "St Andrew's School",
  "St Andrews OSHC/Vac Camp Australia",
  "St Anthony's Catholic Primary School",
  "St Anthony's School",
  "St Anthony's School OSHC/Vac",
  "St Augustine's Parish School",
  "St Augustine's Parish School OSHC/Vac",
  "St Barbara's Parish School",
  "St Bernadette's OSHC Camp Australia Combined",
  "St Bernadette's School",
  "St Brigid's Catholic PS OSHC Evanston",
  "St Brigid's Catholic Primary School",
  "St Brigid's School",
  "St Brigid's School Out of School Hours Care",
  "St Catherine's - Mt Lofty Area OSHC",
  "St Catherine's School",
  "St Columba College",
  "St Columba College OSHC/Vac",
  "St Columba's Memorial School",
  "St David's Parish School Care",
  "St David's Parish School Care",
  "St Dimitrios Greek Orthodox Parish School of Salis",
  "St Dominic's Priory College",
  "St Francis School",
  "St Francis School OSHC - Lockleys",
  "St Francis Xavier Regional School OSHC/Vac",
  "St Francis Xavier's Regional Catholic School",
  "St Francis de Sales College OSHC Camp Australia",
  "St Francis of Assisi Camp Australia OSHC",
  "St Francis of Assisi School",
  "St Gabriel's School",
  "St Gabriel's School OSHC",
  "St George College",
  "St George College Early Learning Centre",
  "St Helen's Park Kindergarten",
  "St Ignatius Junior School OSHC",
  "St Jakobi Lutheran School",
  "St Jakobi Lutheran School OSHC/Vac",
  "St James' Park Kindergarten",
  "St James' School",
  "St John Bosco School",
  "St John Bosco School OSHC/Vac",
  "St John the Apostle Catholic School",
  "St John the Apostle Out of School Hours Care",
  "St John the Baptist Catholic School",
  "St John the Baptist Catholic School OSHC/Vac",
  "St John's Grammar Preschool",
  "St John's Grammar School",
  "St John's Grammar School OSHC/Vac",
  "St Joseph's Memorial Preschool - Norwood",
  "St Joseph's Memorial School",
  "St Joseph's Memorial School OSHC/Vac - Norwood",
  "St Joseph's PS OSHC - Kingswood",
  "St Joseph's Parish Primary School",
  "St Joseph's Preschool - Hectorville",
  "St Joseph's Preschool - Hindmarsh",
  "St Joseph's Preschool - Ottoway",
  "St Joseph's Preschool - Payneham",
  "St Joseph's School - Barmera",
  "St Joseph's School - Clare",
  "St Joseph's School - Hectorville",
  "St Joseph's School - Hindmarsh",
  "St Joseph's School - Kingswood",
  "St Joseph's School - Murray Bridge",
  "St Joseph's School - Ottoway",
  "St Joseph's School - Payneham",
  "St Joseph's School - Peterborough",
  "St Joseph's School - Port Lincoln",
  "St Joseph's School - Renmark",
  "St Joseph's School - Tranmere",
  "St Joseph's School Murray Bridge Camp Aust OSHC",
  "St Joseph's School OSHC Hindmarsh",
  "St Joseph's School OSHC Ottoway",
  "St Joseph's School OSHC Payneham",
  "St Joseph's School OSHC Tranmere",
  "St Joseph's School OSHC/Vac - Hectorville",
  "St Leonards Primary School",
  "St Leonards Primary School OSHC/Vac",
  "St Margaret Mary's OSHC",
  "St Margaret's Kindergarten - Woodville",
  "St Mark's College",
  "St Mark's College:Benedict Campus",
  "St Mark's Lutheran PS Camp Australia OSHC",
  "St Mark's Lutheran Primary School",
  "St Martin de Porres School",
  "St Martin de Porres School OSHC/Vac",
  "St Martin's Catholic Primary School",
  "St Martin's Kindergarten - Mount Gambier",
  "St Martin's OSHC/Vac - Greenacres",
  "St Mary & Anba Bishoy Egyptian Coptic School",
  "St Mary & Anba Bishoy Egyptian Coptic School",
  "St Mary MacKillop School",
  "St Mary MacKillop School OSHC/Vac",
  "St Mary Magdalene's OSHC",
  "St Mary Magdalene's School",
  "St Mary's College",
  "St Mary's College OSHC",
  "St Mary's Memorial School",
  "St Mary's Memorial School OSHC",
  "St Mary's Vietnamese School Inc",
  "St Michael's College Out of School Hours Care",
  "St Michael's Lutheran Primary School",
  "St Michael's Lutheran Primary School OSHC",
  "St Monica's Parish School",
  "St Monica's Parish School OSHC/Vac",
  "St Patrick's School",
  "St Patrick's School OSHC Camp Australia",
  "St Patrick's Special School",
  "St Patrick's Technical College Northern Adelaide",
  "St Paul Lutheran School",
  "St Paul Lutheran School OSHC",
  "St Paul's College",
  "St Peter's College",
  "St Peter's College Camp Australia OSHC",
  "St Peter's Collegiate Girls' School",
  "St Peter's Collegiate Girls' School OSHC",
  "St Peter's Woodlands Grammar School OSHC Camp Aust",
  "St Peters Lutheran School",
  "St Peters Lutheran School OSHC",
  "St Peters Woodlands Grammar School ELC",
  "St Philip's Preschool Kindergarten",
  "St Pius X OSHC/Vac",
  "St Pius X School",
  "St Raphael's Parish School OSHC",
  "St Raphael's School",
  "St Teresa's School - Brighton",
  "St Teresa's School OSHC/Vac",
  "St Therese Primary School",
  "St Therese Primary School OSHC SA Camp Australia",
  "St Thomas More School",
  "St Thomas More School OSHC/Vac",
  "St Thomas School OSHC Camp Australia",
  "St Thomas' Preschool",
  "St Thomas' School",
  "Stansbury Playcentre",
  "Stansbury Primary School",
  "Star of the Sea OSHC/Vac",
  "Star of the Sea School",
  "Stella Maris Parish School",
  "Stepping Stone Tanunda OSHC",
  "Stepping Stone Two Wells OSHC",
  "Stirling District Kindergarten",
  "Stirling East - Mt Lofty Area OSHC",
  "Stirling East Kindergarten",
  "Stirling East Primary School",
  "Stirling North Childhood Services Centre",
  "Stirling North Primary School",
  "Stirling North Primary School Happy Haven OSHC",
  "Stradbroke School",
  "Stradbroke School OSHC/Vac",
  "Strathalbyn Kindergarten",
  "Strathalbyn Kindergarten Occ Care",
  "Strathalbyn Kindergarten Outreach",
  "Streaky Bay Area School",
  "Streaky Bay Area School Vac Care",
  "Streaky Bay Children's Centre",
  "Streaky Bay Children's Centre Occ Care",
  "Stuart High School",
  "Sturt Street Children's Centre",
  "Sturt Street Community School",
  "Sturt Street Community School OSHC/Vac",
  "Sudanese Ethnic School of South Australia",
  "Sukulu na Bari",
  "Suneden Special School",
  "Sunrise Christian School",
  "Sunrise Christian School Fullarton OSHC",
  "Sunrise Christian School Marion",
  "Sunrise Christian School Marion OSHC",
  "Sunrise Christian School Morphett Vale",
  "Sunrise Christian School Morphett Vale OSHC",
  "Sunrise Christian School Naracoorte",
  "Sunrise Christian School Paradise",
  "Sunrise Christian School Paradise OSHC",
  "Sunrise Christian School Whyalla",
  "Sunrise Christian School Whyalla ELC",
  "Sunrise Christian School: Fullarton Campus",
  "Surrey Downs Kindergarten",
  "Surrey Downs Occasional Care",
  "Surrey Downs Primary School OSHC",
  "Surrey Downs R-7 School",
  "Suttontown Primary School",
  "Swallowcliffe Preschool",
  "Swallowcliffe School P-7",
  "Swan Reach Area School",
  "Swan Reach Occasional Care",
  "Swan Reach and Area Kindergarten",
  "Taikurrendi Child and Family Ctr Occasional Car",
  "Taikurrendi Children and Family Centre",
  "Tailem Bend Kindergarten",
  "Tailem Bend Kindergarten Rural Care",
  "Tailem Bend Primary School",
  "Tandanya National Aboriginal Cultural Inst",
  "Tantanoola Playcentre",
  "Tantanoola Playcentre",
  "Tantanoola Primary School",
  "Tanunda - Barossa OSHC",
  "Tanunda Kindergarten Association",
  "Tanunda Lutheran Out of School Hours Care",
  "Tanunda Lutheran School Inc",
  "Tanunda Primary School",
  "Targeted Learning Services",
  "Tarlee Primary School",
  "Tatachilla Lutheran College",
  "Tatachilla Lutheran College OSHC Camp Australia",
  "Tatar-Bashkurt Ethnic School Inc",
  "Tea Tree Gully Primary School",
  "Tea Tree Gully Primary School OSHC/Vac",
  "TeenZone - Novita Outside School Hours Care",
  "TeenZone South - Novita Outside School Hours Care",
  "Temple Christian College - Bethany Campus",
  "Temple Christian College - Mile End",
  "Tenison Woods - Mt Gambier OSHC",
  "Tenison Woods Catholic School",
  "Tenison Woods College",
  "The Ammanullah Khan Pashtu School",
  "The Arabic Language School",
  "The Briars Special Early Learning Centre",
  "The Early Years at Seymour",
  "The Grove Education Centre",
  "The Heights OSHC/Vac",
  "The Heights Preschool",
  "The Heights School",
  "The Hills Christian Community School",
  "The Hills Christian Community School - Oakbank",
  "The Hills Christian Community School - Verdun",
  "The Hills Christian Community School OSHC/Vac",
  "The Hills Montessori School",
  "The Hills Montessori School Inc",
  "The Hills Montessori School Inc OHSC",
  "The Independent Learning Centre",
  "The Lady George Kindergarten",
  "The Pines Child Parent Centre",
  "The Pines Primary School Happy Haven OSHC",
  "The Pines R-7 Intensive English Lang Centre",
  "The Pines R-7 Intensive English Lang Centre",
  "The Pines School",
  "The Romanian Ethnic School",
  "The Willows Children's Centre",
  "Thebarton Senior College",
  "Thebarton Senior College New Arrivals Program",
  "Thebarton Senior College New Arrivals Program",
  "Thiele Primary School",
  "Thomas More College",
  "Thorndon Park Kindergarten",
  "Thorndon Park PS OSHC/Vac",
  "Thorndon Park Primary School",
  "Tintinara Area School",
  "Tintinara Preschool",
  "Tinyeri Children's Centre",
  "Tinyeri Children's Ctr Occasional Care",
  "Torrens Valley Children's Centre",
  "Torrens Valley Christian School",
  "Torrens Valley Christian School OSHC CampAustralia",
  "Torrensville Preschool Centre",
  "Torrensville Preschool Centre Occ Care",
  "Torrensville Primary School",
  "Torrensville Primary School OSHC/Vac",
  "Transition Centres",
  "Transition Centres Daws",
  "Transition Centres Prospect",
  "Trinity College Blakeview OSHC/Vac",
  "Trinity College Blakeview School",
  "Trinity College Central Administration",
  "Trinity College Gawler OSHC/Vac",
  "Trinity College Gawler OSHC/Vac",
  "Trinity College Gawler River School",
  "Trinity College Gawler River School OSHC/Vac",
  "Trinity College North School",
  "Trinity College Senior School",
  "Trinity College South School",
  "Trinity Gardens Children's Centre",
  "Trinity Gardens Children's Centre Occasional Care",
  "Trinity Gardens Primary School OSHC",
  "Trinity Gardens Primary School OSHC",
  "Trinity Gardens School",
  "Trott Park Kindergarten",
  "Truro Playcentre",
  "Truro Primary School",
  "Tumby Bay Area School",
  "Tumby Bay Area School OSHC/Vac",
  "Tumby Bay Kindergarten",
  "Tumby Bay Rural Care",
  "Turkish Ethnic School",
  "Two Wells Community Children's Centre",
  "Two Wells Primary School",
  "Two Wells Primary School Vac Care",
  "Tyndale Christian College - Murray Bridge ELC",
  "Tyndale Christian College - Strathalbyn ELC",
  "Tyndale Christian College-Murray Bridge Incorporat",
  "Tyndale Christian School",
  "Tyndale Christian School - Strathalbyn",
  "Tyndale Christian School OSHC/Vac",
  "Tyndale Christian School Strathalbyn OSHC Camp Aus",
  "Uighur Language School",
  "Underdale High School",
  "Ungarra Playcentre",
  "Ungarra Primary School",
  "Unity College Inc",
  "Unity College OSHClub",
  "University Senior College at Adelaide University",
  "Unley High School",
  "Unley Kindergarten",
  "Unley Primary School",
  "Unley Primary School YMCA OSHC",
  "Upper Sturt PS OSHC",
  "Upper Sturt Primary School",
  "Uraidla Primary School",
  "Uraidla Primary School OSHC/Vac",
  "Uraidla Primary School OSHC/Vac",
  "Uraidla and Hills Community Kindergarten",
  "Urrbrae Agricultural High School",
  "Urrbrae Wetland - Education Service",
  "Vale Park Pre-school",
  "Vale Park Primary School",
  "Vale Park Primary School Happy Haven OSHC",
  "Valley View Kindergarten",
  "Valley View Secondary School",
  "Victor Harbor Community Kindergarten",
  "Victor Harbor Family Day Care",
  "Victor Harbor High School",
  "Victor Harbor R-7 OSHC Camp Australia",
  "Victor Harbor R-7 School",
  "Vietnamese Cultural Diversity Vac Care Program",
  "Vietnamese Ethnic School - SA Chapter",
  "Vineyard Lutheran School",
  "Virginia Preschool",
  "Virginia Primary School",
  "Virginia Primary School OSHC/Vac",
  "Waikerie Children's Centre Inc",
  "Waikerie High School",
  "Waikerie Lutheran Primary School",
  "Waikerie Primary School",
  "Waikerie Primary School OSHC/Vac",
  "Walford Anglican School for Girls",
  "Walford Early Learning Centre",
  "Walford Girls OSHClub",
  "Walkerville Primary School",
  "Walkerville Primary School OSHC/Vac",
  "Wallaroo Mines Primary School",
  "Wallaroo Preschool Centre",
  "Wallaroo Primary School",
  "Wallaroo Primary School OSHC/Vac",
  "Wandana Child Parent Centre",
  "Wandana Primary School",
  "Warooka Child Parent Centre",
  "Warooka Primary School",
  "Warradale Kindergarten",
  "Warradale Primary School",
  "Warradale Primary School OSHC/Vac",
  "Warradale Urban Camp School",
  "Warriappendi School",
  "Wasleys Playcentre",
  "Wasleys Primary School",
  "Watarru Anangu School",
  "Watervale Primary School",
  "Watervale Primary School OSHC",
  "Wattle Park Kindergarten",
  "West Beach Kindergarten",
  "West Beach Primary School",
  "West Beach Primary school OSHC/Vac",
  "West Country Family Day Care Scheme",
  "West Lakes Kgtn & Early Childhood Centre",
  "West Lakes Shore Kindergarten",
  "West Lakes Shore PS OSHC/Vac",
  "West Lakes Shore PS OSHC/Vac",
  "West Lakes Shore School R-7",
  "Westbourne Park PS OSHC Camp Australia",
  "Westbourne Park Primary School",
  "Westminster School",
  "Westminster School OSHC",
  "Westport Preschool",
  "Westport Preschool Occasional Care",
  "Westport Primary School",
  "Westport Primary School OSHC",
  "Whitefriars Parish School YMCA OSHC",
  "Whitefriars School",
  "Whyalla Family Day Care",
  "Whyalla High School",
  "Whyalla Learning Together @ Home",
  "Whyalla Special Education Centre",
  "Whyalla Stuart Campus R-7",
  "Whyalla Stuart ECC Kindergarten Occ Care",
  "Whyalla Stuart Early Childhood Centre Kgtn",
  "Whyalla Town PS OSHC/Vac",
  "Whyalla Town Primary School",
  "Wilderness School",
  "Wilderness School Early Learning Centre",
  "Wilderness School OSHC Camp Australia",
  "Williamstown Primary School",
  "Williamstown Primary School OSHC Camp Australia",
  "Williamstown School-Based Preschool",
  "Willow Close Preschool Centre Occasional Care",
  "Willsden Childhood Services Centre",
  "Willsden Childhood Services Centre Occasional Care",
  "Willsden Primary School",
  "Willunga High School",
  "Willunga PS OSHC/Vac",
  "Willunga Preschool",
  "Willunga Primary School",
  "Willunga Waldorf School",
  "Willunga Waldorf School Kindergarten",
  "Wilmington Preschool",
  "Wilmington Primary School",
  "Wiltja Boarding",
  "Wiltja Program",
  "Win Newby Kindergarten",
  "Wirreanda Secondary School",
  "Woodcroft College Inc",
  "Woodcroft College OSHC",
  "Woodcroft Heights Children's Centre",
  "Woodcroft Heights Children's Ctr Occasional Care",
  "Woodcroft PS Camp Australia OSHC/Vac",
  "Woodcroft Primary School",
  "Woodend Children's Centre",
  "Woodend Children's Centre Occ Care",
  "Woodend Primary School",
  "Woodend Primary School OSHC/Vac",
  "Woodside Preschool Playcentre",
  "Woodside Preschool Playcentre Occ Care",
  "Woodside Primary School",
  "Woodside Primary School OSHC/Vac",
  "Woodville Gardens School B-7",
  "Woodville Gardens School B-7 Children's Centre",
  "Woodville High School",
  "Woodville Primary School",
  "Woodville Primary School OSHC/Vac",
  "Woomera Area School",
  "Wudinna Area School",
  "Wudinna Family Day Care",
  "Wudinna Occasional Care",
  "Wudinna RSL Memorial Kindergarten",
  "Wynn Vale Community House Kindergarten",
  "Wynn Vale Preschool",
  "Wynn Vale R-7 School",
  "Wynn Vale School OSHC/Vac",
  "Xavier College",
  "XingGuang Chinese School Incorporated",
  "YMCA Craigmore South PS OSHC",
  "Yahl Primary School",
  "Yalata Anangu School",
  "Yalata Child Parent Centre",
  "Yankalilla Area School",
  "Yankalilla Area School OSHC/Vac",
  "Yankalilla Community Children's Centre",
  "Yorketown Area School",
  "Yorketown Community Children's Centre",
  "Yorketown Rural Care",
  "Youth Education Centre",
  "Youthinc Enterprise Academy",
  "Zion Preschool Centre",
  "il nido Children's Centre"
];