import React, { ReactNode } from 'react';
import * as S from './Section';
import * as R from './Row';
import * as C from './Control';
import * as Cu from './Custom';

import { Control, Row, Section, FormController, ControlValue, CustomNode, isControl, isSection, isRow, isCustom } from './Interface';


export interface ElementActionInterface {
  onClick?: () => void,
  onChange?: (value: ControlValue | ControlValue[]) => void,
  onValidate?: (valid: boolean, error?: string) => void,
}

export interface NodeActions {
  valueChange?: (value: ControlValue | ControlValue[], controller: FormController) => void,
}

export function cleanList<T>(items: (T | undefined)[]): T[] {
  return items.filter(i => i !== undefined) as T[];
}

export interface FormStateProcessor {

}

export interface FormStateLoader {
  id(parentId: string, id: string): string,
  get(parentId: string, id: string, fullId: string, defaultState: () => Section,  actions: ElementActionInterface): S.Props,
  get(parentId: string, id: string, fullId: string, defaultState: () => Row,  actions: ElementActionInterface): R.Props,
  get(parentId: string, id: string, fullId: string, defaultState: () => CustomNode,  actions: ElementActionInterface): Cu.Props,
  get(parentId: string, id: string, fullId: string, defaultState: () => Control,  actions: ElementActionInterface): C.Props,
  get(parentId: string, id: string, fullId: string, defaultState: () => Section | Row | Control | CustomNode,  actions: ElementActionInterface): S.Props | R.Props | C.Props | Cu.Props,
  get(parentId: string, id: string, fullId: string, defaultState: () => Section | Row | Control | CustomNode,  actions: ElementActionInterface): S.Props | R.Props | C.Props | Cu.Props,
  validate: boolean,
}

export const makeFormElement = (
  element: Control | Section | Row | CustomNode,
  index: number,
  parentId: string,
  processors: {
    value: (id: string, value: ControlValue | ControlValue[]) => void,
    valid: (id: string, valid: boolean, error?: string) => void,
    process: (id: string, target?: string) => void,
    controller: FormController,
  },
  state: FormStateLoader,
  outsideTargets: {
    header: (node: ReactNode) => void,
    footer: (node: ReactNode) => void,
  }
) => {
  let res: React.ReactElement<any, any>;
  const id = element.id || index.toString();
  const stateId = state.id(parentId, id);
  const actionInterface: ElementActionInterface = {};
  if (element.onClick) {
    const c = element.onClick;
    if (typeof c === 'function') {
      actionInterface.onClick = () => {
        c(processors.controller, { id: stateId, parentId });
      }
    } else {
      actionInterface.onClick = () => {
        processors.process(stateId, c !== undefined && c !== id? c: undefined);
      }
    }
  }
  const makeCopy = <T extends Section | Row | Control | CustomNode>(element: T): T => ({
    elementId: element.id,
    ...element,
    display: {
      ...element.display,
      size: {
        ...element.display?.size,
      },
      style: { ...element.display?.style },
      title: {
        ...element.display?.title,
        style: { ...element.display?.title?.style },
      },
    } 
  });
  if (isSection(element)) {
    const itemState = state.get(parentId, id, stateId, () => makeCopy(element), actionInterface);
    
    res = <S.Section key={stateId} id={stateId} {...itemState}>
      {cleanList(element.items.map((c, i) => makeFormElement(c, i, stateId, processors, state, outsideTargets)))}
    </S.Section>;
  } else if (isRow(element)) {
    const itemState = state.get(parentId, id, stateId, () => makeCopy(element), actionInterface);
    res = <R.Row key={stateId} id={stateId} {...itemState}>
      {cleanList(element.items.map((c, i) => makeFormElement(c, i, stateId, processors, state, outsideTargets)))}
    </R.Row>;
  } else if (isCustom(element)) {
    const itemState = state.get(parentId, id, stateId, () => makeCopy(element), actionInterface);
    res = <Cu.Custom key={stateId} id={stateId} {...itemState} />;
  } else {
    actionInterface.onChange = v => processors.value(stateId, v);
    actionInterface.onValidate = (v,e) => processors.valid(stateId, v, e);
    const itemState = state.get(parentId, id, stateId, () => {
      const r = makeCopy(element);
      if (r.valid === undefined) {
        r.valid = C.validate(r.type, r.value, r.optional === true);
      }
      return r;
    }, actionInterface);
    res = <C.Control {...itemState} id={stateId} key={stateId}/>;
  }
  if (element.display && element.display.section === 'header') {
    outsideTargets.header(res);
    return undefined;
  } else if (element.display && element.display.section === 'footer') {
    outsideTargets.footer(res);
    return undefined;
  }
  return res;
}