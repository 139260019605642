export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const login = (accessToken: string, refreshToken?: string, userConfiguration?: any[]) => ({
  type: LOGIN,
  accessToken,
  refreshToken,
  userConfiguration,
});

export const logout = () => ({
  type: LOGOUT,
})