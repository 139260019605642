import { Form as F, Col, Spinner, Button, Dropdown } from 'react-bootstrap';
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Control, ControlValue } from '../Interface';
import { ElementActionInterface } from '../Base';

export const validate = (type: Control['type'], value: ControlValue | ControlValue[], optional: boolean): boolean => {
  if (optional) {
    return true;
  }
  if (type === 'text' || type === 'password') {
    return value !== undefined && (value.toString()).length > 0;
  }
  if (!optional && value === undefined || value === null) {
    return false;
  }
  return true;
}

export const useValue = (control: StandardControlProps) => {
  const { value: inputValue, valid, processing, controller } = control;
  const [value, setValue] = useState(inputValue);
  useEffect(() => {
    if (inputValue !== value) {
      setValue(inputValue)
    }
  }, [inputValue]);

  const change = useCallback((v: any) => {
    if (value === v) {
      return;
    }
    const isValid = validate(control.type, v, control.optional === true);
    setValue(v);
    if (controller.onChange) {
      controller.onChange(v);
    }
    if (isValid !== valid && controller.onValidate) {
      controller.onValidate(isValid);
    }
  }, [value, valid, controller]);

  const click = useCallback(() => {
    if (!processing && controller.onClick) {
      controller.onClick();
    }
  }, [processing, controller]);

  return { value, change, click };
}

export const labelHelper = (value: string) => {
  let r: JSX.Element[] = [];
  value.split('\n').forEach((value,index) => {
    if (index > 0) {
      r.push(<br></br>);
    }
    let i = value.indexOf('{');
    let p = 0;
    while (i !== -1) {
      r.push(<>{value.slice(p, i)}</>);
      let i2 = value.indexOf('}', i);
      try {
        const v = JSON.parse(value.slice(i, i2+1));
        if (v.a) {
          r.push(<a href={v.a}>{v.v}</a>);
        }
      } catch (e) {
        r.push(<>{value.slice(i+1, i2)}</>);
      }
      p = i2+1;
      i = value.indexOf('{', i2);
    }
    r.push(<>{value.slice(p)}</>);
  });
  return r;
}

export type StandardControlProps = Exclude<Control, 'items' | 'onClick'> & {
  controller: ElementActionInterface,
  tmp: string,
  containerRef?: (node: HTMLElement | null) => void,
  inputRef?: (node: HTMLElement | null) => void,
}

export type OutputControlProps = {
  id: string | undefined,
  className: string,
  isInvalid?: boolean,
  isValid?: boolean,
};

export const standardControlProps = (control: StandardControlProps, actions: { change: (v: any) => void, click: () => void }): OutputControlProps => {
  const { display = {}} = control;
  const { click, change } = actions;
  const formProps = {
    id: control.id,
    autoComplete: control.tmp,
    type: control.type || 'label',
    isInvalid: false,
    placeholder: control.placeholder===undefined?undefined:control.placeholder.toString(),
    disabled: display.disabled === true || control.processing === true,
    className: 'control',
    onClick: (e: MouseEvent<any>) => {
      e.preventDefault();
      e.stopPropagation();
      click();
    },
    onChange: (event: React.ChangeEvent<any>) => {
      if (event.target === undefined){
        return;
      }
      change(event.target.value);
    },
  }
  if (display.disabled && display.plainText) {
    (formProps as any).plaintext = true;
  }
  
  if (display.validate && display.disabled !== true) {
    if (control.valid === false) {
      formProps.isInvalid = true;
    }
  }
  if (control.onClick !== undefined) {
    formProps.className += ' action';
  }
  return formProps;
}

export const useControlState = (control: StandardControlProps) => {
  const { value, change, click } = useValue(control);
  const outputProps = standardControlProps(control, { change, click });
  return { value, change, click, outputProps };
}

export const wrapFormControl = (props: StandardControlProps, formProps: OutputControlProps, label: string | undefined, value: any | undefined, control: JSX.Element | undefined) => {
  const { display = {} } = props;
  const input = control? control: <F.Control ref={props.inputRef} {...formProps} value={value !== undefined ? value.toString():''}></F.Control>;
  const body = display.inline? <Col sm="auto" style={{ display: 'flex', flexDirection: 'row', alignItems:'center'}}>
    {label===undefined? undefined: <label className="label">{label}</label>}
    <div style={{width:20}}></div>
    {input}
  </Col>:[label===undefined? undefined: <label className="label">{label}</label>,input];

  return <F.Group ref={props.containerRef} key={props.id} className={label===undefined? 'control-group': undefined} style={display.group?.style}>
    {body}
    {props.valid === false && display.validate === true ?<F.Control.Feedback type="invalid" style={{ display: 'initial'}}>
        {props.error !== undefined?props.error:'* required'}
      </F.Control.Feedback>: undefined}
    
  </F.Group>;
}