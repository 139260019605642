import React, { Component, MutableRefObject, useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Form as F } from 'react-bootstrap';
import * as Base from './Base';
import { ItemState, FormController, ControlValue, Control, Row, Section, isControl, isRow, isSection } from './Interface';


interface DataValue {
  id: string,
  value: ControlValue,
}

interface DataNode {
  children: (DataNode | DataValue)[],
}

export type SubmitHandler = (action: string, data: { [key: string]: { value: ControlValue, valid: boolean } }, controller: FormController) => Promise<undefined | { [key: string]: string }>;
export type ValueHandler = (id: string, value: any, fullId: string[], controller: FormController) => Promise<void>;

export interface Props {
  template: (Control | Row | Section)[],
  templateLockId?: number | string,
  valueChanged?: ValueHandler,
  submitHandler: SubmitHandler,

  headerElement?: HTMLDivElement,
  footerElement?: HTMLDivElement,
};

type StateNode = ItemState & { elementId?: string, parentId: string, fullId: string, id: string, path: string[], containerRef: HTMLElement | null | undefined, inputRef: HTMLElement | null | undefined };
const propagateValidity = (node: string, state: {[key: string]: StateNode}) => {
  const s = state[node] as StateNode & { containsHiddenInvalid?: boolean };
  if (s.parentId === '_form') {
    return;
  }
  const parent = state[s.parentId] as StateNode & { containsHiddenInvalid?: boolean };
  if (s.valid === false || s.containsHiddenInvalid === true) {
    if (parent.containsHiddenInvalid) {
      return;
    }
    if (s.display?.hidden) {
      parent.containsHiddenInvalid = true;
      propagateValidity(parent.fullId, state);
      return;
    }
  } else {
    if (parent.containsHiddenInvalid) {
      let allOk = true;
      Object.values(state).forEach((v: StateNode & { containsHiddenInvalid?: boolean }) => {
        if (v.parentId === parent.fullId && (v.valid === false || v.containsHiddenInvalid === true) && v.display?.hidden) {
          allOk = false;
        }
      })
      if (allOk) {
        parent.containsHiddenInvalid = false;
        propagateValidity(parent.fullId, state);
      }
    }
  }
}

export function Form(props: Props) {
  const [stateLock, setStateLock] = useState(props.templateLockId);
  let [states, setStates] = useState<{[key: string]: StateNode}>({});
  const [clearOnChange, setClearOnChange] = useState<{[key: string]: boolean}>({});
  const [simpleIdMap, setSimpleIdMap] = useState<{[key: string]: string}>({});
  const [isValidating, setValidating] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const setContainerRef = useCallback((id: string, node: HTMLElement | null) => {
    if (states[id]) {
      states[id].containerRef = node;
      setStates(states);
    }
    
  }, [states]);
  const setInputRef = useCallback((id: string, node: HTMLInputElement | null) => {
    if (states[id]) {
      states[id].inputRef = node;
      setStates(states);
    }
  }, [states]);
  const oldKeys: { [key: string]: boolean } = {};
  const getId = (id: string): string => {
    const mapped = simpleIdMap[id];
    return mapped === undefined? id: mapped;
  }
  const getController = (): FormController => {
    const controller: FormController = {
      getState: (id) => {
        return states[getId(id)];
      },
      getValue: (id) => {
        const s = states[getId(id)];
        if (isControl(s)) {
          return s === undefined? undefined: s.value;
        }
        return s.title;
      },
      getValid: (id) => {
        const s = states[getId(id)];
        if (isControl(s)) {
          return s === undefined? undefined: s.valid;
        }
        return undefined;
      },
      setValue: (baseId, value) => {
        const id = getId(baseId);
        const s = states[id];
        if (isControl(s)) {
          states = {
            ...states,
            [id]: {
              ...s,
              value,
            }
          };
          Object.keys(clearOnChange).forEach((k) => {
            (states[k] as Control).valid = true;
            (states[k] as Control).error = undefined;
          })
          setStates(states);
          setClearOnChange({});
          return Promise.resolve();
        }
        return Promise.resolve();
      },
      setHidden: (baseId, hidden) => {
        const id = getId(baseId);
        states = {
          ...states,
          [id]: {
            ...states[id],
            display: {
              ...states[id].display as any,
              hidden,
            }
          }
        };
        propagateValidity(id, states);
        setStates(states);
        return Promise.resolve();
      },
      setValid: (baseId, valid, msg) => {
        const id = getId(baseId);
        const s = states[id];
        states = {
          ...states,
          [id]: {
            ...s,
            valid,
          }
        };
        propagateValidity(id, states)
        setStates(states);
        return Promise.resolve();
      },
      setState: (baseId, newState) => {
        const id = getId(baseId);
        states = {
          ...states,
          [id]: {
            ...states[id],
            ...newState,
          } as StateNode
        };
        propagateValidity(id, states)
        setStates(states);
        return Promise.resolve();
      },
      getStandardisedId: (baseId) => {
        const id = getId(baseId);
        return id;
      },
      getChildren: (baseId) => {
        const id = getId(baseId);
        return Object.values(states).filter(s => s.parentId === id).map(s => s.elementId || s.fullId);
      },
      getParent: (baseId) => {
        const id = getId(baseId);
        const state = states[id];
        if (state) {
          return state.parentId;
        }
        return undefined;
      },
      addNode: (node, parentId, afterNode) => {

      },
      removeNode: (nodeId) => {

      },
    };
    return controller;
  }

  let added = false;
  if (props.templateLockId === stateLock) {
    Object.keys(states).forEach(k => {
      oldKeys[k] = true;
    })
  }
  const header: React.ReactNode[] = [];
  const footer: React.ReactNode[] = [];
  const controller = getController();
  const items = Base.cleanList(props.template.map((element, index) => {
    return Base.makeFormElement(
      element,
      index,
      '_form',
      {
        value: (id, value) => {
          const s = states[id];
          if (isControl(s)) {
            states = {
              ...states,
              [id]: {
                ...s,
                value,
              }
            };
            Object.keys(clearOnChange).forEach((k) => {
              (states[k] as Control).valid = true;
              (states[k] as Control).error = undefined;
            })
            setStates(states);
            setClearOnChange({});
            const change = s.onChange;
            if (change) {
              change(value, controller, { id: s.id, parentId: s.parentId });
            }
            if (props.valueChanged) {
              props.valueChanged(states[id].id, value, states[id].path, controller);
            }
          }
        },
        valid: (id, valid, error) => {
          const s = states[id];
          states = {
            ...states,
            [id]: {
              ...s,
              error,
              valid,
            }
          };
          propagateValidity(id, states)
          setStates(states);
        },
        process: (id, target) => {
          const control = states[id];
          if (control.onClick === undefined || isSubmitting !== false) {
            return;
          }

          let allValid = true;
          const state: { [key: string]: { value: ControlValue, valid: boolean } } = {};
          const disabled: { [key: string]: boolean | undefined } = {};
          const errors: { [key: string]: { id: string, value: string, error: string }} = {};
          Object.entries(states).forEach(([k, v]) => {
            if (isControl(v)) {
              state[k] = { value: Array.isArray(v.value)? v.value[0]: v.value, valid: v.valid !== false };
              const showsInvalid = v.valid === false && !(v.display && v.display.disabled === true);
              if (allValid && showsInvalid) {
                if (v.containerRef) {
                  v.containerRef.scrollIntoView();
                }
                if (v.inputRef) {
                  v.inputRef.focus();
                }
              }
              allValid = allValid && !showsInvalid;
              if (showsInvalid) {
                errors[k] = { error: v.error || '', id: k, value: v.value as string || ''}
              }
              disabled[k] = v.display && v.display.disabled === true;
              states[k] = { ...v, processing: k === id || (target !== undefined && (v.elementId === target || v.onClick === target)), display: { ...v.display, disabled: true } as any};
            }
            if (v.elementId) {
              state[v.elementId] = state[k];
            }
          });
          setValidating(control.validationRequired !== false);
          if (!allValid && control.validationRequired !== false) {
            Object.entries(states).forEach(([k, v]) => {
              if (isControl(v)) {
                states[k] = { ...v, processing: false, display: { ...v.display, disabled: disabled[k], validate: isValidating } as any};
              }
            });
            setStates(states);
            return Promise.resolve();
          }
          setStates(states);
          setSubmitting(true);
          props.submitHandler((control.onClick || (isControl(control)? control.value: '') || '').toString(), state, controller)
            .then(errors => {
              setSubmitting(false);
              Object.entries(states).forEach(([k, v]) => {
                if (isControl(v)) {
                  states[k] = { ...v, processing: false, display: { ...v.display, disabled: disabled[k] } as any};
                }
              });
              if (errors) {
                let first = true;
                Object.entries(errors).forEach(([k,v]) => {
                  const mappedID = getId(k);
                  if (states[mappedID]) {
                    if (first) {
                      first = false;
                      const v = states[mappedID];
                      if (v.containerRef) {
                        v.containerRef.scrollIntoView();
                      }
                      if (v.inputRef) {
                        v.inputRef.focus();
                      }
                    }
                    clearOnChange[mappedID] = true;
                    (states[mappedID] as Control).valid = false;
                    (states[mappedID] as Control).error = v;
                    propagateValidity(mappedID, states);
                  }
                });
              }
              setStates(states);
              setClearOnChange(clearOnChange);
            })
            .catch((error) => {
              setSubmitting(false);
              Object.entries(states).forEach(([k, v]) => {
                if (isControl(v)) {
                  states[k] = { ...v, processing: false, display: { ...v.display, disabled: disabled[k] } as any};
                }
              });
              if (states[id]) {
                const v = states[id];
                if (v.containerRef) {
                  v.containerRef.scrollIntoView();
                }
                if (v.inputRef) {
                  v.inputRef.focus();
                }
              
                (states[id] as Control).valid = false;
                (states[id] as Control).error = error;
                propagateValidity(id, states);
              }

              clearOnChange[id] = true;
              setClearOnChange(clearOnChange);
              setStates(states);
            });
        },
        controller,
      },
      {
        id: (parentId, id) => {
          if (parentId === '_form') {
            return id;
          }
          const fullId = `${states[parentId].fullId}.${id}`;
          simpleIdMap[id] = fullId;
          return fullId;
        },
        get: (parentId, id, fullId, defaultState, actions) => {
          const existing = props.templateLockId === stateLock? states[fullId]: undefined;
          const state = existing === undefined? { ...defaultState(), id, fullId, parentId, path: [], controller: actions, containerRef: undefined, inputRef: undefined }: existing;
          (state as any).controller = actions;
          if (existing !== undefined) {
            delete oldKeys[fullId];
          } else {
            if (parentId === '_form') {
              state.path = [id];
            } else {
              state.path = [...states[parentId].path, id];
            }
            added = true;
          }
          states[fullId] = state;
          return { ...state, display: { ...state.display, validate: isValidating }, containerRef: (node: HTMLElement | null) => setContainerRef(fullId, node), inputRef: (node: HTMLInputElement | null) => setInputRef(fullId, node) } as any;
        },
        validate: isValidating,
      },
      {
        header: (n: any) => {
          header.push(n);
        },
        footer: (n: any) => {
          footer.push(n);
        }
      },
    );
  }));
  if ((added || Object.keys(oldKeys).length !== 0)){
    Object.keys(oldKeys).forEach((k) => {
      delete states[k];
    });
    setStates(states);
    setSimpleIdMap(simpleIdMap);
    setStateLock(props.templateLockId);
  }
  return <F className="form" noValidate onSubmit={(e) => {
    e.preventDefault();
    e.stopPropagation();
  }}>
    {header.length===0?undefined:props.headerElement? ReactDOM.createPortal(header, props.headerElement): header}
    {items}
    {footer.length===0?undefined:props.footerElement? ReactDOM.createPortal(footer, props.footerElement): footer}
  </F>
}