import { Route } from 'react-router-dom';


import { DownloadEvent } from '../../analytics/eventPages/download';

import { RouteBundleProps } from '../common';

export const download = (props: RouteBundleProps) => {
  return [
    <Route path="/download/referral" element={<DownloadEvent/>} />,
  ];
}