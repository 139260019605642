import React, { Component, useState } from 'react';
import { Buffer } from 'buffer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

import { makeRequest } from '../../helper/request';

export interface Props extends HeaderProps {

};

export const NewPassword = (props: Props) => {
  const [sent, setSent] = useState(false);
  const params = useParams();
  let token = params['token'];
  if (token) {
    token = Buffer.from(token, "base64").toString();
  }

  return <HeaderPage>
    <Block.Block alternate={true}>
      <Standard.StandardContent>
        <div style={{ padding: 15, margin: 15, marginLeft: 'auto', marginRight: 'auto', flex: 1, maxWidth: 600 }} >
          {sent?"Your password has been reset":<Form
        submitHandler={(action, state) => {
          if (action === 'reset') {
            const errors: { [key: string]: string } = {};
            const password = state['password'].value===undefined?'':state['password'].value.toString();
            if (password.length < 8 || !/[0-9]/.test(password) || !/[A-Z]/.test(password) ) {
              errors['password'] = '* passwords must be at least 8 characters and contain an uppercase letter and a number';
            }
            if (state['password'].value !== state['password.confirm'].value) {
              errors['password.confirm'] = '* passwords must match';
            }
            if (Object.keys(errors).length > 0) {
              return Promise.resolve(errors)
            }
            return makeRequest({
              type: 'account.password.reset',
              resetToken: token,
              newPassword: state['password'].value,
            }).then((response) => {
              if (response.result === 'success'){ 
                setSent(true);
                return undefined;
              }
              return { 'submit': 'Your password could not be reset'}
            });
          }
          return Promise.resolve(undefined);
        }}
        template={[
          {
            type: 'section',
            title: 'Create New Password',
            items: [],
          },
          {
            type: 'section',
            items: [
              {
                title: 'Password',
                  id: 'password',
                  type: 'password',
              },
              {
                title: 'Confirm Password',
                id: 'password.confirm',
                type: 'password',
                value: '',
                onClick: 'reset',
              }
            ],
          },
          {
            type: 'section',
            items: [
              {
                type: 'row',
                display: {
                  style: {
                    marginTop: 10,
                  },
                  align: 'centre',
                },
                items: [      
                  {
                    id: 'submit',
                    type: 'button',
                    title: 'Change Password',
                    value: 'reset',
                    onClick: 'reset',
                  }
                ]
              },
            ],
          }
        ]} />}
        </div>
      </Standard.StandardContent>
    </Block.Block>
  </HeaderPage>;
}