import { ChangeEvent, useCallback } from 'react';

import { Form } from 'react-bootstrap';

export interface Props {
  onChange: (value: number) => void,
  selected: number | undefined | null,
  isValid?: boolean,
  isInvalid?: boolean,
}

export function TimeSpan(props: Props) {
  const days = typeof props.selected === 'number'? Math.floor(props.selected/(60*60*24)): undefined;
  const hours = typeof props.selected === 'number'? Math.floor((props.selected-(days || 0)*60*60*24)/(60*60)): undefined;

  const changeDay = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let d = Number.parseInt(e.target.value);
    if (Number.isNaN(d)) {
      d = 0;
    }
    if (d < 0) {
      d = 0;
    }
    props.onChange(d*60*60*24+(hours || 0)*60*60);
  }, [days, hours]);

  const changeHour = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let h = Number.parseInt(e.target.value);
    if (Number.isNaN(h)) {
      h = 0;
    }
    if (h < 0) {
      h = 0;
    }
    props.onChange((days || 0)*60*60*24+h*60*60);
  }, [days, hours]);


  return <div className={`validation-control ${props.isValid === true? 'is-valid': ''} ${props.isInvalid === true? 'is-invalid': ''}`} style={{ padding: 0, height: 'calc(1.5em + .75rem + 3px)', width: 180 }}>
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
      <div style={{ display: 'flex', fontSize: 14, fontWeight: 200, flexDirection: 'column', justifyContent: 'center', alignContent: 'center', height: '100%', color: '#8e8e8e', paddingLeft: 3 }}>days:</div>
      <Form.Control value={days} type='number' style={{ width: '40%', border: 'none', background: 'none', borderRadius: 0, paddingLeft: 2, paddingRight: 2 }} onChange={changeDay}></Form.Control>
      <div style={{ display: 'flex', fontSize: 14, fontWeight: 200,  flexDirection: 'column', justifyContent: 'center', alignContent: 'center', height: '100%', color: '#8e8e8e', paddingLeft: 3, borderLeft: '#ced4da', borderLeftWidth: 1, borderLeftStyle: 'solid' }}>hours:</div>
      <Form.Control value={hours} type='number' style={{ width: '30%', border: 'none', background: 'none', borderTopLeftRadius: 0, borderBottomLeftRadius: 0, paddingLeft: 2, paddingRight: 2 }} onChange={changeHour}></Form.Control>
    </div>
  </div>;
} 