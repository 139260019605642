import { Route } from 'react-router-dom';

import * as Web from '../../web';
import * as Training from '../../training';

import { RouteBundleProps } from '../common';

export const training = (props: RouteBundleProps) => {
  return [
    <Route path="/installation-training" element={<Training.RemoteDelivery/>} />,
    <Route path="/training/:id" element={<Training.RemoteDelivery/>} />,
  ];
}

export const presenterPack = (props: RouteBundleProps) => {
  return props.loggedIn? [
    <Route path="/" element={<Web.PresenterPack />} />,
  ]: [];
}