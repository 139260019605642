
import React, { Component, useState } from 'react';
import { redirect, Link, useLocation, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { ChevronUp, ChevronDown, ChevronExpand, FileEarmarkArrowDownFill, ClipboardDataFill, PersonFill, VolumeUpFill, PlayBtnFill, ArrowLeftShort } from 'react-bootstrap-icons';

import { formatNumber } from './helper'; 
import { Form } from '../../components/form/Form';
import { Block, Standard } from '../../components/content';
import { HeaderPage, Props as HeaderProps } from '../../components/page/HeaderPage';

const isIndividualIcon = (data: any): data is {
  full: JSX.Element,
  remote: JSX.Element,
  byo: JSX.Element,
} => typeof data === 'object' && data.full !== undefined && data.remote !== undefined && data.byo !== undefined;

export interface Props {
  stateChange: (action: string) => void,
  organisation: string,
  content: string,
  deliveryMode: string,
  includeBYO?: boolean,
  title: JSX.Element,
}

type PriceGuide = { byo: [number, number], remote: [number, number] };

type OrgPrice = { [key: string]: PriceGuide | string };

const pricing: { [key: string]: OrgPrice | string } = {
  'bamboo.bandits': {
    'arts.large': {
      'byo': [5700,8200],
      'remote': [9000,13800],
    },
    'arts.medium': {
      'byo': [4200,6200],
      'remote': [6800,11300],
    },
    'arts.small': {
      'byo': [3800,5800],
      'remote': [6000,10100],
    },
    'library': 'arts.small',
    'school': 'arts.small',
    'vacation.care': 'arts.small',
    'other': 'arts.large',
    'local.government': 'arts.medium',
    'unknown': 'arts.large',
  },
  'sun.runners': {
    'arts.large': {
      'byo': [5700,8200],
      'remote': [8600,13000],
    },
    'arts.medium': {
      'byo': [4200,6200],
      'remote': [6500,10700],
    },
    'arts.small': {
      'byo': [3800,5800],
      'remote': [6000,10100],
    },
    'library': 'arts.small',
    'school': 'arts.small',
    'vacation.care': 'arts.small',
    'other': 'arts.large',
    'local.government': 'arts.medium',
    'unknown': 'arts.large',
  },
  'the.turners': {
    'arts.large': {
      'byo': [5500,8200],
      'remote': [8200,12800],
    },
    'arts.medium': {
      'byo': [4000,6200],
      'remote': [6300,10500],
    },
    'arts.small': {
      'byo': [3700,5800],
      'remote': [5800,9900],
    },
    'library': 'arts.small',
    'school': 'arts.small',
    'vacation.care': 'arts.small',
    'other': 'arts.large',
    'local.government': 'arts.medium',
    'unknown': 'arts.large',
  },
  default: 'sun.runners',
}



export const Prices = (props: Props) => {
  let orgPrice = pricing[props.content];
  if (orgPrice === undefined) {
    orgPrice = 'sun.runners';
  }
  if (typeof orgPrice === 'string') {
    orgPrice = pricing[orgPrice] as OrgPrice;
  }
  let price = orgPrice[props.organisation];
  if (price === undefined) {
    price = orgPrice['arts.large'];
  }
  if (typeof price === 'string') {
    price = orgPrice[price] as PriceGuide;
  }
  return <div className='small-margin presenter-pack' style={{ padding: 15, flex: 1, flexGrow: 1, zIndex: 1 }} >
    <Col style={{height: '100%'}}>
      <div style={{ paddingBottom: 15}}>
        <Button 
          variant="audioplay-primary"
          style={{ width: 120, height: 50, borderRadius: 30, fontWeight: 700, fontSize: 14 }}
          onClick={() => {
            props.stateChange('');
          }}
        >
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div style={{padding: 0}}><ArrowLeftShort size={20}></ArrowLeftShort></div><div style={{flexGrow:1}}>BACK</div></div> 
        </Button>
      </div>
      
      <Row style={{justifyContent: 'space-between'}}>

        <Col style={{flexGrow: 1}}>
          <Row style={{ alignItems: 'end'}}>
            {props.title}
            <div style={{width: 30, height: 30}}></div>
            
            <div style={{color: 'white', fontWeight: 800, fontSize: 32, textShadow: '2px 2px #000000'}}>INDICATIVE PRICING</div>
          </Row>
          <div style={{width: 30, height: 30}}></div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <div style={{backgroundColor: '#021625cc', flexGrow: 0, border: '2px solid black', borderRadius: 5, display: 'flex', flexDirection: 'column', maxWidth: 700, padding: 10  }}>
              <div style={{display:'flex',flexDirection: 'row', justifyContent: 'center',color: 'white', fontSize: 24, fontWeight: 400}}>1 Wk / 7 Day Presentation</div>
              <div style={{ display: 'table', flexGrow: 1, color: 'white'  }}>
                <div style={{ display: 'table-row', flexDirection: 'row' }}>
                  <div className='presenter-grid-cell' style={{ borderTop: '1px solid #666c',display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5 }}><div className='presenter-grid-cell-content'>Remote Delivery – Equipment Provided</div></div>
                  <div className='presenter-grid-cell' style={{ borderTop: '1px solid #666c',borderLeft: '1px solid #666c', display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5 }}><div className='presenter-grid-cell-content'>{`AU$${formatNumber(price.remote[0])} to AU$${formatNumber(price.remote[1])}`}</div></div>
                </div>
                {props.includeBYO === true? <div style={{ display: 'table-row', flexDirection: 'row' }}>
                  <div className='presenter-grid-cell' style={{ borderTop: '1px solid #666c', display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5 }}><div className='presenter-grid-cell-content'>Remote Delivery – BYO Equipment</div></div>
                  <div className='presenter-grid-cell' style={{ borderTop: '1px solid #666c', borderLeft: '1px solid #666c', display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5, minWidth: '35%' }}><div className='presenter-grid-cell-content'>{`AU$${formatNumber(price.byo[0])} to AU$${formatNumber(price.byo[1])}`}</div></div>
                </div> : undefined }
                <div style={{ display: 'table-row', flexDirection: 'row' }}>
                  <div className='presenter-grid-cell' style={{ borderTop: '1px solid #666c', display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5 }}><div className='presenter-grid-cell-content'>Fully Delivered</div></div>
                  <div className='presenter-grid-cell' style={{ borderTop: '1px solid #666c', borderLeft: '1px solid #666c', display: 'table-cell', fontWeight: 600, fontSize: 16, paddingLeft: 5, paddingRight: 5, minWidth: '35%' }}><div className='presenter-grid-cell-content'>Price provided on request</div></div>
                </div>
              </div>
              <div style={{padding: 5, display: 'flex', borderTop: '1px solid #666c', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 16, fontWeight: 300, color: 'white'}}>
              Discounts are available for booking multiple seasons – these might be bookings across different school holiday periods or for multiple years of a festival for example. Audioplay has a range of experiences/stories and can offer different content for each booking.
              </div>
              <div style={{padding: 5, display: 'flex',  flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: 14, fontWeight: 300, color: 'white'}}>
                <ul>
                    <li><i>All figures are GST Exclusive</i></li>
                    <li><i>These are indicative costings only and prices are subject to change</i></li>
                    <li><i>Freight is not included in the costings and will be calculated as required for Remote Delivery – Equipment Provided bookings.</i></li>
                  </ul>
              </div>
            </div>
            <div style={{width: 30, height: 30}}></div>
            <a href={'mailto:gemma@audioplay.me'} target='_black' style={{color:'black'}}>
                <Button 
                  variant="audioplay-primary"
                  style={{ width: 220, height: 70, borderRadius: 30, fontWeight: 700, fontSize: 18 }}
                >
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><div style={{flexGrow:1}}>GET A QUOTE</div></div>
                </Button>
                </a>
          </div>
        </Col>
      </Row>
    </Col>
  </div>;
}